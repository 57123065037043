import {
  AppBar,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Tab,
  Tabs,
  TextField,
  Toolbar
} from "@material-ui/core";
import {
  Person as AccountIcon,
  Create,
  Lock,
  Mail,
  Menu as MenuIcon,
  MenuOpen,
  Person,
  Send as SendIcon,
  Visibility,
  VisibilityOff,
  VpnKey
} from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/logo.png';

// styles
import useStyles from "./styles";

// components
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import { Button, Typography } from "../Wrappers";

// context
import { encode as btoa } from 'base-64';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from "react-router-dom";
import { Constant } from "../../constant";
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import { signOut, useUserDispatch } from "../../context/UserContext";
import Service from "../../service";

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

let user = JSON.parse(localStorage.getItem("user"));
const envoicodemessage = "Nous venons de vous envoyez un code de verification sur votre mail : ";
let mail = "";
export default function Header(props) {
  var classes = useStyles();
  let history = useHistory();
  if(window.location.hostname.includes("gps.sevob.net")) {
    history.push('/maps/error')
  }

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  var [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [etape, setEtape] = React.useState(0);
  const [etapeInscrit, setEtapeInscrit] = React.useState({value: 0, code: ""});
  const [spinner, setSpinner] = useState(false);
  const [text, setText] = useState("Veuillez saisir le mail que vous utilisez sur SEVOB");
  const [code, setCode] = useState("");
  const [state, setState] = React.useState({
    id:0,
    nom: "",
    telephone: "",
    user: {
      id: 0,
      nomUtilisateur: "",
      motDePasse: "",
    }
  });
  const [error, setError] = React.useState({
    nom: false,
    telephone: false,
    nomUtilisateur: false,
    motDePasse: false,
  });
  const [values, setValues] = React.useState({showPassword: false});

  React.useEffect(() => {
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const cliquer = () => {
    if(state.nom.trim() === "" && value === 1){
      setError({...error, nom: true})
    } else if(state.telephone.trim() === "" && value === 1){
      setError({...error, telephone: true, nom: false})
    } else if((state.user.nomUtilisateur.trim() === "" || (state.user.nomUtilisateur.split("@")[1] === undefined && etape !== 1)) && (etape !== 2)){
      setError({...error, nomUtilisateur: true, telephone: false, nom: false})
    } else if((state.user.motDePasse.trim() === "" && etape !== 0) && (state.user.motDePasse.trim() === "" && etape !== 1)){
      setError({...error, motDePasse: true, nomUtilisateur: false, telephone: false, nom: false})
    } else {
      setError({...error, motDePasse: false, nomUtilisateur: false, telephone: false, nom: false})
      if(value === 0) {
        Connexion()
      } else if(value === 1 && etapeInscrit.value === 0) {
        EnvoiCodeInscription()
      } else if(value === 1 && etapeInscrit.value === 1) {
        VerificationInscription()
      } else {
        if(etape === 0){
          EnvoiCode()
        } else if(etape === 1){
          Verification()
        } else {
          Modification()
        }
        // if(value === 2 && etape !== 2) {
        //   setEtape(etape+1);
        // }
      } 
    }
  };

  const Connexion = () => {
    setSpinner(true);
        const token = btoa(state.user.nomUtilisateur.toString() + ':' + state.user.motDePasse);
        Service.connexion(token, state.user.nomUtilisateur).then(
          async (response) => {
            setSpinner(false);
            setOpen(false)
              localStorage.setItem('user', JSON.stringify(response.data));
              localStorage.setItem('token', token);
              window.location.reload();
              // history.push('/app/dashboard')
              // navigation.navigate("MainDrawer");
          }, error => {
              setSpinner(false);
              toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
        
          }
        )
  };
  const Inscription = () => {
    setSpinner(true);
    Service.inscription(state).then(
      async (response) => {
        setSpinner(false);
        if(response !== undefined) {
          setValue(0)
          toast.success(response.data, {position: "top-center", autoClose: 10000,})
        } else {
          toast.error("Erreur lors de l'envoi de l'email.\nMerci de verifier que votre adresse mail est correct.", {position: "top-center", autoClose: 10000,})
        }
      }, error => {
        toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
        setSpinner(false);
      }
    )
  };


  const EnvoiCodeInscription = () => {
    setSpinner(true);
    const coderesult = makeid(6);
    Service.envoiCodeInscription(state.user.nomUtilisateur, coderesult, state.nom).then(
      (response) => {
          setSpinner(false);
          setCode(coderesult)
          setEtapeInscrit({...etapeInscrit, value: 1})
          setText("Veuillez saisir le code que vous avez reçu à : " + state.user.nomUtilisateur)
          toast.success(envoicodemessage + state.user.nomUtilisateur, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      }
    )
  };

  const VerificationInscription = () => {
    if (etapeInscrit.code === code) {
      setSpinner(false)
      Inscription()
    } else {
      toast.error("Code incorrect", {position: "top-center", autoClose: 10000,})
      setSpinner(false)
    }
  };

  function makeid(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * 
      charactersLength)));
   }
   return result.join('');
}

  const EnvoiCode = () => {
    setSpinner(true);
    const coderesult = makeid(6);
    Service.envoiCode(state.user.nomUtilisateur, coderesult).then(
      (response) => {
        setSpinner(false);
        if(response.data === 1 ) {
          toast.error("Cet E-mail n\'existe pas", {position: "top-center", autoClose: 10000,})
        } else {
          mail = state.user.nomUtilisateur;
          setState({...state, user: {...state.user, nomUtilisateur: ""}})
          setEtape(1);
          setCode(coderesult)
          setText("Veuillez saisir le code que vous avez reçu à : " + mail)
          toast.success(envoicodemessage + mail, {position: "top-center", autoClose: 10000,})
        }
      }, error => {
        setSpinner(false);
        toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      }
    )
  };
  const Verification = () => {
    if (state.user.nomUtilisateur === code) {
      setText("Veuillez saisir votre nouveau mot de passe");
      setEtape(2);
      setSpinner(false)
    } else {
      toast.error("Code incorrect", {position: "top-center", autoClose: 10000,})
      setSpinner(false)
    }
  };
  const Modification = () => {
    setSpinner(true);
    Service.ModificationMotDePasse(mail, state.user.motDePasse).then(
      (response) => {
        setValue(0);
        setEtape(0)
        setState({...state, user: {...state.user, nomUtilisateur: mail}})
        setSpinner(false);
        toast.success("Votre mot de passe à été modifié avec succès", {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.success("Erreur de modification. Merci de réessayer", {position: "top-center", autoClose: 10000,})
      }
    )
  };

  return (
      <div>
        <ToastContainer />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
          {(user !== null && user !== undefined) &&
            <IconButton
              color="inherit"
              onClick={() => toggleSidebar(layoutDispatch)}
              className={classNames(
                classes.headerMenuButtonSandwich,
                classes.headerMenuButtonCollapse,
              )}
            >
              {layoutState.isSidebarOpened ? (
                <MenuOpen
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              ) : (
                <MenuIcon
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              )}
            </IconButton>
          }
            <img src={logo} alt="logo" style={{height: 50, borderRadius: 5}} />
            {/* <Typography variant="h6" weight="medium" className={classes.logotype}>
              SEVOB
            </Typography> */}
            <div className={classes.grow} />
            {/* <div
              className={classNames(classes.search, {
                [classes.searchFocused]: isSearchOpen,
              })}
            >
              <div
                className={classNames(classes.searchIcon, {
                  [classes.searchIconOpened]: isSearchOpen,
                })}
                onClick={() => setSearchOpen(!isSearchOpen)}
              >
                <SearchIcon classes={{ root: classes.headerIcon }} />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
            <IconButton
              color="inherit"
              aria-haspopup="true"
              aria-controls="mail-menu"
              onClick={e => {
                setNotificationsMenu(e.currentTarget);
                setIsNotificationsUnread(false);
              }}
              className={classes.headerMenuButton}
            >
              <Badge
                badgeContent={isNotificationsUnread ? notifications.length : null}
                color="warning"
              >
                <NotificationsIcon classes={{ root: classes.headerIcon }} />
              </Badge>
            </IconButton>
            <IconButton
              color="inherit"
              aria-haspopup="true"
              aria-controls="mail-menu"
              onClick={e => {
                setMailMenu(e.currentTarget);
                setIsMailsUnread(false);
              }}
              className={classes.headerMenuButton}
            >
              <Badge
                badgeContent={isMailsUnread ? messages.length : null}
                color="secondary"
              >
                <MailIcon classes={{ root: classes.headerIcon }} />
              </Badge>
            </IconButton> */}
            {(user === null || user === undefined) &&
              <Button variant={"outlined"} className={classes.headerMenuButton}>
                  <IconButton
                    aria-haspopup="true"
                    className={classes.headerMenuButton}
                    onClick={(e)=> setOpen(true)}
                    style={{color: 'white'}}
                    >
                    <AccountIcon classes={{ root: classes.headerIcon }} /> Identification
                  </IconButton>
              </Button>
            }
            {(user !== null && user !== undefined) &&
              <IconButton
                aria-haspopup="true"
                color="inherit"
                className={classes.headerMenuButton}
                aria-controls="profile-menu"
                onClick={e => setProfileMenu(e.currentTarget)}
              >
                <AccountIcon classes={{ root: classes.headerIcon }} /> {(user !== null && user !== undefined) && user.nom}
              </IconButton>
            }
            <Menu
              id="mail-menu"
              open={Boolean(mailMenu)}
              anchorEl={mailMenu}
              onClose={() => setMailMenu(null)}
              MenuListProps={{ className: classes.headerMenuList }}
              className={classes.headerMenu}
              classes={{ paper: classes.profileMenu }}
              disableAutoFocusItem
            >
              <div className={classes.profileMenuUser}>
                <Typography variant="h4" weight="medium">
                  New Messages
                </Typography>
                <Typography
                  className={classes.profileMenuLink}
                  component="a"
                  color="secondary"
                >
                  {messages.length} New Messages
                </Typography>
              </div>
              {messages.map(message => (
                <MenuItem key={message.id} className={classes.messageNotification}>
                  <div className={classes.messageNotificationSide}>
                    <UserAvatar color={message.variant} name={message.name} />
                    <Typography size="sm" color="text" colorBrightness="secondary">
                      {message.time}
                    </Typography>
                  </div>
                  <div
                    className={classNames(
                      classes.messageNotificationSide,
                      classes.messageNotificationBodySide,
                    )}
                  >
                    <Typography weight="medium" gutterBottom>
                      {message.name}
                    </Typography>
                    <Typography color="text" colorBrightness="secondary">
                      {message.message}
                    </Typography>
                  </div>
                </MenuItem>
              ))}
              <Fab
                variant="extended"
                color="primary"
                aria-label="Add"
                className={classes.sendMessageButton}
              >
                Send New Message
                <SendIcon className={classes.sendButtonIcon} />
              </Fab>
            </Menu>
            <Menu
              id="notifications-menu"
              open={Boolean(notificationsMenu)}
              anchorEl={notificationsMenu}
              onClose={() => setNotificationsMenu(null)}
              className={classes.headerMenu}
              disableAutoFocusItem
            >
              {notifications.map(notification => (
                <MenuItem
                  key={notification.id}
                  onClick={() => setNotificationsMenu(null)}
                  className={classes.headerMenuItem}
                >
                  <Notification {...notification} typographyVariant="inherit" />
                </MenuItem>
              ))}
            </Menu>
            <Menu
              id="profile-menu"
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              onClose={() => setProfileMenu(null)}
              className={classes.headerMenu}
              classes={{ paper: classes.profileMenu }}
              disableAutoFocusItem
              onClick={(e) => history.push('/app/Profil')}
            >
              <div className={classes.profileMenuUser}>
                <Typography variant="h4" weight="medium">
                  {(user !== null && user !== undefined) && user.nom}
                </Typography>
                <Typography
                  className={classes.profileMenuLink}
                  component="a"
                  color="primary"
                >
                  {(user !== null && user !== undefined) && user.users.nomUtilisateur}
                </Typography>
              </div>
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
                
              >
                <AccountIcon className={classes.profileMenuIcon} /> Profil
              </MenuItem>
              {/* <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <AccountIcon className={classes.profileMenuIcon} /> Tasks
              </MenuItem>
              <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem,
                )}
              >
                <AccountIcon className={classes.profileMenuIcon} /> Messages
              </MenuItem> */}
              <div className={classes.profileMenuUser}>
                <Typography
                  className={classes.profileMenuLink}
                  color="error"
                  onClick={() => signOut(userDispatch, props.history)}
                >
                  Déconnexion
                </Typography>
              </div>
            </Menu>
          </Toolbar>

          <Modal
            open={open}
            onClose={(e)=>setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{zIndex: 1000, overflow: 'scroll'}}
          >
            <div>
            <Grid container spacing={3}>
                <Grid item lg={3}>
                </Grid>
                <Grid item lg={6}>
                  <Paper square  style={{paddingTop: 60, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <Tabs
                      value={value}
                      indicatorColor="secondary"
                      textColor="secondary"
                      onChange={handleChange}
                      variant="fullWidth"
                      aria-label="disabled tabs example"
                    >
                      <Tab label="Connexion" icon={<Lock />}/>
                      <Tab label="Inscription" icon={<Create />}/>
                      <Tab label="Mot de passe oublié" icon={<VpnKey />} />
                    </Tabs>

                  <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, marginTop: 50}}>
                    {value === 0 && "Page de connexion"}
                    {value === 1 && "Page d'inscription"}
                    {value === 2 && "Mot de passe oublié"}
                  </h1>

                  {(value === 2 || (value === 1 && etapeInscrit.value === 1)) && <p style={{textAlign: 'center', marginBottom: 10, color: 'red'}}>{text}</p>}
                  <Grid container spacing={3}>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={8}>
                          <Grid container spacing={3}>
{/* Pour le code d'inscription */}
                          {(value === 1 && etapeInscrit.value === 1) && 
                              <Grid item xs={12}>
                                <TextField value={etapeInscrit.code} error={error.code} helperText={error.code && "Code incorrect"} onChange={(e)=> setEtapeInscrit({...etapeInscrit, code: e.target.value})} size="small" label={"Code"} variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Mail />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            }

                            {(value === 1 && etapeInscrit.value === 0) && 
                              <Grid item xs={12}>
                                <TextField error={error.nom} helperText={error.nom && Constant.champObligatoire} onChange={(e)=>setState({...state, nom: e.target.value})} size="small" label="Nom individu/Entreprise" variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Person />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            }
                            {(value === 1 && etapeInscrit.value === 0) && 
                              <Grid item xs={12}>
                                <label>Téléphone</label>
                                <br/>
                                <PhoneInput
                                    // value={value}
                                    onChange={(e)=>setState({...state, telephone: e})}
                                    country={"ml"}
                                    placeholder="Téléphone"
                                    inputProps={{
                                        name: 'telephone',
                                        required: true,
                                        autoFocus: true,
                                      }}
                                      inputStyle={{width: "100%"}}
                                />
                                {error.telephone && <small style={{color: 'red'}}>{Constant.champObligatoire}</small>}
                              </Grid>
                            }
                                
                            {(value === 0 || (value === 1 && etapeInscrit.value === 0) || (value === 2 && etape !== 2)) && 
                              <Grid item xs={12}>
                                <TextField value={state.user.nomUtilisateur} error={error.nomUtilisateur} helperText={error.nomUtilisateur && Constant.champObligatoire} onChange={(e)=> setState({...state, user: {...state.user, nomUtilisateur: e.target.value}})} size="small" label={(etape !== 1 || value !== 2 ) ? "Email" : "Code"} variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Mail />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            }
                            {(value === 0 || (value === 1 && etapeInscrit.value === 0) || (value === 2 && etape === 2)) && 
                              <Grid item xs={12}>
                                <TextField type={values.showPassword ? 'text' : 'password'} error={error.motDePasse} helperText={error.motDePasse && Constant.champObligatoire} onChange={(e)=>setState({...state, user: {...state.user, motDePasse: e.target.value}})} size="small" label={(etape !== 2 || value !== 2 ) ? "Mot de passe" : "Nouveau mot de passe"} variant="outlined" fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <VpnKey />
                                      </InputAdornment>
                                    ),
                                    endAdornment:(
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                        >
                                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                            }
                            <Grid item sm={8}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {value === 0 && "Se connecter"}
                                {(value === 1 && etapeInscrit.value === 0) && "S'inscrire"}
                                {(value === 2 && etape===0) && "Envoyer le code"}
                                {((value === 2 && etape===1) || (value === 1 && etapeInscrit.value === 1)) && "Vérifier le code"}
                                {(value === 2 && etape===2) && "Modifier mon mot de passe"}

                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item sm={4} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                      </Grid>
                      <Grid item xs={2}></Grid>
                  </Grid>
                  </Paper>
                </Grid>
                <Grid item lg={3}>
                </Grid>
            </Grid>
            </div>
          </Modal>

        </AppBar>
      </div>
  );
}
