import {
  Button,
  Chip,
  CircularProgress, Grid,
  IconButton,
  InputAdornment, LinearProgress, Modal, Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import React, { useState } from "react";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

// data
import Service from "../../service";

import { DatePicker, Select } from 'antd';

import { AccountBalanceWallet, Add, Cancel, Check, Delete, Edit, Person, Sms } from "@material-ui/icons";
import 'moment/locale/fr';
import MUIDataTable from "mui-datatables";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Constant } from "../../constant";
import useStyles from "../dashboard/styles";
const { RangePicker } = DatePicker;


let user = JSON.parse(localStorage.getItem('user'));
export default function GestionApis() {
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  var [open, setOpen] = useState(false);
  var [open2, setOpen2] = useState(false);
  const [reponse2, setReponse2] = useState([]);
  const navigate = useHistory();
  const [state, setState] = React.useState({
    id:0,
    nom: "",
    activationclient: "",
    description: "",
    codetest: "",
    type: "",
    idcode: "",
    montant: 0,
  });
  const [error, setError] = React.useState({
    nom: false,
    description: false,
    type: false,
    montant: false,
    idcode: false,
  });

  React.useEffect(() => {
    getAPIsList()
    getSenderAll()
  }, []);


const getSenderAll = () => {
  Service.getSenderAll().then(
    async (response) => {
      if(response.data !== "") {
        setReponse2(response.data);
      }
    })
}


  const getAPIsList = async () => {
      Service.getAPIsList('all').then(
        async (response) => {
          if(response.data !== "") {
            setReponse(response.data)
          } else {
            alert(Constant.noAccess)
            navigation("/app/Profil", "")
          }
        })
  }

  const changeStatut = async (id) => {
      setSpinner(true);
      Service.changeStatutAPIs(id).then(
        async (response) => {
          getAPIsList();
          setSpinner(false);
        }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      })
  }

  const init = () => {
    setState({
      id:0,
      nom: "",
      description: "",
      type: "",
      codetest: "",
      idcode: "",
      montant: 0,
    });
  }

  const cliquer = async () => {
    if(state.idcode.trim() === ""){
      setError({...error, idcode: true})
    } else if(state.nom.trim() === ""){
      setError({...error, nom: true, idcode: false})
    } else if(state.description.trim() === ""){
      setError({...error, description: true, nom: false, idcode: false})
    } else if(state.montant <= 0){
      setError({...error, montant: true, description: false, nom: false, idcode: false})
    } else if(state.type.trim() === ""){
      setError({...error, type: true, montant: false, description: false, nom: false, idcode: false})
    } else {
      let data = {}
      data = state
      data.idcode = state.idcode + state.activationclient
        setSpinner(true);
        Service.SaveApi(data).then(
          async (response) => {
            if(response.data !== 0) {
              init()
              toast.success("Ajout effectué avec succès", {position: "top-center", autoClose: 10000,})
              getAPIsList()
            } else {
              alert(Constant.noAccess)
            }
            setSpinner(false);
            setOpen(false)
          }, error => {
              setSpinner(false);
              toast.error("Erreur d'ajout. Identifiant de l'api doit être unique", {position: "top-center", autoClose: 10000,})
          }
        )
    }
}

const navigation = (path, data) => {
  navigate.push({
    pathname: `${path}/${data}`
  });
}

const changeSenderStatut = async (value, idSmsSender) => {
  setSpinner(true)
  Service.changeSenderStatut(value, idSmsSender).then(
    async (response) => {
      getSenderAll();
      setSpinner(false)
      toast.success(response.data, {position: "top-center", autoClose: 10000,})
    }, error => {
      setSpinner(false);
      toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
  })
}

const supprimer = async (idApi) => {
  setSpinner(true)
  Service.supprimerApi(idApi).then(
    async (response) => {
      getAPIsList();
      setSpinner(false)
      toast.success("Suppression effectuée avec succès", {position: "top-center", autoClose: 10000,})
    }, error => {
      setSpinner(false);
      toast.error("Impossible de faire de faire cette suppression. Car il a des enregistrements", {position: "top-center", autoClose: 10000,})
  })
}

const modifier = async (item) => {
  if(item.idcode.split("--")[1] !== undefined) {
    item.activationclient = "--" + item.idcode.split("--")[1]
  }
  item.idcode = item.idcode.split("--")[0]
  setState(item);
  setOpen(true)
}

const columns2 = [
  {
    name: "smsSender.name",
    label: "Expéditeur",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "smsSender.statut",
    label: "Etat",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "id",
    label: "Action",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        let senutil = tableMeta.tableData[tableMeta.rowIndex];
        return <div>
                    <strong>Changer d'etat</strong>
                    <br/>
                    <Select
                      style={{ width: 200 }}
                      dropdownStyle={{zIndex: 1300}}
                      onChange={(e)=>changeSenderStatut(e, senutil.smsSender.id)}
                      options={[
                        { value: 'En attente', label: 'En attente' },
                        { value: 'Validé', label: 'Validé' },
                        { value: 'Rejeté', label: 'Rejeté' }
                      ]}
                    />
              </div>;
      }
    }
  }
];

  return (
    <>
      <ToastContainer />
      <PageTitle title="Gestion des APIs" />
      {user.roles.some((a)=> a.name === Constant.ROLE_GESTION_API_AJOUT || a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_GESTION_API) &&
        <Grid container spacing={4} style={{marginBottom: 20}}>
          <Grid item xs={12}>
            <Button onClick={(e)=>{setOpen(true); init()}} variant="outlined" startIcon={<Add />} style={{color: 'green', borderColor: 'green'}}>
              Ajouter un API
            </Button>
          </Grid>
        </Grid>
      }
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color="secondary" />}
          <Widget title="Listes des APIs" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            {reponse.length > 0 && 
            <Table className="mb-0">
                <TableHead>
                  <TableRow>
                    <TableCell>Identifiant de l'api</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Code Pour faire les tests</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Facturation</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Date d'ajout</TableCell>
                    <TableCell colSpan={2}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reponse.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="pl-3 fw-normal">{item.idcode}</TableCell>
                      <TableCell>{item.nom}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.codetest}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.montant}{item.type === "Pourcentage" ? "%" : " " + Constant.devis}</TableCell>
                      <TableCell>
                        <Chip label={item.statut ? "Activé" : "Desactivé"} classes={{root: item.statut ? classes.success : classes.secondary}}/>
                      </TableCell>
                      <TableCell>{item.dateinscription}</TableCell>
                      <TableCell>
                        {user.roles.some((a)=> a.name === Constant.ROLE_GESTION_API_AJOUT || a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_GESTION_API) &&
                          <>
                            <IconButton color="primary" style={{fontSize: 15}} onClick={(e)=> {modifier(item)}}><Edit /> Modifier</IconButton>
                            <br/>
                            <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm('Êtes-vous sûr de faire cette suppression ?'))supprimer(item.id)}}><Delete /> Supprimer</IconButton>
                            <br/>
                            <IconButton style={{color: item.statut ? 'red' : 'green', fontSize: 15}} onClick={(e)=> changeStatut(item.id)}> {item.statut ? <><Cancel /> Désactiver</>: <><Check /> Activer</>}</IconButton>
                          </>
                        }
                      </TableCell>
                      <TableCell>
                          {user.roles.some((a)=> a.name === Constant.ROLE_GESTION_UTILISATEUR || a.name === Constant.ROLE_SEVOB_SUPER_ADMIN) &&
                              <Button onClick={(e)=>{navigation("/app/gestionUtilisateur", `${item.id}2474&${item.nom}`)}} size="small" variant="contained" startIcon={<Person />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                                Utilisateurs
                              </Button>
                          }
                          {(user.roles.some((a)=> a.name === Constant.ROLE_GESTION_API_SMS_SENDER_ID || a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_GESTION_API) && item.idcode.includes("sms")) &&
                            <Button onClick={(e)=>{setOpen2(true)}} size="small" variant="contained" startIcon={<Sms />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                              SMS expéditeurs
                            </Button>
                          }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            }
          </Widget>
        </Grid>
      </Grid>


      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1200}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item lg={4}>
              </Grid>
              <Grid item lg={4}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Page d'ajout des APIs
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8}>
                          <Grid container spacing={3}>
                          <Grid item xs={12}>
                                <span>Identifiant de l'api</span>
                                <br/>
                                <Select
                                  style={{ width: "100%", zIndex: 1}}
                                  dropdownStyle={{zIndex: 1300}}
                                  onChange={(e)=>setState({...state, idcode: e})}
                                  defaultValue={state.idcode}
                                  options={Constant.ApiList}
                                />
                                {error.idcode && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.nom} error={error.nom} helperText={error.nom && Constant.champObligatoire} onChange={(e)=>setState({...state, nom: e.target.value})} size="small" label="Nom de l'API" variant="outlined" fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Edit />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.description} error={error.description} helperText={error.description && Constant.champObligatoire} onChange={(e)=>setState({...state, description: e.target.value})} size="small" label="Description de l'API" variant="outlined" fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Edit />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField defaultValue={state.codetest} onChange={(e)=>setState({...state, codetest: e.target.value})} size="small" label="Code pour faire les tests" variant="outlined" fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Edit />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                                <span>Type de facturation</span>
                                <br/>
                                <Select
                                  style={{ width: "100%", zIndex: 1}}
                                  onChange={(e)=>setState({...state, type: e})}
                                  dropdownStyle={{zIndex: 1300}}
                                  defaultValue={state.type}
                                  options={[
                                    { value: 'Pourcentage', label: 'Pourcentage' },
                                    { value: 'Transaction', label: 'Transaction' },
                                    { value: 'Journalière', label: 'Journalière' },
                                    { value: 'Hebdomendaire', label: 'Hebdomendaire' },
                                    { value: 'Mensuel', label: 'Mensuel' },
                                    { value: 'Trimestriel', label: 'Trimestriel' },
                                    { value: 'Annuelle', label: 'Annuelle' },
                                  ]}
                                />
                                {error.type && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <TextField type="number" defaultValue={state.montant} error={error.montant} helperText={error.montant && Constant.champObligatoire} onChange={(e)=>setState({...state, montant: e.target.value})} size="small" label="Montant de la facturation" variant="outlined" fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AccountBalanceWallet />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                                <span>Le client peut il l'activer lui même</span>
                                <br/>
                                <Select
                                  style={{ width: "100%", zIndex: 1}}
                                  dropdownStyle={{zIndex: 1300}}
                                  onChange={(e)=>setState({...state, activationclient: e})}
                                  defaultValue={state.activationclient}
                                  options={[
                                    { value: '', label: 'Oui' },
                                    { value: '--admin', label: 'Non' },
                                  ]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === 0 ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item lg={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>

      <Modal
        open={open2}
        onClose={(e)=>setOpen2(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1200, overflow: 'scroll'}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item lg={2}>
              </Grid>
              <Grid item lg={8}>
                <Paper square  style={{marginTop: 50, padding: 50, paddingTop: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50}}>
                       Les expéditeurs pour les SMS
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {spinner && <LinearProgress color="secondary" />}
                        </Grid>
                        <Grid item lg={1}>
                        </Grid>
                        <Grid item lg={10}>
                          <Grid container spacing={3}>
                              <Grid sm={12}>
                                  <MUIDataTable
                                    title={"Mes expéditeurs"}
                                    data={reponse2}
                                    columns={columns2}
                                    options={{
                                      filter: true,
                                      draggableColumns: { enabled: true },
                                      enableNestedDataAccess: '.',
                                      filterType: "multiselect",
                                      serverSide: true,
                                      jumpToPage: true,
                                      textLabels: {
                                        body: {
                                          noMatch: 'Rien à afficher pour le moment',
                                        }
                                      },
                                      selectableRows: "none"
                                    }}
                                    />
                              </Grid>
                          </Grid>
                          <br/>
                        </Grid>
                        <Grid item lg={1}>
                        </Grid>
                    </Grid>
                        <Grid item lg={12}>
                          <hr/>
                            <Button variant="outlined" color="default" onClick={(e)=>setOpen2(false)}>
                              Fermer
                            </Button>
                        </Grid>
                </Paper>
              </Grid>
              <Grid item lg={2}>
              </Grid>
            </Grid>

        </div>
      </Modal>
    </>
  );
}
