import { Grid, LinearProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Service from "../../service";

import { DatePicker, Select } from 'antd';

import frFR from 'antd/es/date-picker/locale/fr_FR';
import moment from 'moment';
import 'moment/locale/fr';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Constant } from "../../constant";
const { RangePicker } = DatePicker;

let user = JSON.parse(localStorage.getItem('user'));
export default function MesTransactionsTest(props) {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);

  const periode = async (value) => {
    // await setEndDate(new Date());
    const date = new Date();
    if(value === "Journalière") {
    } else if(value === "Hebdomendaire") {
      await date.setDate(date.getDate() - 7);
    } else if(value === "Mensuel") {
      await date.setDate(date.getDate() - 30);
    } else if(value === "Trimestriel") {
      await date.setDate(date.getDate() - 90);
    } else if(value === "Annuelle") {
      await date.setDate(date.getDate() - 365);
    }
    historique(date, new Date())
  }

  React.useEffect(() => {
    (async ()=> {
    if(props.match.params.data.split("2474&")[1] !== undefined){
      if((user.roles.some(e => e.name === "ROLE_EMPLOYE" || e.name === "ROLE_SEVOB_SUPER_ADMIN"))){
      } else {
        props.history.push("/app/Profil")
      }
    }
  })();
  }, []);

  const historique = async (date, endDate) => {
    setSpinner(true);
    endDate.setMonth(endDate.getMonth());
    date.setMonth(date.getMonth());
    let data = {
      utilisateurid: (props.match.params.data.split("2474&")[1] !== undefined) ? parseInt(props.match.params.data.split("2474&")[0]) : user.id,
      debut: date.getFullYear()+"-"+(date.getMonth() + 1)+"-"+date.getDate(),
      fin: endDate.getFullYear()+"-"+(endDate.getMonth() + 1)+"-"+endDate.getDate(),
    }
    Service.mesTransactionTestList(data).then(
      async (response) => {
        if(response.data !== "") {
          let donnees = []
          response.data.forEach((element, index) => {
            let e = element;
            // "Motif", "Nom", "Montant", "Facturation", "Reste", "Type de paiement", "Etat", "Autres informations", "Commentaire de SEVOB", "Date d'ajout", "Heure"
            donnees.push([
              (e.apiutilisateur !== null && e.apiutilisateur !== undefined) ? e.apiutilisateur.api.nom : "Retrait",
              e.nomentreprise,
              (e.montant.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + Constant.devis),
              e.facturation,
              (e.recetteclient.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " + Constant.devis),
              e.typepaiement,
              e.etat,
              e.autreinformation,
              e.dateinscription,
              e.heure,
            ])
            if(response.data.length === (index + 1)){
              setReponse(donnees);
            }
          });
        }else {
          alert(Constant.noAccess)
          props.history.push("/app/Profil")
        }
        setSpinner(false);
      }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      }
    )
}


function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}
const onChange = (dates, dateStrings) => {
  historique(new Date(dateStrings[0]), new Date(dateStrings[1]))
  // this.setState({debut: dateStrings[0].split("-").reverse().join("-"), fin: dateStrings[1].split("-").reverse().join("-")})
}

  return (
    <>
      <ToastContainer />
      <PageTitle title={(props.match.params.data.split("2474&")[1] !== undefined) ? ("Les transactions TESTS de " + props.match.params.data.split("2474&")[1]) : "Mes transactions TESTS"} />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item sm={2}>
          <strong>Par Période</strong>
          <br/>
          <Select
            style={{ width: '100%' }}
            onChange={(e)=>periode(e)}
            options={[
              { value: 'Journalière', label: 'Journalière' },
              { value: 'Hebdomendaire', label: 'Hebdomendaire' },
              { value: 'Mensuel', label: 'Mensuel' },
              { value: 'Trimestriel', label: 'Trimestriel' },
              { value: 'Annuelle', label: 'Annuelle' },
            ]}
          />
        </Grid>
        <Grid item sm={4}>
          <strong>Par intervalle de Date</strong>
          <br/>
          <RangePicker 
            disabledDate={disabledDate}
            onChange={onChange}
            // format="DD-MM-YYYY"
            style={{width: "100%"}}
            locale={frFR}
            ranges={{
              'Aujourd\'hui': [moment(), moment()],
              'Ce mois': [moment().startOf('month'), moment().endOf('month')],
            }}
            placeholder={["Du", "au"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color="secondary" />}
          <MUIDataTable
            title={(props.match.params.data.split("2474&")[1] !== undefined) ? ("Liste des transactions TESTS de " + props.match.params.data.split("2474&")[1]) : "Liste de mes transactions TESTS"}
            data={reponse}
            columns={["Motif", "Nom", "Montant", "Facturation", "Reste", "Type de paiement", "Etat", "Autres informations", "Date d'ajout", "Heure"]}
            options={{
              filterType: "checkbox",
              textLabels: {
                body: {
                  noMatch: 'Rien à afficher pour le moment',
                }
              },
              selectableRows: "none"
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
