import { Select } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from 'react-toastify';
import { Constant } from "../../constant";
import Service from "../../service";
import Geolocalisation from "../geolocalisation/Geolocalisation";
let user = JSON.parse(localStorage.getItem('user'));
export default function MesGPS(props) {
  const [clesClient, setClesClient] = useState([]);
  const navigate = useHistory();
  React.useEffect(() => {
    (async ()=> {
      getApisDuClient()
    })();
    
  }, []);


  const getApisDuClient = () => {
    Service.getApisDuClient(user.id, "gpstrack").then(
      async (response) => {
        setClesClient(response.data.data);
        if(response.data.data.length === 0) {
          toast.error(Constant.pasApiActiver + " les GPS", {position: "top-center", autoClose: 10000,})
        }
      })
  }


const navigation = (path, data) => {
  navigate.push({
    pathname: `${path}/${data}`
  });
  window.location.reload()
}

  return (
    <>
    <ToastContainer />
    <div style={{textAlign: props.match.params.data.split("2474&")[0] !== "show" ? "left" : 'center'}}>
          <Select
              showSearch
              allowClear
              defaultValue={props.match.params.data.split("2474&")[0] !== "show" ? props.match.params.data.split("2474&")[0] : null}
              style={{ width: 250, textAlign: 'center'}}
              dropdownStyle={{zIndex: 1300}}
              placeholder={<strong style={{color: 'red'}}>Sélectionnez une clé ici</strong>}
              onChange={(e)=>e !== undefined && navigation("/app/MesGPS", `${e}${(props.match.params.data.split("2474&")[1] !== undefined) ? ('2474&' + props.match.params.data.split("2474&")[1]) : ""}`)}
              options={
                clesClient.length > 0 && clesClient.map((element) => {
                  return { "value": `${element.id}${element.cle}`, "label": `CLE: ${element.id}${element.cle}` }
                })
              }
            />
        </div>
    {
      props.match.params.data.split("2474&")[0] !== "show"
      &&
          <>
            <Geolocalisation props={props}/>
          </>
    }
    </>
  );
}
