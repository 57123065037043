import { Chip, Grid, LinearProgress, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Service from "../../service";

import { DatePicker, Modal, Select } from 'antd';

import frFR from 'antd/es/date-picker/locale/fr_FR';
import moment from 'moment';
import 'moment/locale/fr';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Constant } from "../../constant";
import useStyles from "../dashboard/styles";
const { RangePicker } = DatePicker;

let etat = "";
let user = JSON.parse(localStorage.getItem('user'));
let dateConst = {}
export default function DemandeRetrait(props) {
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const navigate = useHistory();

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
       }
    },
    {
      name: "recetteclient",
      label: "Montant",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value + " " + Constant.devis
        }
       }
    },
    {
      name: "typepaiement",
      label: "Type de retrait",
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "intitution",
      label: "Institution financière",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <pre>{value}</pre>
        }
       }
    },
    {
      name: "nomentreprise",
      label: "Nom-information",
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "autreinformation",
      label: "Autres informations",
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "etat",
      label: "Etat",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Chip label={value} classes={{root: value.includes("effectué") ? classes.success : (value.includes("annulé") ? classes.secondary : (value.includes("cours") ? classes.blue : classes.warning))}}/>;
        }
       }
    },
    {
      name: "date",
      label: "Date et Heure",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <pre>{value}</pre>
        }
       }
    },
    {
      name: "nomUtilisateur",
      label: "Client",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <pre>{value}</pre>
        }
       }
    },
    {
      name: "commentaire",
      label: "Commentaire de SEVOB",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <pre>{value}</pre>
        }
       }
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>
                  <strong>Changer d'etat</strong>
                  <br/>
                  <Select
                    style={{ width: 200 }}
                    dropdownStyle={{zIndex: 1300}}
                    onChange={(e)=>showModal(e, value)}
                    options={[
                      { value: 'Retrait en attente', label: 'Retrait en attente' },
                      { value: 'Retrait en cours', label: 'Retrait en cours' },
                      { value: 'Retrait annulé', label: 'Retrait annulé' },
                      { value: 'Retrait effectué', label: 'Retrait effectué' }
                    ]}
                  />
            </div>;
        }
      }
    }
  ];

  const [isModalOpen, setIsModalOpen] = useState({
    show: false,
    etat: "",
    commentaire: "",
    id: 0,
    adminid: user.id,
  });

  const showModal = (etat, id) => {
    setIsModalOpen(
      {
        show: true,
        etat: etat,
        commentaire: "",
        id: id,
        adminid: user.id,
      }
    );
  };

  const handleOk = () => {
    setSpinner(true);
    Service.AnnulerRetrait(isModalOpen).then(
      async (response) => {
        setSpinner(false);
        setIsModalOpen({
          show: false,
          etat: "",
          commentaire: "",
          id: 0,
          adminid: user.id,
        });
        if(dateConst.utilisateurid !== undefined){
          let d = dateConst.debut.setMonth(dateConst.debut.getMonth() - 1);
          let dend = dateConst.fin.setMonth(dateConst.fin.getMonth() - 1);
          historique(new Date(d), new Date(dend))
        }
        toast.success(response.data, {position: "top-center", autoClose: 10000,})
      }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      }
    )
  };

  const handleCancel = () => {
    setIsModalOpen({
      show: false,
      etat: "",
      commentaire: "",
      id: 0,
      adminid: user.id,
    });
  };


  React.useEffect(() => {
    (async ()=> {
    // if(props.match.params.data.split("2474&")[1] !== undefined){
    //   if((user.roles.some(e => e.name === "ROLE_EMPLOYE" || e.name === "ROLE_SEVOB_SUPER_ADMIN"))){
    //   } else {
    //     props.history.push("/app/Profil")
    //   }
    // }
  })();
  }, []);

  const navigation = (path, data) => {
    navigate.push({
      pathname: `${path}/${data}`
    });
  }

  const periode = async (value) => {
    // await setEndDate(new Date());
    const date = new Date();
    if(value === "Journalière") {
    } else if(value === "Hebdomendaire") {
      await date.setDate(date.getDate() - 7);
    } else if(value === "Mensuel") {
      await date.setDate(date.getDate() - 30);
    } else if(value === "Trimestriel") {
      await date.setDate(date.getDate() - 90);
    } else if(value === "Annuelle") {
      await date.setDate(date.getDate() - 365);
    }
    historique(date, new Date())
  }


  const historique = async (date, endDate) => {
    setSpinner(true);
    endDate.setMonth(endDate.getMonth());
    date.setMonth(date.getMonth());
    let data = {
      // utilisateurid: localStorage.getItem('user').id,
      debut: date.getFullYear()+"-"+(date.getMonth() + 1)+"-"+date.getDate(),
      fin: endDate.getFullYear()+"-"+(endDate.getMonth() + 1)+"-"+endDate.getDate(),
    }

    dateConst = {
      utilisateurid: 1,
      debut: date,
      fin: endDate
    };

    // if((props.match.params.data.split("2474&")[1] !== undefined)){
    //   data.idApi = parseInt(props.match.params.data.split("2474&")[0]);
    // }
    Service.utilisateurRetraitTransfert(data).then(
      async (response) => {
        if(typeof(response.data) !== "string"){
          let donnees = []
          response.data.forEach((element, index) => {
            let transaction = element.transaction;
            let utilisateur = element.utilisateur;
            donnees.push({
              id: transaction.id, 
              recetteclient: transaction.recetteclient, 
              typepaiement: transaction.typepaiement, 
              etat: transaction.etat, 
              autreinformation: transaction.autreinformation,
              nomentreprise: transaction.nomentreprise,
              commentaire: transaction.commentaire, 
              date: transaction.dateinscription + "\n" + transaction.heure,
              nomUtilisateur: "Nom:  "+utilisateur.nom + "\nTéléphone:  " + utilisateur.telephone + "\nEmail:  " + utilisateur.users.nomUtilisateur,
              intitution: element.intitution + "\n" + element.numero
            })
            if(response.data.length === (index + 1)){
              setReponse(donnees);
            }
          });
        } else {
          alert(response.data)
          props.history.push("/app/Profil")
        }
        setSpinner(false);
      }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      }
    )
}


function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}
const onChange = (dates, dateStrings) => {
  historique(new Date(dateStrings[0]), new Date(dateStrings[1]))
  // this.setState({debut: dateStrings[0].split("-").reverse().join("-"), fin: dateStrings[1].split("-").reverse().join("-")})
}

  return (
    <>
      <ToastContainer />
      <PageTitle title={"Gestion des demandes de retrait"} />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item sm={2}>
          <strong>Par Période</strong>
          <br/>
          <Select
            style={{ width: '100%' }}
            dropdownStyle={{zIndex: 1300}}
            onChange={(e)=>periode(e)}
            options={[
              { value: 'Journalière', label: 'Journalière' },
              { value: 'Hebdomendaire', label: 'Hebdomendaire' },
              { value: 'Mensuel', label: 'Mensuel' },
              { value: 'Trimestriel', label: 'Trimestriel' },
              { value: 'Annuelle', label: 'Annuelle' },
            ]}
          />
        </Grid>
        <Grid item sm={4}>
          <strong>Par intervalle de Date</strong>
          <br/>
          <RangePicker 
            disabledDate={disabledDate}
            onChange={onChange}
            // format="DD-MM-YYYY"
            style={{width: "100%"}}
            locale={frFR}
            ranges={{
              'Aujourd\'hui': [moment(), moment()],
              'Ce mois': [moment().startOf('month'), moment().endOf('month')],
            }}
            placeholder={["Du", "au"]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color="secondary" />}
          <MUIDataTable
            title="Listes des demandes de retrait"
            data={reponse}
            columns={columns}
            // columns={["ID", "Nom", "Téléphone", "Email", "Date d'ajout"]}
            options={{
              filterType: "checkbox",
              textLabels: {
                body: {
                  noMatch: 'Rien à afficher pour le moment',
                }
              },
              selectableRows: "none"
            }}
          />
        </Grid>
      </Grid>
      <Modal title="Etes-vous sûr de votre décision ?" open={isModalOpen.show} onOk={handleOk} onCancel={handleCancel} okText="Confirmer" cancelText="Annuler">
        <strong>Votre commentaire</strong>
        <TextField multiline onChange={(e)=>setIsModalOpen({...isModalOpen, commentaire: e.target.value})} size="small" variant="outlined" fullWidth />
      </Modal>
    </>
  );
}
