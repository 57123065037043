import { Button } from "@material-ui/core";
import { Alert } from "antd";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import appstore from '../../assets/appstore.png';
import playstore from '../../assets/playstore.png';
import siteweb from '../../assets/siteweb.png';
import { Constant } from "../../constant";
import Service from "../../service";

function QrCode(props) {
  const { innerWidth: width, innerHeight: height } = window;
  const [response, setResponse] = useState({});
  const [contenu, setContenu] = useState({
    contenubackgroundcolor: "white",
    contenurembourragehorizontale: 10,
    contenurembourrageverticale: 10,
    contenuimage: "",
    contenuimagesize: 150,
    contenuimagewidth: 40,
    contenuimageheight: 40,
    contenuimagetransparence: 1,
    elements: []
  })

  React.useEffect(() => {
    if(props.qrcodecontenu !== undefined) {
      setResponse({code: "200"})
      setContenu(props.qrcodecontenu)
    } else {
      Service.getQrCode(props.match.params.data.split("2474&")[0]).then(
        async (response) => {
          setResponse(response.data)
          if(response.data.code === "200") {
            setContenu(JSON.parse(response.data.qrcodecontenu))
          }
        })
    }
  }, [props]);

  const getIcon = (value) => {
    if(value.element === Constant.ios) {
      return <img src={appstore} style={{height: value.icontaille, width: value.icontaille}} />
    } else if(value.element === Constant.Android) {
      return <img src={playstore} style={{height: value.icontaille, width: value.icontaille}} />
    } else if(value.element === Constant.web) {
      return <img src={siteweb} style={{height: value.icontaille, width: value.icontaille}} />
    }
  }

  return (
    <div>
      {(response.code !== undefined && response.code !== "200") &&
          <Alert
            message={<strong>{"Information"}</strong>}
            description={response.message}
            type={"error"}
            showIcon
          />
      }
      {(response.code !== undefined && response.code === "200") &&
        <div style={{backgroundColor: contenu.contenubackgroundcolor, height: height, textAlign: 'center', paddingTop: contenu.contenurembourrageverticale, paddingBottom: contenu.contenurembourrageverticale, paddingLeft: contenu.contenurembourragehorizontale, paddingRight: contenu.contenurembourragehorizontale}}>
        {contenu.contenuimage !== "" && <img src={contenu.contenuimage} width={contenu.contenuimagewidth} height={contenu.contenuimageheight} style={{opacity: contenu.contenuimagetransparence}}/>}
        <br/>
        {contenu.elements.length > 0 && contenu.elements.map((value, index)=> {
          // ios, android, web
            if(value.element === Constant.ios || value.element === Constant.Android || value.element === Constant.web){
              return <span key={index+"c"}>
                {value.sautdeligne === "Oui" && <br/>}
                <Button startIcon={getIcon(value)} onClick={(e)=>value.lienclick.trim() !== "" && window.open(value.lienclick)} style={{
                backgroundColor: value.background,
                color: value.couleur,
                marginBottom: 0,
                marginTop: value.espacehaut,
                marginLeft: value.espacegauche,
                marginRight: value.espacedroite,
                borderWidth: value.bordurewith,
                borderStyle: value.borduretype,
                borderColor: value.bordurecouleur,
                borderRadius: value.bordurearrondi,
                fontSize: value.taille,
                opacity: value.transparence,
                cursor: 'pointer',
                paddingTop: value.rembourrageverticale,
                paddingBottom: value.rembourrageverticale,
                paddingLeft: value.rembourragehorizontale,
                paddingRight: value.rembourragehorizontale
              }}> {value.text}</Button>
              </span>
          // image
            } else if(value.element === Constant.Image){
              return <span key={index+"c"} onClick={(e)=>value.lienclick.trim() !== "" && window.open(value.lienclick)}>
                  {value.sautdeligne === "Oui" && <br/>}
                  <img src={value.lien} width={value.width + "%"} height={value.height + "%"}
                      style={{
                        marginBottom: 0,
                        marginTop: value.espacehaut,
                        marginLeft: value.espacegauche,
                        marginRight: value.espacedroite,
                        borderWidth: value.bordurewith,
                        borderStyle: value.borduretype,
                        borderColor: value.bordurecouleur,
                        borderRadius: value.bordurearrondi,
                        opacity: value.transparence,
                        paddingTop: value.rembourrageverticale,
                        paddingBottom: value.rembourrageverticale,
                        paddingLeft: value.rembourragehorizontale,
                        paddingRight: value.rembourragehorizontale
                      }}
                    />
                </span>
          // Text
            } else if(value.element === Constant.Text){
              return <span key={index+"c"} onClick={(e)=>value.lienclick.trim() !== "" && window.open(value.lienclick)}>
                  {value.sautdeligne === "Oui" && <br/>}
                  <pre
                    style={{
                      marginBottom: 0,
                      marginTop: value.espacehaut,
                      marginLeft: value.espacegauche,
                      marginRight: value.espacedroite,
                      borderWidth: value.bordurewith,
                      borderStyle: value.borduretype,
                      borderColor: value.bordurecouleur,
                      borderRadius: value.bordurearrondi,
                      opacity: value.transparence,
                      backgroundColor: value.background,
                      color: value.couleur,
                      fontSize: value.taille,
                      textAlign: "start",
                      whiteSpace: "pre-wrap",
                      paddingTop: value.rembourrageverticale,
                      paddingBottom: value.rembourrageverticale,
                      paddingLeft: value.rembourragehorizontale,
                      paddingRight: value.rembourragehorizontale,
                    }}
                    >
                    {value.text}
                  </pre>
              </span>
          // Video
            } else if(value.element === Constant.video){
              return <span key={index+"c"} onClick={(e)=>value.lienclick.trim() !== "" && window.open(value.lienclick)}>
                <iframe width={value.width + "%"} height={value.height + "%"} src={value.lien} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                style={{
                  marginBottom: 0,
                  marginTop: value.espacehaut,
                  marginLeft: value.espacegauche,
                  marginRight: value.espacedroite,
                  borderWidth: value.bordurewith,
                  borderStyle: value.borduretype,
                  borderColor: value.bordurecouleur,
                  borderRadius: value.bordurearrondi,
                  opacity: value.transparence,
                  paddingTop: value.rembourrageverticale,
                  paddingBottom: value.rembourrageverticale,
                  paddingLeft: value.rembourragehorizontale,
                  paddingRight: value.rembourragehorizontale
                }}
                ></iframe>
                  
                </span>
            }
        })}
      </div>
      }
    </div>
  );
}

export default withRouter(QrCode);
