export const Constant = {
    couleurPrimaire: "#6D859E",
    couleurSecondaire: "#E93323",
    textPrimaire: "white",
    CleDeSevob: "4n",
    devis: "FCFA",
    champObligatoire: "Ce champs est obligatoire",
    erreurServeur: "Erreur du serveur. Verifier votre connexion internet.",
    pasApiActiver: "Vous n'avez pas d'api activé pour",
    faireRetrait: "Faire un retrait",
    faireRecharge: "Recharger mon compte",
    insererNumeros: "Veuillez saisir au minimum un numéro",
    acheterSms: "Acheter des SMS maintenant",
    activerAbonnement: "Activer mon abonnement",
    envoyerSms: "Envoyer des SMS",
    faireTransfert: "Faire un transfert",
    clientGestion: "Mes groupes",
    mesExpediteur: "Mes expéditeurs",
    lienLogo: "https://www.sevob.net/SEVOB.png",
    lienQrCode: "https://www.sevob.net/#/QrCode/",
    acheter: "Acheter",
    SevobTest: "SEVOB",
    faireRetraitSevob: "Faire un retrait sur le compte de SEVOB",
    faireTransfertSevob: "Faire un transfert depuis le compte de SEVOB",
    conceptionLogiciel: "Conception de logiciel",
    integrationGps: "Intégration des gps",
    formationProfessionnelles: "Formations professionnelles",
    venteEtPrestation: "Ventes et prestation de service",
    paiementweb: "paiementweb",
    gpstrack: "gpstrack",
    sms: "sms",
    qrcode: "qr",
    Valider: "Validé",
    depart: "Départ",
    FONCTIONNEL: "FONCTIONNEL",
    Autre: "Autre",
    DAMAGED: "DAMAGED",
    LOSE: "LOSE",
    INSTALLED: "INSTALLED",
    CONFIGURED: "CONFIGURED",
    lienNonValide: "Ce lien n'est pas valide",
    positionActuelle: '<h4>Votre position actuelle</h4>',
    appartenance: [
        { "value": "", "label": "Toutes" },
        { "value": "SEVOB", "label": "SEVOB" },
        { "value": "FABA Logistique", "label": "FABA Logistique" },
      ],
    typeVehicule: [
        { "value": "", "label": "Tout" },
        { "value": "Moto", "label": "Moto" },
        { "value": "Voiture", "label": "Voiture" },
        { "value": "Camion", "label": "Camion" },
        { "value": "Bateau", "label": "Bateau" },
      ],
    etat: [
        { "value": "", "label": "Tout" },
        { "value": "CONFIGURED", "label": "Configuré" },
        { "value": "INSTALLED", "label": "Installé" },
        { "value": "FONCTIONNEL", "label": "Fonctionnel" },
        { "value": "DAMAGED", "label": "Non fonctionnel" },
        { "value": "LOSE", "label": "Perdu" },
      ],
    ApiList: [
        { "value": "paiementweb", "label": "Paiements en ligne" },
        { "value": "gpstrack", "label": "GPS tackeurs" },
        { "value": "sms", "label": "SMS" },
        { "value": "qrcode", "label": "Qr code" },
      ],
    ROLE_USERS: "ROLE_USERS",
    ROLE_SEVOB_SUPER_ADMIN: "ROLE_SEVOB_SUPER_ADMIN",
    ROLE_EMPLOYE: "ROLE_EMPLOYE",
    ROLE_STATISTIQUES_SEVOB: "ROLE_STATISTIQUES_SEVOB",
    ROLE_MODIFIER_FACTURATION: "ROLE_MODIFIER_FACTURATION",
    ROLE_MODIFIER_TYPE_FACTURATION: "ROLE_MODIFIER_FACTURATION",
    ROLE_TRANSFERT_SEVOB: "ROLE_TRANSFERT_SEVOB",
    ROLE_RETRAIT_SEVOB: "ROLE_RETRAIT_SEVOB",
    ROLE_GESTION_UTILISATEUR: "ROLE_GESTION_UTILISATEUR",
    ROLE_GESTION_UTILISATEUR_TRANSACTIONS_TEST: "ROLE_GESTION_UTILISATEUR_TRANSACTIONS_TEST",
	  ROLE_GESTION_UTILISATEUR_TRANSACTIONS: "ROLE_GESTION_UTILISATEUR_TRANSACTIONS",
	  ROLE_GESTION_UTILISATEUR_API: "ROLE_GESTION_UTILISATEUR_API",
	  ROLE_GESTION_UTILISATEUR_COMPTE: "ROLE_GESTION_UTILISATEUR_COMPTE",
	  ROLE_GESTION_UTILISATEUR_RECHERCHE: "ROLE_GESTION_UTILISATEUR_RECHERCHE",
	  ROLE_GESTION_API: "ROLE_GESTION_API",
	  ROLE_GESTION_API_AJOUT: "ROLE_GESTION_API_AJOUT",
	  ROLE_GESTION_API_SMS_SENDER_ID: "ROLE_GESTION_API_SMS_SENDER_ID",
	  ROLE_GESTION_RETRAIT: "ROLE_GESTION_RETRAIT",
    visible: "visible",
    hidden: "hidden",
    noAccess: "L'accès vous est refusé.",
    ios: "Application IOS",
    Android: "Application Android",
    web: "Site web",
    Image: "Image",
    video: "Vidéo et autres",
    Text: "Text",
}