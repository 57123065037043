import {
  Box,
  Button,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Tab,
  Tabs,
  TextField
} from "@material-ui/core";
import React, { useState } from "react";


// components
import DeviceEmulator from 'react-device-emulator';
import 'react-device-emulator/lib/styles/style.css';
import PageTitle from "../../components/PageTitle/PageTitle";
// data

import {
  QrcodeOutlined
} from '@ant-design/icons';
import { Android, Apple, Delete, Edit, Image, Settings, VideoLabel, Visibility, Web } from "@material-ui/icons";
import { Affix, Alert, Collapse, ColorPicker, DatePicker, Popover, Segmented, Select, Slider } from 'antd';
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import axios from "axios";
import 'moment/locale/fr';
import { QRCode } from 'react-qrcode-logo';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appstore from '../../assets/appstore.png';
import playstore from '../../assets/playstore.png';
import siteweb from '../../assets/siteweb.png';
import { Constant } from "../../constant";
import Service from "../../service";
import useStyles from "../dashboard/styles";
import QrCode from "../qrCode/QrCode";

const { RangePicker } = DatePicker;


let user = JSON.parse(localStorage.getItem('user'));
export default function GestionQrCode(props) {
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [clesClient, setClesClient] = useState([]);
  const navigate = useHistory();
  var [titre, setTitre] = useState("");
  const [response, setResponse] = useState({});
  const [state, setState] = useState({
    qrcodecolor: "black",
    qrcodeoeilcolor: "black",
    qrcodeoeilbordure: 5,
    qrcodebackgroundcolor: "white",
    qrcodeimage: "",
    qrcodeimagesize: 150,
    qrcodeimagewidth: 40,
    qrcodeimageheight: 40,
    qrcodeimagetransparence: 1,
    qrcodezonevide: 10,
    qrcodeniveau: "H",
    qrcodetype: "squares",
  })

  const [contenu, setContenu] = useState({
    contenubackgroundcolor: "white",
    contenurembourragehorizontale: 10,
    contenurembourrageverticale: 10,
    contenuimage: "",
    contenuimagesize: 150,
    contenuimagewidth: 40,
    contenuimageheight: 40,
    contenuimagetransparence: 1,
    elements: []
  })

  const priceAbonnement = [
    {
      jours: 31,
      prixParJour: 161.290,
    },
    {
      jours: 93,
      prixParJour: 129.032,
    },
    {
      jours: 186,
      prixParJour: 112.903,
    },
    {
      jours: 372,
      prixParJour: 96.774,
    },
    {
      jours: 1116,
      prixParJour: 80.645,
    },
    {
      jours: 1860,
      prixParJour: 64.516,
    },
  ]

  React.useEffect(() => {
    getApisDuClient()
    if(props.match.params.data.split("2474&")[0] !== "show"){
      Service.getQrCode(props.match.params.data.split("2474&")[0]).then(
        async (response) => {
          setResponse(response.data)
          if(response.data.code === "200") {
            setState(JSON.parse(response.data.qrcode))
            setContenu(JSON.parse(response.data.qrcodecontenu))
          }
        })
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);


  const getApisDuClient = () => {
    Service.getApisDuClient(user.id, "qrcode").then(
      async (response) => {
        setClesClient(response.data.data);
        if(response.data.data.length === 0) {
          toast.error(Constant.pasApiActiver + " les Qr codes", {position: "top-center", autoClose: 10000,})
        }
      })
  }


const navigation = (path, data) => {
  navigate.push({
    pathname: `${path}/${data}`
  });
  window.location.reload()
}

  const downloadCode = () => {
    const canvas = document.getElementById("QR");
    if(canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl
      downloadLink.download = `qr_code.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const addElement = (element) => {
    let d = {
        element: element,
        bordurewith: 1,
        borduretype: "solid",
        bordurecouleur: "red",
        bordurearrondi: 5,
        espacehaut: 10,
        espacegauche: 0,
        espacedroite: 0,
        rembourragehorizontale: 5,
        rembourrageverticale: 5,
        transparence: 1,
        lienclick: "",
        sautdeligne:"Oui"
    }
    if(element === Constant.ios || element === Constant.Android || element === Constant.web){
        d.text= "text du bouton"
        d.background= Constant.couleurPrimaire
        // d.iconcouleur= "red"
        d.icontaille= 10
        d.couleur= "white"
        d.taille= 10
    } else if(element === Constant.Image || element === Constant.video){
        d.lien= ""
        d.height= 200
        d.width= 200
    } else if(element === Constant.Text){
        d.background= "white"
        d.couleur= "black"
        d.text= "text ici"
        d.taille= 10
    }
    contenu.elements.push(d)
    setOpen(false)
  };

  const genExtra = (index) => (
    <Delete
      onClick={(event) => {
        setContenu({...contenu, elements: contenu.elements.filter((a, ind) => ind !== index)})
        event.stopPropagation();
      }}
    />
  );

  const getBordure = (value) => (
    <>
      <TextField label="Redirection (Optionnel)" variant="outlined" size="small" value={value.lienclick} onChange={(e) => {value.lienclick= e.target.value; setContenu({...contenu, elements: contenu.elements})}}/><br/><br/>
      <div>Saut de ligne: <Segmented options={['Oui','Non']} value={value.sautdeligne} onChange={(e) => {value.sautdeligne= e; setContenu({...contenu, elements: contenu.elements})}} /></div><br/>
      <div>Taille de la Bordure: <Slider min={0} max={10} value={value.bordurewith} onChange={(e)=> {value.bordurewith= e; setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
      <div>Type de la Bordure: <Segmented options={['none','solid', 'dashed', 'dotted', 'double', 'groove']} value={value.borduretype} onChange={(e) => {value.borduretype= e; setContenu({...contenu, elements: contenu.elements})}} /></div><br/>
      <div>Couleur de la Bordure: <ColorPicker value={value.bordurecouleur} onChangeComplete={(color)=> {value.bordurecouleur= color.toHexString(); setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
      <div>Contour de la Bordure: <Slider min={0} max={100} value={value.bordurearrondi} onChange={(e)=> {value.bordurearrondi= e; setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
      <div>Espace d'en haut: <Slider min={0} max={100} value={value.espacehaut} onChange={(e)=> {value.espacehaut= e; setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
      <div>Espace de gauche: <Slider min={0} max={100} value={value.espacegauche} onChange={(e)=> {value.espacegauche= e; setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
      <div>Espace de droite: <Slider min={0} max={100} value={value.espacedroite} onChange={(e)=> {value.espacedroite= e; setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
      <div>Rembourrage horizontal: <Slider min={0} max={100} value={value.rembourragehorizontale} onChange={(e)=> {value.rembourragehorizontale= e; setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
      <div>Rembourrage vertical: <Slider min={0} max={100} value={value.rembourrageverticale} onChange={(e)=> {value.rembourrageverticale= e; setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
      <div>Transparence: <Slider step={0.1} min={0} max={1} value={value.transparence} onChange={(e)=> {value.transparence= e; setContenu({...contenu, elements: contenu.elements})}}/></div>
    </>
  );

  const getIcon = (value) => {
    if(value.element === Constant.ios) {
      return <img src={appstore} style={{height: value.icontaille, width: value.icontaille}} />
    } else if(value.element === Constant.Android) {
      return <img src={playstore} style={{height: value.icontaille, width: value.icontaille}} />
    } else if(value.element === Constant.web) {
      return <img src={siteweb} style={{height: value.icontaille, width: value.icontaille}} />
    }
  }

  const Enregistrer = () => {
    setSpinner(true);
    let data = {
      cle: props.match.params.data.split("2474&")[0],
      qrcode: JSON.stringify(state),
      qrcodecontenu: JSON.stringify(contenu)
    }
    Service.addQrCode(data).then(
      async (response) => {
        setSpinner(false);
        if(response.data.includes("refusé")) {
          toast.error(response.data, {position: "top-center", autoClose: 10000,})
        } else {
          toast.success(response.data, {position: "top-center", autoClose: 10000,})
        }
      }, error => {
        toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
        setSpinner(false);
      }
    )
  }

  const OpenModal = async (titre) => {
    setOpen2(true)
    setTitre(titre)
  }


const PayerMonAbonnement = (price) => {
  setSpinner(true);
  Service.addAbonnement(props.match.params.data.split("2474&")[0], price).then(
    async (response) => {
      let body = {
      'mode': 'REEL',
      'cle': Constant.CleDeSevob,
      'nom_entreprise': 'SEVOB-Abonnement',
      'lien_logo_entreprise': Constant.lienLogo,
      'montant': response.data.montant,
      'commande_id': new Date().getTime()+"p",
      'autre_information': '',
      'return_url': window.location.href,
      'cancel_url': 'https://vps82722.serveur-vps.net:2474/sevob/anonyme/changeStatutAbonnement/'+response.data.id,
      'success_url': 'https://vps82722.serveur-vps.net:2474/sevob/anonyme/changeStatutAbonnement/'+response.data.id,
      'error_url': 'https://vps82722.serveur-vps.net:2474/sevob/anonyme/changeStatutAbonnement/'+response.data.id,
      }
      axios.post('https://api.sevob.net', body).then(
        (reponse)=> {
          if(reponse.data.code === "200") {
            window.open(reponse.data.url, "_self")
          } else {
            toast.error(reponse.data.message, {position: "top-center", autoClose: 10000,})
          }
        },
        error => {
          toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
        }
      );
      setSpinner(false)
    }, error => {
      setSpinner(false)
      toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
  })
  
}

  return (
    <>
      <ToastContainer />
      <div style={{textAlign: props.match.params.data.split("2474&")[0] !== "show" ? "left" : 'center'}}>
          <Select
              showSearch
              allowClear
              defaultValue={props.match.params.data.split("2474&")[0] !== "show" ? props.match.params.data.split("2474&")[0] : null}
              style={{ width: 250, textAlign: 'center'}}
              dropdownStyle={{zIndex: 1300}}
              placeholder={<strong style={{color: 'red'}}>Sélectionnez une clé ici</strong>}
              onChange={(e)=>e !== undefined && navigation("/app/GestionQrCode", `${e}${(props.match.params.data.split("2474&")[1] !== undefined) ? ('2474&' + props.match.params.data.split("2474&")[1]) : ""}`)}
              options={
                clesClient.length > 0 && clesClient.map((element) => {
                  return { "value": `${element.id}${element.cle}`, "label": `CLE: ${element.id}${element.cle}` }
                })
              }
            />
        </div>
        {
        props.match.params.data.split("2474&")[0] !== "show"
        &&
          <>
            <PageTitle title="Personnalisation du Qr code" 
            button={
              <div>
                <Button
                  variant="contained"
                  size="medium"
                  style={{backgroundColor: "green", color: "white", marginBottom: 10}}
                  onClick={(e)=>{Enregistrer()}}
                >
                    Enregistrer les modifications
                </Button>
              </div>
            }
            />
            
            <Grid container spacing={4}>
              <Grid item lg={12}>
                {response.code !== undefined &&
                  <Alert
                    message={<strong>{response.code === "200" ? "Information" : "Information urgente"}</strong>}
                    description={response.message}
                    type={response.code === "200" ? "success" : "error"}
                    action={
                      <Button
                        variant="contained"
                        size="medium"
                        style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginTop: 10}}
                        onClick={(e)=>{OpenModal(Constant.activerAbonnement)}}
                      >
                        {Constant.activerAbonnement}
                      </Button>
                    }
                    showIcon
                  />
                }
                {spinner && <LinearProgress color="secondary" />}
              </Grid>
      {/* Reglages */}
              <Grid item lg={5}>
                <h2><Settings/> Réglages</h2>
                <Box sx={{ bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider', marginBottom: 20}}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                  textColor="secondary"
                  variant="fullWidth"
                  indicatorColor="secondary"
                  >
                    <Tab label="Qr code" />
                    <Tab label="Contenu" />
                  </Tabs>
                </Box>
                {/* qr code */}
                {value === 0 &&
                  <div>
                    <div>Niveau: <Segmented options={['L', 'M', 'Q', 'H']} value={state.qrcodeniveau} onChange={(e) => setState({...state, qrcodeniveau: e})} /></div><br/>
                    <div>Type: <Segmented options={['squares', 'dots', 'fluid']} value={state.qrcodetype} onChange={(e) => setState({...state, qrcodetype: e})} /></div><br/>
                    <div>Couleur du qr code: <ColorPicker value={state.qrcodecolor} onChangeComplete={(color)=> setState({...state, qrcodecolor: color.toHexString()})}/></div><br/>
                    <div>Couleur arrière plan du qr code: <ColorPicker value={state.qrcodebackgroundcolor} onChangeComplete={(color)=> setState({...state, qrcodebackgroundcolor: color.toHexString()})}/></div><br/>
                    <div>Couleur des oeils: <ColorPicker value={state.qrcodeoeilcolor} onChangeComplete={(color)=> setState({...state, qrcodeoeilcolor: color.toHexString()})}/></div><br/>
                    <div>Bordure des oeils: <Slider min={0} max={15} value={state.qrcodeoeilbordure} onChange={(e)=> setState({...state, qrcodeoeilbordure: e})}/></div><br/>
                    <TextField label="Taille du qr code" variant="outlined" size="small" type="number" value={state.qrcodeimagesize} onChange={(e) => setState({...state, qrcodeimagesize: parseInt(e.target.value) < 500 ? parseInt(e.target.value) : 500})}/><br/><br/>
                    <TextField label="Lien de l'image" variant="outlined" size="small" value={state.qrcodeimage} onChange={(e) => setState({...state, qrcodeimage: e.target.value})}/><br/><br/>
                    <TextField label="Longueur de l'image" variant="outlined" size="small" type="number" value={state.qrcodeimageheight} onChange={(e) => setState({...state, qrcodeimageheight: parseInt(e.target.value) < 500 ? parseInt(e.target.value) : 500})}/><br/><br/>
                    <TextField label="Largeur de l'image" variant="outlined" size="small" type="number" value={state.qrcodeimagewidth} onChange={(e) => setState({...state, qrcodeimagewidth: parseInt(e.target.value) < 500 ? parseInt(e.target.value) : 500})}/><br/><br/>
                    <div>Transparence de l'image: <Slider step={0.1} min={0} max={1} value={state.qrcodeimagetransparence} onChange={(e)=> setState({...state, qrcodeimagetransparence: e})}/></div>
                    <div>Rembourrage: <Slider step={0.1} min={0} max={100} value={state.qrcodezonevide} onChange={(e)=> setState({...state, qrcodezonevide: e})}/></div><br/>
                  </div>
                }
      {/* contenu */}
                {value === 1 &&
                  <div>
                    <div>Couleur arrière plan du contenu: <ColorPicker value={contenu.contenubackgroundcolor} onChangeComplete={(color)=> setContenu({...contenu, contenubackgroundcolor: color.toHexString()})}/></div><br/>
                    <div>Rembourrage horizontal: <Slider min={0} max={100} value={contenu.contenurembourragehorizontale} onChange={(e)=> setContenu({...contenu, contenurembourragehorizontale: e})}/></div><br/>
                    <div>Rembourrage vertical: <Slider min={0} max={100} value={contenu.contenurembourrageverticale} onChange={(e)=> setContenu({...contenu, contenurembourrageverticale: e})}/></div><br/>
                    <TextField label="Lien du logo" variant="outlined" size="small" value={contenu.contenuimage} onChange={(e) => setContenu({...contenu, contenuimage: e.target.value})}/><br/><br/>
                    <TextField label="Longueur du logo" variant="outlined" size="small" type="number" value={contenu.contenuimageheight} onChange={(e) => setContenu({...contenu, contenuimageheight: parseInt(e.target.value) < 500 ? parseInt(e.target.value) : 500})}/><br/><br/>
                    <TextField label="Largeur du logo" variant="outlined" size="small" type="number" value={contenu.contenuimagewidth} onChange={(e) => setContenu({...contenu, contenuimagewidth: parseInt(e.target.value) < 500 ? parseInt(e.target.value) : 500})}/><br/><br/>
                    <div>Transparence de l'image: <Slider step={0.1} min={0} max={1} value={contenu.contenuimagetransparence} onChange={(e)=> setContenu({...contenu, contenuimagetransparence: e})}/></div>
                    <div style={{textAlign: 'center'}}>
                      <Popover
                        content={
                        <div>
                            <nav aria-label="main mailbox folders">
                              <List>
                                <ListItem onClick={(e)=> addElement(Constant.ios)} style={{cursor: "pointer"}}>
                                    <ListItemIcon>
                                      <Apple />
                                    </ListItemIcon>
                                    <ListItemText primary={Constant.ios} />
                                </ListItem>
                                <ListItem  onClick={(e)=> addElement(Constant.Android)} style={{cursor: "pointer"}}>
                                    <ListItemIcon>
                                      <Android />
                                    </ListItemIcon>
                                    <ListItemText primary={Constant.Android} />
                                </ListItem>
                                <ListItem onClick={(e)=> addElement(Constant.web)} style={{cursor: "pointer"}}>
                                    <ListItemIcon>
                                      <Web />
                                    </ListItemIcon>
                                    <ListItemText primary={Constant.web} />
                                </ListItem>
                                <ListItem onClick={(e)=> addElement(Constant.Text)} style={{cursor: "pointer"}}>
                                    <ListItemIcon>
                                      <Edit />
                                    </ListItemIcon>
                                    <ListItemText primary={Constant.Text} />
                                </ListItem>
                                <ListItem onClick={(e)=> addElement(Constant.Image)} style={{cursor: "pointer"}}>
                                    <ListItemIcon>
                                      <Image />
                                    </ListItemIcon>
                                    <ListItemText primary={Constant.Image} />
                                </ListItem>
                                <ListItem onClick={(e)=> addElement(Constant.video)} style={{cursor: "pointer"}}>
                                    <ListItemIcon>
                                      <VideoLabel />
                                    </ListItemIcon>
                                    <ListItemText primary={Constant.video} />
                                </ListItem>
                              </List>
                            </nav>
                        </div>}
                        title="Sélectionner l'élément à ajouter"
                        trigger="click"
                        placement="bottom"
                        open={open}
                        onOpenChange={handleOpenChange}
                      >
                        <Button variant="contained"
                          size="medium"
                          style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginTop: 10}}
                        >
                            Ajouter un nouvel élément
                        </Button>
                      </Popover>
                    </div>
                    <br/>
                    <div>
                      {contenu.elements.length > 0 && contenu.elements.map((value, index)=> {
                        // ios, android, web
                          if(value.element === Constant.ios || value.element === Constant.Android || value.element === Constant.web){
                              return <Collapse key={index+"c"} defaultActiveKey={index} style={{marginBottom: 5}}>
                                        <CollapsePanel header={value.element} showArrow key={index} extra={genExtra(index)}>
                                          <TextField label="Text" variant="outlined" size="small" value={value.text} onChange={(e) => {value.text= e.target.value; setContenu({...contenu, elements: contenu.elements})}}/><br/><br/>
                                          <TextField label="Taille du text" variant="outlined" size="small" type="number" value={value.taille} onChange={(e) => {value.taille= parseInt(e.target.value) < 50 ? parseInt(e.target.value) : 50; setContenu({...contenu, elements: contenu.elements})}}/><br/><br/>
                                          <div>Couleur du text: <ColorPicker value={value.couleur} onChangeComplete={(color)=> {value.couleur= color.toHexString(); setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
                                          <div>Couleur du bouton: <ColorPicker value={value.background} onChangeComplete={(color)=> {value.background= color.toHexString(); setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
                                          {/* <div>Couleur de l'icône: <ColorPicker value={value.iconcouleur} onChangeComplete={(color)=> {value.iconcouleur= color.toHexString(); setContenu({...contenu, elements: contenu.elements})}}/></div><br/> */}
                                          <TextField label="Taille de l'icône" variant="outlined" size="small" type="number" value={value.icontaille} onChange={(e) => {value.icontaille= parseInt(e.target.value) < 50 ? parseInt(e.target.value) : 50; setContenu({...contenu, elements: contenu.elements})}}/><br/><br/>
                                          {getBordure(value)}
                                        </CollapsePanel>
                                      </Collapse>
                        // Image, video
                          } else if(value.element === Constant.Image || value.element === Constant.video){
                            return <Collapse key={index+"c"} defaultActiveKey={index} style={{marginBottom: 5}}>
                                      <CollapsePanel header={value.element} showArrow key={index} extra={genExtra(index)}>
                                        <TextField label="Lien" variant="outlined" size="small" value={value.lien} onChange={(e) => {value.lien= e.target.value; setContenu({...contenu, elements: contenu.elements});}}/><br/><br/>
                                        <div>Longueur: <Slider min={0} max={100} value={value.height} onChange={(e)=> {value.height= e; setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
                                        <div>Largeur: <Slider min={0} max={100} value={value.width} onChange={(e)=> {value.width= e; setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
                                        {getBordure(value)}
                                      </CollapsePanel>
                                    </Collapse>
                        // Text
                          } else if(value.element === Constant.Text){
                            return <Collapse key={index+"c"} defaultActiveKey={index} style={{marginBottom: 5}}>
                                    <CollapsePanel header={value.element} showArrow key={index} extra={genExtra(index)}>
                                          <TextField multiline maxRows={4} label="Text" variant="outlined" size="small" value={value.text} onChange={(e) => {value.text= e.target.value; setContenu({...contenu, elements: contenu.elements})}}/><br/><br/>
                                          <TextField label="Taille du text" variant="outlined" size="small" type="number" value={value.taille} onChange={(e) => {value.taille= parseInt(e.target.value) < 50 ? parseInt(e.target.value) : 50; setContenu({...contenu, elements: contenu.elements})}}/><br/><br/>
                                          <div>Couleur de l'arrière plan: <ColorPicker value={value.background} onChangeComplete={(color)=> {value.background= color.toHexString(); setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
                                          <div>Couleur du text: <ColorPicker value={value.couleur} onChangeComplete={(color)=> {value.couleur= color.toHexString(); setContenu({...contenu, elements: contenu.elements})}}/></div><br/>
                                          {getBordure(value)}
                                    </CollapsePanel>
                                  </Collapse>
                      }
                      })}
                    </div>
                  </div>
                }
                <div>
                  <Button
                    variant="contained"
                    size="medium"
                    style={{backgroundColor: "green", color: "white", marginBottom: 10}}
                    onClick={(e)=>{Enregistrer()}}
                  >
                      Enregistrer les modifications
                  </Button>
                </div>
              </Grid>
              <Grid item lg={1}></Grid>
          {/* Qrode */}
              <Grid item lg={2}>
                  <Affix offsetTop={50}>
                    <div>
                      <h2><QrcodeOutlined /> Qr code</h2>
                        <QRCode
                          value={Constant.lienQrCode + props.match.params.data.split("2474&")[0]}            // here you should keep the link/value(string) for which you are generation promocode
                          ecLevel={state.qrcodeniveau}
                          qrStyle={state.qrcodetype}
                          fgColor={state.qrcodecolor}
                          bgColor={state.qrcodebackgroundcolor}
                          size={state.qrcodeimagesize}      // the dimension of the QR code (number)
                          logoImage={state.qrcodeimage}  // URL of the logo you want to use, make sure it is a dynamic url
                          logoHeight={state.qrcodeimageheight}
                          logoWidth={state.qrcodeimagewidth}
                          logoOpacity={state.qrcodeimagetransparence}
                          enableCORS={true}       // type of qr code, wether you want dotted ones or the square ones
                          eyeRadius={state.qrcodeoeilbordure}          // radius of the promocode eye
                          eyeColor={state.qrcodeoeilcolor}
                          quietZone={state.qrcodezonevide}          // radius of the promocode eye
                          id={"QR"}
                        />
                        <br/>
                      <Button
                        variant="contained"
                        size="medium"
                        style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginTop: 10}}
                        onClick={(e)=>{downloadCode()}}
                      >
                          Télécharger
                      </Button>
                    </div>
                  </Affix>
              </Grid>
          {/* Resultat */}
              <Grid item xl={4}>
                <Affix offsetTop={50}>
                  <div>
                    <h2><Visibility/> Contenu</h2>
                    <DeviceEmulator type="mobile" withDeviceSwitch withRotator >
                      {/* <div style={{backgroundColor: contenu.contenubackgroundcolor, height: "100vh", textAlign: 'center', paddingTop: contenu.contenurembourrageverticale, paddingBottom: contenu.contenurembourrageverticale, paddingLeft: contenu.contenurembourragehorizontale, paddingRight: contenu.contenurembourragehorizontale}}>
                        {contenu.contenuimage !== "" && <img src={contenu.contenuimage} width={contenu.contenuimagewidth} height={contenu.contenuimageheight} style={{opacity: contenu.contenuimagetransparence}}/>}
                        <br/>
                        {contenu.elements.length > 0 && contenu.elements.map((value, index)=> {
                          // ios, android, web
                            if(value.element === Constant.ios || value.element === Constant.Android || value.element === Constant.web){
                              return <span key={index+"c"}>
                                {value.sautdeligne === "Oui" && <br/>}
                                <Button startIcon={getIcon(value)} onClick={(e)=>value.lienclick.trim() !== "" && window.open(value.lienclick)} style={{
                                backgroundColor: value.background,
                                color: value.couleur,
                                marginTop: value.espacehaut,
                                marginLeft: value.espacegauche,
                                borderWidth: value.bordurewith,
                                borderStyle: value.borduretype,
                                borderColor: value.bordurecouleur,
                                borderRadius: value.bordurearrondi,
                                fontSize: value.taille,
                                opacity: value.transparence,
                                cursor: 'pointer',
                                margin: 0,
                                paddingTop: value.rembourrageverticale,
                                paddingBottom: value.rembourrageverticale,
                                paddingLeft: value.rembourragehorizontale,
                                paddingRight: value.rembourragehorizontale
                              }}> {value.text}</Button>
                              </span>
                          // image
                            } else if(value.element === Constant.Image){
                              return <span key={index+"c"} onClick={(e)=>value.lienclick.trim() !== "" && window.open(value.lienclick)}>
                                  {value.sautdeligne === "Oui" && <br/>}
                                  <img src={value.lien} width={value.width} height={value.height}
                                      style={{
                                        marginTop: value.espacehaut,
                                        marginLeft: value.espacegauche,
                                        borderWidth: value.bordurewith,
                                        borderStyle: value.borduretype,
                                        borderColor: value.bordurecouleur,
                                        borderRadius: value.bordurearrondi,
                                        opacity: value.transparence,
                                        margin: 0,
                                        paddingTop: value.rembourrageverticale,
                                        paddingBottom: value.rembourrageverticale,
                                        paddingLeft: value.rembourragehorizontale,
                                        paddingRight: value.rembourragehorizontale
                                      }}
                                    />
                                </span>
                          // Text
                            } else if(value.element === Constant.Text){
                              return <span key={index+"c"} onClick={(e)=>value.lienclick.trim() !== "" && window.open(value.lienclick)}>
                                  {value.sautdeligne === "Oui" && <br/>}
                                  <pre
                                    style={{
                                      marginTop: value.espacehaut,
                                      marginLeft: value.espacegauche,
                                      borderWidth: value.bordurewith,
                                      borderStyle: value.borduretype,
                                      borderColor: value.bordurecouleur,
                                      borderRadius: value.bordurearrondi,
                                      opacity: value.transparence,
                                      backgroundColor: value.background,
                                      color: value.couleur,
                                      fontSize: value.taille,
                                      textAlign: "start",
                                      whiteSpace: "pre-wrap",
                                      margin: 0,
                                      paddingTop: value.rembourrageverticale,
                                      paddingBottom: value.rembourrageverticale,
                                      paddingLeft: value.rembourragehorizontale,
                                      paddingRight: value.rembourragehorizontale,
                                    }}
                                    >
                                    {value.text}
                                  </pre>
                              </span>
                          // Video
                            } else if(value.element === Constant.video){
                              return <span key={index+"c"} onClick={(e)=>value.lienclick.trim() !== "" && window.open(value.lienclick)}>
                                <iframe width={value.width} height={value.height} src={value.lien} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                                style={{
                                  marginTop: value.espacehaut,
                                  marginLeft: value.espacegauche,
                                  borderWidth: value.bordurewith,
                                  borderStyle: value.borduretype,
                                  borderColor: value.bordurecouleur,
                                  borderRadius: value.bordurearrondi,
                                  opacity: value.transparence,
                                  margin: 0,
                                  paddingTop: value.rembourrageverticale,
                                  paddingBottom: value.rembourrageverticale,
                                  paddingLeft: value.rembourragehorizontale,
                                  paddingRight: value.rembourragehorizontale
                                }}
                                ></iframe>
                                  
                                </span>
                            }
                        })}
                      </div> */}
                      <QrCode qrcodecontenu={contenu}/>
                    </DeviceEmulator>
                  </div>
                </Affix>
              </Grid>
            </Grid>
        </>
      }

      <Modal
        open={open2}
        onClose={(e)=>setOpen2(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1200, overflow: 'scroll'}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item lg={2}>
              </Grid>
              <Grid item lg={8}>
                <Paper square  style={{marginTop: 50, padding: 50, paddingTop: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50}}>
                       {titre}
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {spinner && <LinearProgress color="secondary" />}
                        </Grid>
                        <Grid item lg={1}></Grid>
                        <Grid item lg={10}>
                          <Grid container spacing={3} style={{textAlign: 'center'}}>
                            {priceAbonnement.map((price, index)=>{
                              let mois = price.jours/31
                                return <Grid key={index + "sms"} item sm={4}>
                                      <div style={{borderBottomColor: 'orange', borderBottomWidth: 2, borderBottomStyle: 'solid', paddingLeft: 5, paddingRight: 5}}>
                                        <div style={{backgroundColor: Constant.couleurPrimaire, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                          <p style={{color: 'white', fontWeight: 'bold', fontSize: 20, padding: 5}}>{mois < 12 ? mois + " mois" : (mois/12) + " ans"}</p>
                                          <div style={{backgroundColor: "orange", marginTop: -20, borderTopLeftRadius: 20, borderTopRightRadius: 20}}>
                                            <strong style={{color: 'white', fontWeight: 'bold', fontSize: 30}}>{(price.prixParJour * price.jours).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</strong>
                                            <br/>
                                            <strong style={{color: 'white', fontWeight: 'bold'}}>{Constant.devis}</strong>
                                            <hr style={{marginLeft: 20, marginRight: 20}}/>
                                            <strong>{(price.prixParJour * price.jours).toFixed(0)/mois} {Constant.devis} par mois</strong>
                                            <hr style={{marginLeft: 20, marginRight: 20}}/>
                                            <div>
                                              <Button
                                                variant="contained"
                                                size="medium"
                                                style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginBottom: 10}}
                                                onClick={(e)=>{PayerMonAbonnement(price)}}
                                              >
                                                  {Constant.acheter}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </Grid>
                            })}
                          </Grid>
                        </Grid>
                        <Grid item lg={1}></Grid>
                      </Grid>
                      <Grid item lg={12}>
                        <hr/>
                          <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                            Fermer
                          </Button>
                      </Grid>
                </Paper>
              </Grid>
              <Grid item lg={2}>
              </Grid>
            </Grid>

        </div>
      </Modal>

    </>
  );
}
