import {
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  TextField
} from "@material-ui/core";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo
import i1 from '../../assets/1.png';
import i2 from '../../assets/2.png';
import i3 from '../../assets/3.png';
import i4 from '../../assets/4.png';
import i5 from '../../assets/5.png';
import i6 from '../../assets/6.png';
import i7 from '../../assets/7.png';
import logo from "../../assets/SEVOB.png";
import background from '../../assets/background.jpg';
import footer from '../../assets/footer1.jpeg';
import formations from "../../assets/formations.gif";
import gps from "../../assets/gps.gif";
import moovmoney from '../../assets/moovmoney.png';
import orangemoney from '../../assets/orangemoney.png';
import paiementweb from "../../assets/paiementweb.gif";
import prestation from "../../assets/prestation.gif";
import qr_code1 from '../../assets/qr_code1.png';
import qr_code2 from '../../assets/qr_code2.png';
import qr_code3 from '../../assets/qr_code3.png';
import qrcode from "../../assets/qrcode.gif";
import samamoney from '../../assets/samamoney.png';
import sms from "../../assets/sms.gif";
import ventes from "../../assets/ventes.gif";
import wavemoney from '../../assets/wavemoney.jpeg';

// context
import { Laptop, Smartphone } from "@material-ui/icons";
import { Button as ButtonAntd, Card, Image, Steps, theme } from 'antd';
import { ToastContainer, toast } from "react-toastify";
import Header from "../../components/Header/Header";
import { Constant } from "../../constant";
import { useUserDispatch } from "../../context/UserContext";
import Service from "../../service";

const steps = [
  {
    title: 'Identification',
  },
  {
    title: 'Activation de l\'API',
  }
];

function Login(props) {
  var classes = useStyles();
  // global
  var userDispatch = useUserDispatch();
  var [open, setOpen] = useState(false);
  var [open2, setOpen2] = useState(false);
  const [spinner, setSpinner] = useState(false);
  var [state2, setState2] = useState({
    sujet: "",
    mail: "",
    message: "",
  });
  var [error, setError] = useState({
    sujet: false,
    mail: false,
    message: false,
  });
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  // local
  var [state, setState] = useState({
    back1: Constant.couleurSecondaire,
    back2: Constant.couleurSecondaire,
    back3: Constant.couleurSecondaire,
    back4: Constant.couleurSecondaire,
    back5: Constant.couleurSecondaire,
    back6: Constant.couleurSecondaire,
    back7: Constant.couleurSecondaire,
  });

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: 20
  };


  const envoyer = () => {
    if(state2.mail.trim() === "" || state2.mail.split("@")[1] === undefined){
      setError({...error, mail: true})
    } else if(state2.sujet.trim() === ""){
      setError({...error, sujet: true, mail: false})
    } else if(state2.message.trim() === "" ){
      setError({...error, message: true, sujet: false, mail: false})
    } else {
      setError({...error, message: false, sujet: false, mail: false})
      setSpinner(true);
        Service.contacter(state2).then(
          (response) => {
              setSpinner(false);
              setOpen2(false)
              toast.success("Message envoyé avec succès", {position: "top-center", autoClose: 10000,})
          }, error => {
            setSpinner(false);
            toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
          }
        )
    }
  };


  return (
    <div>
      <ToastContainer />
      <Header />
      <Grid container>
        <Grid item sm={12} style={{backgroundImage: `url(${background})`, height: 500, width: window.screen.width, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}  style={{textAlign: 'center', marginTop: 150, lineHeight: 1, paddingRight: 5}}>
              <img src={logo} alt="logo"  style={{height: 100, width: 100, borderRadius: 100, boxShadow: "0px 0px 10px 0px"}}/>
              <h1 style={{color: Constant.couleurPrimaire}}>SEVOB-SARL</h1>
              <h3>Nous réalisons selon vos besoins</h3>
              {/* <Grid container>
                <Grid item xs={4}><img src={logo} alt="logo"  style={{height: 150, width: 150, borderRadius: 150, borderRight: "solid 5px "+Constant.couleurPrimaire}}/></Grid>
                <Grid item xs={4}><img src={logo} alt="logo"  style={{height: 150, width: 150, borderRadius: 150, borderRight: "solid 5px "+Constant.couleurPrimaire}}/></Grid>
                <Grid item xs={4}><img src={logo} alt="logo"  style={{height: 150, width: 150, borderRadius: 150, borderRight: "solid 5px "+Constant.couleurPrimaire}}/></Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1}></Grid>
        <Grid item sm={10}>
            <Grid container>
                <Grid xs={12} sm={6} lg={3}>
                      <Card
                        title="Paiement en ligne"
                        hoverable
                        headStyle={{backgroundColor: state.back1, color: Constant.textPrimaire, fontSize: 15}} 
                        bordered={false} 
                        onMouseEnter={(e)=> setState({...state, back1: Constant.couleurPrimaire})}
                        onMouseLeave={(e)=> setState({...state, back1: Constant.couleurSecondaire})}
                        style={{ marginRight: 10, marginTop: -55, marginBottom: 70}}
                        cover={
                          <div style={{textAlign: 'center'}}>
                            <img
                              alt="example"
                              src={paiementweb}
                              // style={{height: 150, width: 150}}
                            />
                          </div>
                        }
                        actions={[
                          <div>
                          <Button onClick={(e) => {setOpen(true); setCurrent(0)}} variant={"outlined"} className={classes.purchaseBtn} style={{borderColor: state.back1, color: state.back1}}> Comment ça marche ?</Button>
                        </div>
                        ]}

                        >
                          <div style={{height: 180, marginTop: -50}}>
                                <p>Intégrez rapidement et facilement notre solution pour vos paiements en ligne avec: </p>
                                <div>
                                  <img src={orangemoney} style={{height: 30, borderRadius: 5}} />
                                  <img src={moovmoney} style={{height: 30, borderRadius: 5}} />
                                  <img src={samamoney} style={{height: 30, borderRadius: 5}} />
                                  <img src={wavemoney} style={{height: 30, borderRadius: 5}} />
                                </div>
                                <br/>
                                <div style={{color: 'red'}}>
                                  <strong>1) Frais par transaction: 2.5%</strong><br/>
                                  <strong>2) Possibilité de retrait en moins de 24h</strong>
                                </div>
                          </div>
                      </Card>
                  </Grid>
                <Grid xs={12} sm={6} lg={3}>
                      <Card
                        title="Envoi des SMS"
                        hoverable
                        headStyle={{backgroundColor: state.back5, color: Constant.textPrimaire, fontSize: 15}} 
                        bordered={false} 
                        onMouseEnter={(e)=> setState({...state, back5: Constant.couleurPrimaire})}
                        onMouseLeave={(e)=> setState({...state, back5: Constant.couleurSecondaire})}
                        style={{ marginRight: 10, marginTop: -55, marginBottom: 70}}
                        cover={
                          <div style={{textAlign: 'center'}}>
                            <img
                              alt="example"
                              src={sms}
                              // style={{height: 150, width: 150}}
                            />
                          </div>
                        }
                        actions={[
                          <div>
                            <Button onClick={(e) => {setOpen(true); setCurrent(0)}} variant={"outlined"} className={classes.purchaseBtn} style={{borderColor: state.back2, color: state.back2}}>👈🏾 Même processus que le paiement</Button>
                        </div>
                        ]}

                        >
                          <div style={{height: 180, marginTop: -50}}>
                                <p>Avec notre plateforme, vous pouvez automatiser l'envoi de vos SMS de masse de manière simple, rapide et à un prix avantageux. Enregistrez vos contacts directement, créez des groupes, etc...</p>
                                <strong>Profitez de notre API gratuite pour envoyer des SMS avec vos différentes plateformes</strong>
                          </div>
                      </Card>
                  </Grid>
                <Grid xs={12} sm={6} lg={3}>
                      <Card
                        title="Qr code professionnel"
                        hoverable
                        headStyle={{backgroundColor: state.back7, color: Constant.textPrimaire, fontSize: 15}} 
                        bordered={false} 
                        onMouseEnter={(e)=> setState({...state, back7: Constant.couleurPrimaire})}
                        onMouseLeave={(e)=> setState({...state, back7: Constant.couleurSecondaire})}
                        style={{ marginRight: 10, marginTop: -55, marginBottom: 70}}
                        cover={
                          <div style={{textAlign: 'center'}}>
                            <img
                              alt="example"
                              src={qrcode}
                              // style={{height: 150, width: 150}}
                            />
                          </div>
                        }
                        actions={[
                          <div>
                            <Button onClick={(e) => {setOpen(true); setCurrent(0)}} variant={"outlined"} className={classes.purchaseBtn} style={{borderColor: state.back2, color: state.back2}}>👈🏾 Même processus que le paiement</Button>
                        </div>
                        ]}

                        >
                          <div style={{height: 180, marginTop: -50}}>
                                <p>Un seul Qr code pour tous vos besoins: publicités, redirection sur vos plateformes (web et mobile), ect ...</p>
                                <p>
                                <img
                                    alt="qrcode 1"
                                    src={qr_code1}
                                    style={{height: 100, width: 100}}
                                  />
                                <img
                                    alt="qrcode 2"
                                    src={qr_code2}
                                    style={{height: 100, width: 100}}
                                  />
                                <img
                                    alt="qrcode 3"
                                    src={qr_code3}
                                    style={{height: 100, width: 100}}
                                  />
                                </p>
                          </div>
                      </Card>
                  </Grid>
                <Grid xs={12} sm={6} lg={3}>
                      <Card
                        title="Intégration des gps"
                        hoverable
                        headStyle={{backgroundColor: state.back6, color: Constant.textPrimaire, fontSize: 15}} 
                        bordered={false} 
                        onMouseEnter={(e)=> setState({...state, back6: Constant.couleurPrimaire})}
                        onMouseLeave={(e)=> setState({...state, back6: Constant.couleurSecondaire})}
                        style={{ marginRight: 10, marginTop: -55, marginBottom: 70}}
                        cover={
                          <div style={{textAlign: 'center'}}>
                            <img
                              alt="example"
                              src={gps}
                              // style={{height: 150, width: 150}}
                            />
                          </div>
                        }
                        actions={[
                          <div>
                            <Button onClick={(e) => {setOpen2(true); setState2({...state2, sujet: Constant.integrationGps})}}  variant={"outlined"} className={classes.purchaseBtn} style={{borderColor: state.back2, color: state.back2}}> Nous Contacter ?</Button>
                        </div>
                        ]}

                        >
                          <div style={{height: 180, marginTop: -50}}>
                                <p>Grâce à notre solution de traçage, vous pouvez localiser, contrôler et gérer votre flotte de véhicules.</p>
                                <p><Smartphone /> (Android, IOS)</p>
                                <p><Laptop /> Sites web,</p>
                          </div>
                      </Card>
                  </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                      <Card 
                        title={Constant.conceptionLogiciel} 
                        hoverable
                        headStyle={{backgroundColor: state.back2, color: Constant.textPrimaire, fontSize: 15}} 
                        bordered={false} 
                        onMouseEnter={(e)=> setState({...state, back2: Constant.couleurPrimaire})}
                        onMouseLeave={(e)=> setState({...state, back2: Constant.couleurSecondaire})}
                        style={{marginRight: 10, marginTop: -55, marginBottom: 70 }}
                        cover={
                          <div style={{textAlign: 'center'}}>
                            <img
                              alt="example"
                              src={prestation}
                              // style={{height: 150, width: 250}}
                            />
                          </div>
                        }
                        actions={[
                          <div>
                          <Button onClick={(e) => {setOpen2(true); setState2({...state2, sujet: Constant.conceptionLogiciel})}}  variant={"outlined"} className={classes.purchaseBtn} style={{borderColor: state.back2, color: state.back2}}> Nous Contacter ?</Button>
                        </div>
                        ]}>
                          <div style={{height: 180, marginTop: -50}}>
                                <p>Avec nos spécialistes dans ce domaine, nous créons pour vous des:</p>
                                <p><Smartphone /> Applications mobile (Android, IOS, Windows)</p>
                                <p><Laptop /> Sites web, logiciels et applications web</p>
                          </div>
                      </Card>
                  </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                      <Card 
                        title={Constant.formationProfessionnelles} 
                        hoverable
                        headStyle={{backgroundColor: state.back3, color: Constant.textPrimaire, fontSize: 15}} 
                        bordered={false} 
                        onMouseEnter={(e)=> setState({...state, back3: Constant.couleurPrimaire})}
                        onMouseLeave={(e)=> setState({...state, back3: Constant.couleurSecondaire})}
                        style={{marginRight: 10, marginTop: -55, marginBottom: 70 }}
                        cover={
                          <div style={{textAlign: 'center'}}>
                            <img
                              alt="example"
                              src={formations}
                              // style={{height: 150, width: 250}}
                            />
                          </div>
                        }
                        actions={[
                          <div>
                          <Button onClick={(e) => {setOpen2(true); setState2({...state2, sujet: Constant.formationProfessionnelles})}} variant={"outlined"} className={classes.purchaseBtn} style={{borderColor: state.back3, color: state.back3}}> Nous Contacter ?</Button>
                        </div>
                        ]}
                        >
                          <div style={{height: 180, marginTop: -50}}>
                              <p>80% de pratique et 20% de théorie, nos professionnelles sont là pour: </p>
                              <strong>1) Des formations périodiques</strong><br/>
                              <strong>2) Des formations en ligne</strong><br/>
                              <strong>3) Des formations individuelles</strong><br/>
                              <strong>4) Former vos entreprises</strong><br/>
                          </div>
                      </Card>
                  </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                      <Card 
                        title={Constant.venteEtPrestation}
                        hoverable
                        headStyle={{backgroundColor: state.back4, color: Constant.textPrimaire, fontSize: 15}} 
                        bordered={false} 
                        onMouseEnter={(e)=> setState({...state, back4: Constant.couleurPrimaire})}
                        onMouseLeave={(e)=> setState({...state, back4: Constant.couleurSecondaire})} 
                        style={{marginRight: 10, marginTop: -55, marginBottom: 70 }}
                        cover={
                          <div style={{textAlign: 'center'}}>
                            <img
                              alt="example"
                              src={ventes}
                              // style={{height: 130, width: 230}}
                            />
                          </div>
                        }
                        actions={[
                          <div>
                          <Button onClick={(e) => {setOpen2(true); setState2({...state2, sujet: Constant.venteEtPrestation})}} variant={"outlined"} className={classes.purchaseBtn} style={{borderColor: state.back4, color: state.back4}}> Nous Contacter ?</Button>
                        </div>
                        ]}>
                          <div style={{height: 180, marginTop: -50}}>
                              <p>Nous nous chargeons de l'entretien, de l'achat et en particulier de la vente des:</p>
                              <strong>1) Ordinateurs neufs et secondes mains</strong><br/>
                              <strong>2) Des téléphones et tablettes</strong><br/>
                              <strong>3) Des voitures</strong><br/>
                              <strong>4) Des parcelles de terre</strong><br/>
                          </div>
                      </Card>
                  </Grid>
                </Grid>
            </Grid>
        <Grid item sm={1}></Grid>
        <Grid item sm={12} style={{marginTop: -130, backgroundImage: `url(${footer})`, height: 250, width: window.screen.width, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <Grid container style={{marginTop: -60}}>
            <Grid item xs={12}  style={{textAlign: 'center', marginTop: 150, lineHeight: 1}}>
              <img src={logo} alt="logo"  style={{height: 50, width: 50, borderRadius: 50, boxShadow: "0px 0px 10px 0px"}}/> 
              <h1 style={{color: Constant.textPrimaire}}>SEVOB-SARL</h1>
              <p style={{color: Constant.textPrimaire}}>&copy; 2023-{new Date().getFullYear()}</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
{/* Pour l'integration */}
      <Modal
            open={open}
            onClose={(e)=>setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{overflow: 'scroll', zIndex: 1200}}
          >
            <Grid container spacing={3}>
                <Grid item lg={3}>
                </Grid>
                <Grid item lg={6}>
                    <Paper square  style={{padding: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginTop: 50}}>
                        <Steps current={current} items={items}/>
                          <div style={contentStyle}>
                            {/* Identification */}
                            {current === 0 &&
                              <Grid container spacing={3}>
                                <Grid item xl={12}>
                                  <h3>1) Cliquez sur le bouton "Identification"</h3>
                                  <p>Cet bouton se trouve en haut de la page d'accueil</p>
                                  <Image src={i1}  style={{maxHeight: 100}}/>
                                </Grid>
                                <Grid item xl={12}>
                                  <h3>2) Inscription</h3>
                                  <p>Vous devez créer un compte si vous n'en avez pas encore en cliquant sur "Inscription" et en remplissant les champs proposés</p>
                                  <Image src={i2} style={{maxHeight: 150}}/>
                                </Grid>
                                <Grid item xl={12}>
                                  <h3>3) Connexion</h3>
                                  <p>Une fois inscrit, vous pouvez accéder à votre espace en vous connectant avec vos identifiants</p>
                                  <Image src={i3} style={{maxHeight: 150}}/>
                                </Grid>
                              </Grid>
                            }

                            {current === 1 &&
                              <Grid container spacing={3}>
                                <Grid item xl={12}>
                                  <h3>1) Une fois connecté, cliquez sur "MesApis"</h3>
                                  <Image src={i4}  style={{maxHeight: 100}}/>
                                </Grid>
                                <Grid item xl={12}>
                                  <h3>2) Cliquez sur "Ajouter un API"</h3>
                                  <Image src={i5} style={{maxHeight: 150}}/>
                                </Grid>
                                <Grid item xl={12}>
                                  <h3>3) Validation</h3>
                                  <p>Chercher parmi les APIs disponible "Paiement en ligne" et cliquer sur "Valider" sur la ligne </p>
                                  <Image src={i6} style={{maxHeight: 150}}/>
                                </Grid>
                                <Grid item xl={12}>
                                  <h3>4) Intégration</h3>
                                  <p>L'API apparaitra sur votre liste après validation. Cliquez sur "Intégration" pour savoir comment l'intégrer à votre projet." </p>
                                  <Image src={i7} style={{maxHeight: 150}}/>
                                </Grid>
                              </Grid>
                            }
                          </div>

                          <div style={{ marginTop: 24 }}>
                            {current < steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => next()}>
                                Suivant
                              </ButtonAntd>
                            )}
                            {current === steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => setOpen(false)}>
                                Terminé
                              </ButtonAntd>
                            )}
                            {current > 0 && (
                              <ButtonAntd style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Précédent
                              </ButtonAntd>
                            )}
                            <ButtonAntd style={{ margin: '0 8px', float: 'right' }} onClick={() => setOpen(false)}>
                                Fermer
                              </ButtonAntd>
                          </div>
                    </Paper>
                </Grid>
                <Grid item lg={3}>
                </Grid>
            </Grid>
          </Modal>

{/* Pour nous contacter */}
          <Modal
            open={open2}
            onClose={(e)=>setOpen2(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{overflow: 'scroll', zIndex: 1200}}
          >
            <Grid container spacing={3}>
                <Grid item lg={3}>
                </Grid>
                <Grid item lg={6}>
                    <Paper square  style={{padding: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginTop: 50}}>
                        <div style={contentStyle}>
                          <h3>Veuillez nous écrire en remplissant les champs ci-dessous. Nous vous répondrons dans un délai de 24h.</h3>
                          <br/>
                          <Grid container spacing={3}>
                              <Grid item lg={2}></Grid>
                              <Grid item lg={8}>
                                  <Grid container spacing={3}>
                                    <Grid item xs={12}>  
                                      <strong>Votre mail</strong>
                                      <TextField error={error.mail} helperText={error.mail && Constant.champObligatoire} onChange={(e)=>setState2({...state2, mail: e.target.value})} size="small" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>  
                                      <strong>Sujet</strong>
                                      <TextField error={error.sujet} helperText={error.sujet && Constant.champObligatoire} value={state2.sujet} onChange={(e)=>setState2({...state2, sujet: e.target.value})} size="small" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>  
                                      <strong>Votre message</strong>
                                      <TextField error={error.message} helperText={error.message && Constant.champObligatoire} multiline onChange={(e)=>setState2({...state2, message: e.target.value})} size="small" variant="outlined" fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>  
                                        <div style={{ marginTop: 24 }}>
                                              <ButtonAntd type="primary" onClick={() => envoyer()} disabled={spinner}>
                                                Envoyer {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                                              </ButtonAntd>
                                              <ButtonAntd style={{ margin: '0 8px', float: 'right' }} onClick={() => setOpen2(false)}>
                                                Fermer
                                              </ButtonAntd>
                                        </div>
                                    </Grid>
                                  </Grid>
                              </Grid>
                              <Grid item lg={2}></Grid>
                          </Grid>
                        </div>
                        
                    </Paper>
                </Grid>
                <Grid item lg={3}>
                </Grid>
            </Grid>
          </Modal>

    </div>
  );
}

export default withRouter(Login);
