import { Drawer, IconButton, List } from "@material-ui/core";
import {
  AccountBalanceWallet,
  ArrowBack as ArrowBackIcon,
  BarChart,
  CheckCircle,
  GpsFixed,
  GridOn,
  Group,
  LibraryBooks as LibraryIcon,
  Money,
  PieChart,
  PinDrop,
  ShowChart,
  Sms
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";

const structure = [
  { id: 0, type: "title", label: "Administation" },
  { id: 1, label: "Utilisateurs", link: "/app/gestionUtilisateur/show", icon: <Group /> },
  { id: 2, label: "APIs", link: "/app/gestionApis", icon: <LibraryIcon /> },
  { id: 7, label: "GPS", link: "/app/GestionGPS", icon: <GpsFixed /> },
  // { id: 3, label: "Publicités", link: "https://flatlogic.com/forum", icon: <DesktopWindows /> },
  { id: 3, label: "Gestions de Retrait", link: "/app/demandeRetrait", icon: <CheckCircle /> },
  { id: 4, label: "Statistiques", link: "/app/Statistique/show", icon: <ShowChart /> },
  { id: 5, type: "divider" },
  { id: 10, type: "title", label: "Portefeuille" },
  { id: 11, label: "Mon compte", link: "/app/Solde/show", icon: <AccountBalanceWallet /> },
  { id: 14, type: "divider" },
  { id: 6, type: "title", label: "Gestions" },
  // { id: 7, label: "Tableau de board", link: "https://flatlogic.com/templates", icon: <ShowChart /> },
  { id: 8, label: "Mes APIs", link: "/app/mesApis/show", icon: <LibraryIcon /> },
  {
    id: 17,
    label: "Paiement web",
    link: "/app/ui",
    icon: <Money />,
    children: [
      { id: 13, label: "Transactions", link: "/app/mesTransactions/show", icon: <PieChart /> },
      { id: 16, label: "Tests", link: "/app/mesTransactionsTest/show", icon: <BarChart /> },
    ],
  },
  { id: 18, label: "SMS", link: "/app/GestionSMS/show", icon: <Sms /> },
  { id: 19, label: "Qr Code", link: "/app/GestionQrCode/show", icon: <GridOn /> },
  { id: 20, label: "GPS", link: "/app/MesGPS/show", icon: <PinDrop /> },
  { id: 9, type: "divider" },
  // { id: 15, type: "title", label: "Mode Tests" },
  // { id: 16, label: "Solde", link: "https://flatlogic.com/forum", icon: <AccountBalance /> },
  // { id: 12, type: "divider" },

  // { id: 33, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  // {
  //   id: 19,
  //   label: "Typography",
  //   link: "/app/typography",
  //   icon: <TypographyIcon />,
  // },
  // { id: 20, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 21,
  //   label: "Notifications",
  //   link: "/app/notifications",
  //   icon: <NotificationsIcon />,
  // },
  // {
  //   id: 22,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  // { id: 23, type: "divider" },
  // { id: 24, type: "title", label: "HELP" },
  // { id: 25, label: "Library", link: "https://flatlogic.com/templates", icon: <LibraryIcon /> },
  // { id: 26, label: "Support", link: "https://flatlogic.com/forum", icon: <SupportIcon /> },
  // { id: 27, label: "FAQ", link: "https://flatlogic.com/forum", icon: <FAQIcon /> },
  // { id: 28, type: "divider" },
  // { id: 29, type: "title", label: "PROJECTS" },
  // {
  //   id: 30,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="small" color="warning" />,
  // },
  // {
  //   id: 31,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="small" color="primary" />,
  // },
  // {
  //   id: 32,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="small" color="secondary" />,
  // },
];
let user = JSON.parse(localStorage.getItem('user'));
function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const value = (link) =>{
    return (<SidebarLink
    key={link.id}
    location={location}
    isSidebarOpened={isSidebarOpened}
    {...link}
  />)
  }
  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => {
          if((link.id === 0 || link.id === 1 || link.id === 2 || link.id === 3 || link.id === 4 || link.id === 5 || link.id === 7)) {
              if(user !== null && (user.roles.some(e => e.name === "ROLE_EMPLOYE" || e.name === "ROLE_SEVOB_SUPER_ADMIN"))) {
                return value(link)
              } else {
                return null
              }
          } else {
            return value(link)
          }
        })}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
