import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  LinearProgress,
  Modal,
  Paper,
  TextField
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

// styles
import useStyles from "./styles";

import PageTitle from "../../components/PageTitle/PageTitle";
import { Constant } from "../../constant";
import BigStat from "./components/BigStat/BigStat";


import { DatePicker, Divider, Flex, Popover, Select, Tag, Tooltip, Typography, theme } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { AccountBalanceWallet, Check, CloudUpload, Delete, DeleteOutline, Edit, Save } from "@material-ui/icons";
import frFR from 'antd/es/date-picker/locale/fr_FR';
import axios from "axios";
import moment from 'moment';
import 'moment/locale/fr';
import MUIDataTable from "mui-datatables";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import Service from "../../service";
import { AsRoles } from "../../verifyRoles";
const { RangePicker } = DatePicker;

let user = JSON.parse(localStorage.getItem('user'));
let dateConst = {}
export default function GestionSMS(props) {
  var classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const [userTrouver, setUserTrouver] = useState({});
  const [groupeSelect, setGroupeSelect] = useState({});
  const [reponse2, setReponse2] = useState([]);
  const [smsSenderUtilisateurValider, setSmsSenderUtilisateurValider] = useState([]);
  const [reponse, setReponse] = useState([]);
  const [compte, setCompte] = useState([]);
  const [clesClient, setClesClient] = useState([]);
  const [groupe, setGroupe] = useState([]);
  const [compteDate, setCompteDate] = useState([]);
  var [open, setOpen] = useState(false);
  var [titre, setTitre] = useState("");

  // Pour unput tag debut
const tagInputStyle = {
  width: "50%",
  height: 30,
  marginInlineEnd: 8,
  verticalAlign: 'top',
};
  const { token } = theme.useToken();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [anotherOptions, setAnotherOptions] = useState([]);
  const [value, setValue] = useState('');
  const navigate = useHistory();
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  // Pour unput tag fin

  const [state, setState] = React.useState({
    cleutilisateur: props.match.params.data.split("2474&")[0],
    choix: "",
    visible:false,
    nom_groupe: "",
    nom_a_afficher: "",
    message: "",
    emailDetinateur: "",
    idreceveur: 0
  });
  const [error, setError] = React.useState({
    numeros: false,
    numeros2: false,
    nom_a_afficher: false,
    message: false,
    emailDetinateur: false,
  });

  const columns2 = [
    {
      name: "smsSender.name",
      label: "Expéditeur",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "smsSender.statut",
      label: "Etat",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let e = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
              <Button onClick={(el)=> {if(window.confirm('Êtes-vous sûr de faire cette suppression ?'))supprimerSender(e.id)}} size="small" variant="contained" startIcon={<DeleteOutline />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                Supprimer
              </Button>
            </div>;
        }
      }
    }
  ];

  const columns = [
    {
      name: "nomAAfficher",
      label: "Expéditeur",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "numeros",
      label: "Numéros",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta, updateValue) => {
					return (
						<Typography component={'span'} noWrap={true}>
							{value}
						</Typography>
					)
				}
      }
    },
    {
      name: "message",
      label: "Message",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "dateinscription",
      label: "Date d'envoi",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "heure",
      label: "Heure d'envoi",
      options: {
        filter: true,
        sort: true,
      }
    },
  ];

  const priceSMS = [
    {
      quantite: 100,
      prix: 30,
    },
    {
      quantite: 500,
      prix: 28,
    },
    {
      quantite: 2000,
      prix: 25,
    },
    {
      quantite: 5000,
      prix: 22,
    },
    {
      quantite: 10000,
      prix: 20,
    },
    {
      quantite: 25000,
      prix: 18,
    },
    {
      quantite: 50000,
      prix: 15,
    },
    {
      quantite: 100000,
      prix: 13,
    },
  ]

  React.useEffect(() => {
    (async ()=> {
      getApisDuClient()
      if(props.match.params.data.split("2474&")[1] !== undefined){
        if(AsRoles([Constant.ROLE_SEVOB_SUPER_ADMIN, Constant.ROLE_STATISTIQUES_SEVOB]) === Constant.visible){
          await init();
        } else {
          props.history.push("/app/Profil")
        }
      } else {
        init()
      }
    })();
    
  }, []);


  const init = async () => {
    if(props.match.params.data.split("2474&")[0] !== "show") {
      getSolde()
      getGroupe()
      getSender()
    }
}

  const getApisDuClient = () => {
    Service.getApisDuClient(user.id, "sms").then(
      async (response) => {
        setClesClient(response.data.data);
        if(response.data.data.length === 0) {
          toast.error(Constant.pasApiActiver + " les SMS", {position: "top-center", autoClose: 10000,})
        }
      })
  }

  const getSolde = () => {
    Service.getSoldeSMS(props.match.params.data.split("2474&")[0]).then(
      async (response) => {
        let data = [
          {
            product: "SMS disponibles",
            total: response.data.smsdisponible
          },
          {
            product: "SMS envoyés",
            total: response.data.smsenvoye
          },
          {
            product: "Totale",
            total: response.data.Totale
          },
        ]
        setCompte(data);
      })
  }

  const periode = async (value) => {
    // await setEndDate(new Date());
    const date = new Date();
    if(value === "Journalière") {
    } else if(value === "Hebdomendaire") {
      await date.setDate(date.getDate() - 7);
    } else if(value === "Mensuel") {
      await date.setDate(date.getDate() - 30);
    } else if(value === "Trimestriel") {
      await date.setDate(date.getDate() - 90);
    } else if(value === "Annuelle") {
      await date.setDate(date.getDate() - 365);
    }
    historique(date, new Date())
  }


  const historique = async (date, endDate) => {
    setSpinner(true);
    let data = {
      cle: props.match.params.data.split("2474&")[0],
      debut: date.getTime(),
      fin: endDate.getTime(),
    }
    dateConst = {
      cle: props.match.params.data.split("2474&")[0],
      debut: date.getTime(),
      fin: endDate.getTime()
    };
    Service.EnvoiFaitParDate(data).then(
      async (response) => {
        setSpinner(false);
        setReponse(response.data);
      }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      }
    )
}


function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}
const onChange = (dates, dateStrings) => {
  if(dateStrings[0] !== '' && dateStrings[1] !== ''){
    historique(new Date(dateStrings[0]), new Date(dateStrings[1]))
  }
  // this.setState({debut: dateStrings[0].split("-").reverse().join("-"), fin: dateStrings[1].split("-").reverse().join("-")})
}

const OpenModal = async (titre) => {
  setOpen(true)
  setTitre(titre)
}

// Pour input tag debut 

useEffect(() => {
  if (inputVisible) {
    inputRef.current?.focus();
  }
}, [inputVisible]);
useEffect(() => {
  editInputRef.current?.focus();
}, [editInputValue]);
const handleClose = (removedTag) => {
  const newTags = tags.filter((tag) => tag !== removedTag);
  setTags(newTags);
};
const showInput = () => {
  setInputVisible(true);
};
const handleInputChange = (e) => {
  setInputValue(e);
};
const handleInputConfirm = () => {
  if (inputValue && !tags.includes(inputValue)) {
    setTags([...tags, inputValue]);
  }
  setInputVisible(false);
  setInputValue('');
};
const handleEditInputChange = (e) => {
  setEditInputValue(e);
};
const handleEditInputConfirm = () => {
  const newTags = [...tags];
  newTags[editInputIndex] = editInputValue;
  setTags(newTags);
  setEditInputIndex(-1);
  setEditInputValue('');
};
const tagPlusStyle = {
  height: 30,
  background: 'green',
  color: 'white',
  borderStyle: 'dashed',
};

// Pour input tag fin

// Pour select tag debut
  const handleChange = (value) => {
    if(value === undefined){
      nouveauGroup()
    } else {
      let gr = groupe.filter(m=>m.id === value)[0];
      setState({
        ...state,
        visible: true,
        nom_groupe: gr.nom
      })
      setTags(gr.numeros.split(","));
      setGroupeSelect(gr)
    }
  };

  const handleChange2 = (value) => {
    setState({
        ...state,
        nom_a_afficher: value,
      })
  };

  // Pour select tag fin
  


const nomAafficherInfo = (
  <div>
    <p>Vous pouvez demander l'ajout de vos <strong>noms d'expéditeurs</strong> en cliquant sur</p>
    <Button
      variant="contained"
      size="medium"
      style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginLeft: 20, marginBottom: 10}}
      onClick={(e)=>{OpenModal(Constant.mesExpediteur)}}
    >
        {Constant.mesExpediteur}
    </Button>
  </div>
);

const numeroInfo = (
  <div>
    <p>Veuillez fournir les numéros dans leur format standard avec <strong>l'indicatif du pays</strong>.</p>
  </div>
);
const expediteurInfo = (
  <div>
    <p>Le nom de l'expéditeur ne doit pas débuter par <strong>un chiffre</strong>.</p>
    <p>Le nom de l'expéditeur ne doit pas dépasser <strong>11 caractères au maximum</strong>.</p>
  </div>
);
const messageInfo = (
  <div>
    <p>Le contenu de votre message ne doit pas dépasser <strong>155 caractères au maximum</strong>.</p>
  </div>
);
const boutonExcelInfo = (
  <div>
    <p>Veillez à ce que la colonne <strong>numéro</strong> contenant vos numéros soit présente dans le <strong>fichier Excel sur la prémière ligne</strong>.</p>
  </div>
);


const nouveauGroup = () => {
  setTags([]);
  setState({...state, visible: true, nom_groupe: ""})
  setGroupeSelect({})
};

const conversionNumero = (tags) => {
  let result = tags.toString().split("+").join("").split(",00").join(",").split(" ").join("").split(".").join("").split("e").join("").split("E").join("");
  return result.startsWith("00") ? result.slice(2) : result;
}

const EnregistrerSender = () => {
    setSpinner(true);
    let data = {
      idUtilisateur: user.id,
      name: value.trim()
    }
    Service.addSender(data).then(
      async (response) => {
        getSender()
        setSpinner(false);
        if(response.data.includes("refusé")) {
          toast.error(response.data, {position: "top-center", autoClose: 10000,})
        } else {
          toast.success(response.data, {position: "top-center", autoClose: 10000,})
        }
      }, error => {
        toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
        setSpinner(false);
      }
    )
  }
const Enregistrer = () => {
  if(state.nom_groupe.trim() === ""){
    setError({...error, nom_groupe: true})
  } else if(tags.length <= 0){
    setError({...error, numeros: true, nom_groupe: false})
  } else {
    setSpinner(true);
    setError({...error, numeros: false, nom_groupe: false})
    let data = {
      cleutilisateur: state.cleutilisateur,
      nom: state.nom_groupe.trim(),
      numeros: conversionNumero(tags),
      type: "SMS",
      id: groupeSelect.id !== undefined ? groupeSelect.id : 0
    }
    Service.addGroupe(data).then(
      async (response) => {
        setGroupe([])
        setSpinner(false);
        toast.success(response.data, {position: "top-center", autoClose: 10000,})
        nouveauGroup()
        init()
      }, error => {
        toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
        setSpinner(false);
      }
    )
  }
};


const Envoyer = async () => {
  if(state.nom_a_afficher.trim() === ""){
    setError({...error, nom_a_afficher: true})
  } else if(tags.length <= 0){
    setError({...error, numeros2: true, nom_a_afficher: false})
  } else if(state.message.trim() === ""){
    setError({...error, message: true, numeros2: false, nom_a_afficher: false})
  } else {
    setError({...error, message: false, numeros2: false, nom_a_afficher: false})
    if(window.confirm('Confirmez-vous l\'envoi des SMS ?')){
      let data = {
        nom_a_afficher: state.nom_a_afficher.trim(),
        numeros: conversionNumero(tags),
        message: state.message.trim(),
        cle: props.match.params.data.split("2474&")[0],
      }
      setSpinner(true)
              Service.envoisms(data).then(
                async (response) => {
                  setSpinner(false);
                  if(response !== undefined && response.data.code === "200") {
                    toast.success(response.data.message, {position: "top-center", autoClose: 10000,})
                    getSolde()
                  } else {
                    toast.error(response.data.message, {position: "top-center", autoClose: 10000,})
                  }
                }, error => {
                  toast.error("Erreur du serveur. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
                  setSpinner(false);
                }
              )
      }
  }

}

const getSender = () => {
  Service.getSender(user.id).then(
    async (response) => {
      setReponse2(response.data);
      setSmsSenderUtilisateurValider(response.data.filter((a)=> a.smsSender.statut === Constant.Valider));
    })
}

const getGroupe = () => {
  Service.getGroupe(props.match.params.data.split("2474&")[0]).then(
    async (response) => {
      setGroupe(response.data);
    })
}


const supprimer = async (idGroupe) => {
  setSpinner(true)
  Service.supprimerGroupe(idGroupe).then(
    async (response) => {
      setGroupe([])
      setSpinner(false)
      toast.success("Suppression effectuée avec succès", {position: "top-center", autoClose: 10000,})
      nouveauGroup()
      init()
    }, error => {
      setSpinner(false);
      toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
  })
}

const hiddenFileInput = useRef(null);
  
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClickFILE = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChangeFILE = event => {
    setSpinner(true)
    const f = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
        let numeroPlace = dataParse[0].indexOf("numéro")
        let tagList = [];
        for (let index = 1; index < dataParse.length; index++) {
          tagList.push(dataParse[index][numeroPlace]);

          if((index+1) === dataParse.length){
            setSpinner(false)
            setTags(tagList);
            setState({...state, visible: true})
          }
        }
    };
    reader.readAsBinaryString(f)
  };


const PayerMesSMS = (price) => {
  setSpinner(true);
  let data = {
    quantite: price.quantite,
    prix: price.prix,
    cle: props.match.params.data.split("2474&")[0]
  }
  Service.addSmsPayment(data).then(
    async (response) => {
      let body = {
      'mode': 'REEL',
      'cle': Constant.CleDeSevob,
      'nom_entreprise': 'SEVOB-SMS',
      'lien_logo_entreprise': Constant.lienLogo,
      'montant': response.data.montant,
      'commande_id': new Date().getTime()+"s",
      'autre_information': '',
      'return_url': window.location.href,
      'cancel_url': 'https://vps82722.serveur-vps.net:2474/sevob/anonyme/validerAchatSms/'+response.data.id,
      'success_url': 'https://vps82722.serveur-vps.net:2474/sevob/anonyme/validerAchatSms/'+response.data.id,
      'error_url': 'https://vps82722.serveur-vps.net:2474/sevob/anonyme/validerAchatSms/'+response.data.id,
      }
      axios.post('https://api.sevob.net', body).then(
        (reponse)=> {
          if(reponse.data.code === "200") {
            window.open(reponse.data.url, "_self")
          } else {
            toast.error(reponse.data.message, {position: "top-center", autoClose: 10000,})
          }
        },
        error => {
          toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
        }
      );
      setSpinner(false)
    }, error => {
      setSpinner(false)
      toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
  })
  
}

const supprimerSender = async (idSmsSenderUtilisateur) => {
  setSpinner(true)
  Service.supprimerSender(idSmsSenderUtilisateur).then(
    async (response) => {
      getSender();
      setSpinner(false)
      toast.success("Suppression effectuée avec succès", {position: "top-center", autoClose: 10000,})
    }, error => {
      setSpinner(false);
      toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
  })
}


const navigation = (path, data) => {
  navigate.push({
    pathname: `${path}/${data}`
  });
  window.location.reload()
}
  return (
    <>
        <div style={{textAlign: props.match.params.data.split("2474&")[0] !== "show" ? "left" : 'center'}}>
            <Select
                showSearch
                allowClear
                defaultValue={props.match.params.data.split("2474&")[0] !== "show" ? props.match.params.data.split("2474&")[0] : null}
                style={{ width: 250, textAlign: 'center'}}
                dropdownStyle={{zIndex: 1300}}
                placeholder={<strong style={{color: 'red'}}>Sélectionnez une clé ici</strong>}
                onChange={(e)=>e !== undefined && navigation("/app/GestionSMS", `${e}${(props.match.params.data.split("2474&")[1] !== undefined) ? ('2474&' + props.match.params.data.split("2474&")[1]) : ""}`)}
                options={
                  clesClient.length > 0 && clesClient.map((element) => {
                    return { "value": `${element.id}${element.cle}`, "label": `CLE: ${element.id}${element.cle}` }
                  })
                }
              />
          </div>
      {
        props.match.params.data.split("2474&")[0] !== "show"
        &&
          <>
                <PageTitle title={(props.match.params.data.split("2474&")[1] !== undefined) ? ("Gestion SMS de " + props.match.params.data.split("2474&")[1]) : "Gestion SMS"} button={
                  <div>
                    <Button
                      variant="contained"
                      size="medium"
                      style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginLeft: 20, marginBottom: 10}}
                      onClick={(e)=>{OpenModal(Constant.mesExpediteur)}}
                    >
                        {Constant.mesExpediteur}
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginLeft: 20, marginBottom: 10}}
                      onClick={(e)=>{OpenModal(Constant.clientGestion)}}
                    >
                        {Constant.clientGestion}
                    </Button>
                  </div>
                } />
                <Grid container spacing={4}>
                <Grid item md={3} sm={6} xs={12} style={{backgroundColor: 'orange', textAlign: 'center'}}>
                  <h2 style={{color: 'white'}}><AccountBalanceWallet/> A partir de 13 {Constant.devis}/SMS</h2>
                  <Button
                      variant="contained"
                      size="medium"
                      style={{backgroundColor: "green", color: Constant.textPrimaire, marginBottom: 10}}
                      onClick={(e)=>{OpenModal(Constant.acheterSms)}}
                    >
                        {Constant.acheterSms}
                    </Button>
                </Grid>
                  {compte.length>0 && compte.map(stat => (
                    <Grid item md={3} sm={6} xs={12} key={stat.product} style={{backgroundColor: Constant.couleurPrimaire, borderBottomColor: 'rgba(255,0,0,0.3)', borderBottomWidth: 5, borderBottomStyle: 'solid'}}>
                      <BigStat {...stat} />
                    </Grid>
                  ))}


                  <Grid item lg={4} style={{padding: 50}}>
                      <Grid container spacing={3} style={{backgroundColor: 'white', padding: 20, borderRadius: 20, boxShadow: "1px 1px 10px 0px red"}}>
                          <Grid item xs={12}>
                            <h1 style={{textAlign: 'center'}}>Nouveau Message</h1>
                          </Grid>
                          <Grid item xs={12}>
                              <Popover color="#F08080" placement="left" content={nomAafficherInfo} title="Information">
                                  <Select
                                      showSearch
                                      allowClear
                                      style={{ width: '100%' }}
                                      placeholder="Nom de l'expéditeur"
                                      onChange={handleChange2}
                                      options={
                                        smsSenderUtilisateurValider.length > 0 ? smsSenderUtilisateurValider.map((element) => {
                                          return { "value": element.smsSender.name, "label": element.smsSender.name }
                                        })
                                        :
                                        [{ "value": Constant.SevobTest, "label": Constant.SevobTest }]
                                      }
                                    />
                                    {error.nom_a_afficher && <small style={{color: 'red'}}>{Constant.champObligatoire}</small>}
                              </Popover>
                          </Grid>
                          <Grid item xs={12}>
                              <Select
                                  showSearch
                                  allowClear
                                  style={{ width: '100%' }}
                                  placeholder="(Optionnel) Sélectionnez un groupe"
                                  onChange={handleChange}
                                  options={
                                    groupe.length > 0 && groupe.map((element) => {
                                      return { "value": element.id, "label": element.nom }
                                    })
                                  }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Flex gap="4px 0" wrap="wrap">
                                    {tags.map((tag, index) => {
                                      if (editInputIndex === index) {
                                        return (
                                          <Grid key={index + "a1"} container spacing={4} style={{marginLeft: 0}}>
                                            <Grid item xs={8}>
                                              <PhoneInput
                                                  value={editInputValue}
                                                  onChange={handleEditInputChange}
                                                  country={"ml"}
                                                  placeholder="Numéro"
                                                  inputProps={{
                                                      name: 'telephone',
                                                      required: true,
                                                      autoFocus: true,
                                                    }}
                                                    inputStyle={{width: "100%"}}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              <Button color="primary" onClick={(e)=>handleEditInputConfirm()}>Modifier</Button>
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                      const isLongTag = tag.length > 20;
                                      const tagElem = (
                                        <Tag
                                          key={tag}
                                          closable={true}
                                          style={{ userSelect: 'none' }}
                                          onClose={() => handleClose(tag)}
                                          color="red"
                                        >
                                          <span
                                            onClick={(e) => {
                                                setEditInputIndex(index);
                                                setEditInputValue(tag);
                                                e.preventDefault();
                                            }}
                                          >
                                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                          </span>
                                        </Tag>
                                      );
                                      return isLongTag ? (
                                        <Tooltip title={tag} key={tag}>
                                          {tagElem}
                                        </Tooltip>
                                      ) : (
                                        tagElem
                                      );
                                    })}
                                  </Flex>
                                  <br/>
                                    {inputVisible ? (
                                      <Grid container spacing={4} style={{marginLeft: 0}}>
                                        <Grid item xs={8}>
                                          <PhoneInput
                                              value={inputValue}
                                              onChange={handleInputChange}
                                              country={"ml"}
                                              placeholder="Numéro"
                                              inputProps={{
                                                  name: 'telephone',
                                                  required: true,
                                                  autoFocus: true,
                                                }}
                                                inputStyle={{width: "100%"}}
                                          />
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Button color="primary" onClick={(e)=>handleInputConfirm()}>Ajouter</Button>
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                                        Ajouter un numéro
                                      </Tag>
                                    )}
                            </Grid>
                            
                          {error.numeros2 && <small>{Constant.insererNumeros}</small>}
                          <Grid item xs={12}>
                              <Popover color="#F08080" placement="left" content={messageInfo} title="Information">
                                <TextField type="text" error={error.message} helperText={error.message && Constant.champObligatoire} onChange={(e)=>setState({...state, message: e.target.value})} size="small" label={"Message"} variant="outlined" fullWidth
                                    multiline
                                    minRroows={2}
                                    inputProps={{
                                      maxLength: 155,
                                    }}
                                  />
                              </Popover>
                          </Grid>

                          <Grid item xs={12}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>{Envoyer()}} style={{marginRight: 10}} disabled={spinner}>
                                Envoyer {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                Fermer
                              </Button>
                          </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={8}>
                      <Grid container spacing={4} style={{marginTop: 20, marginLeft: 0}}>
                        <Grid item sm={6}>
                          <strong>Par Période</strong>
                          <br/>
                          <Select
                            style={{ width: '100%' }}
                            onChange={(e)=>periode(e)}
                            dropdownStyle={{zIndex: 1300}}
                            options={[
                              { value: 'Journalière', label: 'Journalière' },
                              { value: 'Hebdomendaire', label: 'Hebdomendaire' },
                              { value: 'Mensuel', label: 'Mensuel' },
                              { value: 'Trimestriel', label: 'Trimestriel' },
                              { value: 'Annuelle', label: 'Annuelle' },
                            ]}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <strong>Par intervalle de Date</strong>
                          <br/>
                          <RangePicker 
                            disabledDate={disabledDate}
                            onChange={onChange}
                            // format="DD-MM-YYYY"
                            style={{width: "100%"}}
                            locale={frFR}
                            ranges={{
                              'Aujourd\'hui': [moment(), moment()],
                              'Ce mois': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            placeholder={["Du", "au"]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {spinner && <LinearProgress color="secondary" />}
                          <MUIDataTable
                            title={(props.match.params.data.split("2474&")[1] !== undefined) ? ("Liste d'envoi des SMS de " + props.match.params.data.split("2474&")[1]) : "Liste d'envoi de vos SMS"}
                            data={reponse}
                            columns={columns}
                            options={{
                              filter: true,
                              draggableColumns: { enabled: true },
                              enableNestedDataAccess: '.',
                              filterType: "multiselect",
                              serverSide: true,
                              jumpToPage: true,
                              filterType: "checkbox",
                              textLabels: {
                                body: {
                                  noMatch: 'Rien à afficher pour le moment',
                                }
                              },
                              selectableRows: "none"
                            }}
                            />
                        </Grid>
                      </Grid>
                  </Grid>
                </Grid>
          </>
        }

      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1200, overflow: 'scroll'}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item lg={2}>
              </Grid>
              <Grid item lg={8}>
                <Paper square  style={{marginTop: 50, padding: 50, paddingTop: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50}}>
                       {titre}
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                          {spinner && <LinearProgress color="secondary" />}
                        </Grid>
                        <Grid item lg={1}>
                        </Grid>
                        {Constant.clientGestion === titre &&
                            <Grid item lg={10}>

                                <Grid container spacing={3} style={{backgroundColor: "rgba(0, 255, 0, 0.1)", marginBottom: 20}}>
                                  <Grid item xs={4}>
                                      <Select
                                          showSearch
                                          allowClear
                                          style={{ width: '100%' }}
                                          dropdownStyle={{zIndex: 1300}}
                                          placeholder="Sélectionnez un groupe"
                                          onChange={handleChange}
                                          options={
                                            groupe.length > 0 && groupe.map((element) => {
                                              return { "value": element.id, "label": element.nom }
                                            })
                                          }
                                        />
                                    </Grid>
                                    <Grid item xs={3} style={{textAlign: 'center'}}>
                                        <Button
                                          variant="contained"
                                          size="medium"
                                          style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire}}
                                          onClick={(e)=>{nouveauGroup()}}
                                        >
                                            Nouveau groupe
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Popover color="#F08080" zIndex={1300} placement="top" content={boutonExcelInfo} title="Information">
                                            <Button
                                              variant="contained"
                                              size="medium"
                                              style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire}}
                                              onClick={handleClickFILE}
                                            >
                                              <CloudUpload/> &nbsp;  Importer un Excel
                                            </Button>
                                            <input
                                              type="file"
                                              onChange={handleChangeFILE}
                                              accept=".xlsx, .xls, .csv"
                                              ref={hiddenFileInput}
                                              style={{display: 'none'}} // Make the file input element invisible
                                            />
                                        </Popover>
                                    </Grid>
                                </Grid>
                                {state.visible &&
                                  <>
                                      <Divider orientation="right" orientationMargin={0} style={{borderLeft: '1px solid red', marginTop: 50, marginBottom: 50}}>
                                          <Button variant="outlined" color="secondary" onClick={(e)=>{Enregistrer()}} style={{marginRight: 10}} disabled={spinner}>
                                              <Save/> Enregistrer
                                          </Button>
                                      </Divider>
                                      <Grid container spacing={3}>
                                            {groupeSelect.id !== undefined &&
                                              <Grid item xs={1}>
                                                <Delete style={{color:'red', cursor: 'pointer'}} onClick={(e)=> {if(window.confirm('Êtes-vous sûr de supprimer ce groupe ?\nLes numéros qui sont associés seront également supprimés.'))supprimer(groupeSelect.id)}}/>
                                              </Grid>
                                            }
                                            <Grid item xs={5}>
                                                  <TextField type="text" error={error.nom_groupe} helperText={error.nom_groupe && Constant.champObligatoire} onChange={(e)=>setState({...state, nom_groupe: e.target.value})} size="small" label={"Nom du groupe"} variant="outlined" fullWidth
                                                      value={state.nom_groupe}
                                                      InputProps={{
                                                        startAdornment: (
                                                          <InputAdornment position="start">
                                                            <Edit />
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                            </Grid>
                                            <Grid item xs={6}>
                                                {inputVisible ? (
                                                    <Grid container spacing={4} style={{marginLeft: 0}}>
                                                    <Grid item xs={8}>
                                                      <PhoneInput
                                                          value={inputValue}
                                                          onChange={handleInputChange}
                                                          country={"ml"}
                                                          placeholder="Numéro"
                                                          inputProps={{
                                                              name: 'telephone',
                                                              required: true,
                                                              autoFocus: true,
                                                            }}
                                                            inputStyle={{width: "100%"}}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                      <Button color="primary" onClick={(e)=>handleInputConfirm()}>Ajouter</Button>
                                                    </Grid>
                                                  </Grid>
                                                  ) : (
                                                    <>
                                                    <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
                                                      Ajouter un numéro
                                                    </Tag>
                                                      <br/>
                                                      {error.numeros && <small style={{color: 'red'}}>{Constant.insererNumeros}</small>}
                                                    </>
                                                  )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Flex gap="4px 0" wrap="wrap">
                                                    {tags.map((tag, index) => {
                                                      if (editInputIndex === index) {
                                                        return (
                                                          <Grid key={index + "a2"} container spacing={4} style={{marginLeft: 0}}>
                                                            <Grid item xs={8}>
                                                              <PhoneInput
                                                                  value={editInputValue}
                                                                  onChange={handleEditInputChange}
                                                                  country={"ml"}
                                                                  placeholder="Numéro"
                                                                  inputProps={{
                                                                      name: 'telephone',
                                                                      required: true,
                                                                      autoFocus: true,
                                                                    }}
                                                                    inputStyle={{width: "100%"}}
                                                              />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                              <Button color="primary" onClick={(e)=>handleEditInputConfirm()}>Modifier</Button>
                                                            </Grid>
                                                          </Grid>
                                                        );
                                                      }
                                                      const isLongTag = tag.length > 20;
                                                      const tagElem = (
                                                        <Tag
                                                          key={tag}
                                                          closable={true}
                                                          style={{ userSelect: 'none' }}
                                                          onClose={() => handleClose(tag)}
                                                          color="red"
                                                        >
                                                          <span
                                                            onClick={(e) => {
                                                                setEditInputIndex(index);
                                                                setEditInputValue(tag);
                                                                e.preventDefault();
                                                            }}
                                                          >
                                                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                                          </span>
                                                        </Tag>
                                                      );
                                                      return isLongTag ? (
                                                        <Tooltip title={tag} key={tag}>
                                                          {tagElem}
                                                        </Tooltip>
                                                      ) : (
                                                        tagElem
                                                      );
                                                    })}
                                                  </Flex>
                                            </Grid>
                                      </Grid>
                                  </>
                                }

                            </Grid>
                        }
                        {Constant.acheterSms === titre &&
                            <Grid item lg={10}>
                              <Grid container spacing={3} style={{textAlign: 'center'}}>
                                {priceSMS.map((price, index)=>{
                                    return <Grid key={index + "sms"} item sm={3}>
                                          <div style={{borderBottomColor: 'orange', borderBottomWidth: 2, borderBottomStyle: 'solid', paddingLeft: 5, paddingRight: 5}}>
                                            <div style={{backgroundColor: Constant.couleurPrimaire, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                              <p style={{color: 'white', fontWeight: 'bold', fontSize: 20, padding: 5}}>{price.quantite.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} SMS</p>
                                              <div style={{backgroundColor: "orange", marginTop: -20, borderTopLeftRadius: 20, borderTopRightRadius: 20}}>
                                                <strong style={{color: 'white', fontWeight: 'bold', fontSize: 30}}>{(price.prix * price.quantite).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</strong>
                                                <br/>
                                                <strong style={{color: 'white', fontWeight: 'bold'}}>{Constant.devis}</strong>
                                                <hr style={{marginLeft: 20, marginRight: 20}}/>
                                                <strong>{price.prix} {Constant.devis} par SMS</strong>
                                                <hr style={{marginLeft: 20, marginRight: 20}}/>
                                                <div>
                                                  <Button
                                                    variant="contained"
                                                    size="medium"
                                                    style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginBottom: 10}}
                                                    onClick={(e)=>{PayerMesSMS(price)}}
                                                  >
                                                      {Constant.acheter}
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </Grid>
                                })}
                              </Grid>
                            </Grid>
                        }
                        {Constant.mesExpediteur === titre &&
                            <Grid item lg={10}>
                              <Grid container spacing={3}>
                                  <Grid sm={6}>
                                        <Popover color="#F08080" placement="bottom" content={expediteurInfo} title="Information" overlayStyle={{zIndex: 1300}}>
                                          <TextField type="text" onChange={(e)=>setValue(e.target.value)} size="small" label={"Nom du nouveau expéditeur"} variant="outlined" fullWidth
                                              inputProps={{
                                                maxLength: 11,
                                              }}
                                            />
                                        </Popover>
                                  </Grid>
                                  <Grid sm={1}></Grid>
                                  <Grid sm={5}>
                                      <Button variant="outlined" color="secondary" onClick={(e)=>{EnregistrerSender()}} style={{marginRight: 10, visibility: value.trim() === "" ? 'hidden' : "visible"}} disabled={spinner}>
                                          <Check/> Envoyer pour validation
                                      </Button>
                                  </Grid>
                                  <Grid sm={12}>
                                    <br/>
                                      <MUIDataTable
                                        title={"Mes expéditeurs"}
                                        data={reponse2}
                                        columns={columns2}
                                        options={{
                                          filter: true,
                                          draggableColumns: { enabled: true },
                                          enableNestedDataAccess: '.',
                                          filterType: "multiselect",
                                          serverSide: true,
                                          jumpToPage: true,
                                          textLabels: {
                                            body: {
                                              noMatch: 'Rien à afficher pour le moment',
                                            }
                                          },
                                          selectableRows: "none"
                                        }}
                                        />
                                  </Grid>
                              </Grid>
                              <br/>
                            </Grid>
                        }

                        <Grid item lg={1}>
                        </Grid>
                    </Grid>
                        <Grid item lg={12}>
                          <hr/>
                            <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                              Fermer
                            </Button>
                        </Grid>
                </Paper>
              </Grid>
              <Grid item lg={2}>
              </Grid>
            </Grid>

        </div>
      </Modal>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
