import {
  Chip,
  IconButton
} from "@material-ui/core";
import React, { useState } from "react";

// components

// data
import Service, { urlTraccar } from "../../service";

import { DatePicker } from 'antd';

import { Edit } from "@material-ui/icons";
import 'moment/locale/fr';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Constant } from "../../constant";
import useStyles from "../dashboard/styles";
const { RangePicker } = DatePicker;


let search = ""
export default function GestionGPS() {
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  var [open, setOpen] = useState(false);
  const navigate = useHistory();
  var [page, setPage] = useState({page: 0, size: 50, totalElements: 0});
  const [serverSide, setServerSide] = useState(true);
  const [state, setState] = React.useState({
    id:0,
    appartenance: "",
    vehicleType: "",
    etat: "",
    phoneNumber: "",
    cle: "",
    nomgps: "",
    descriptiongps: "",
  });
  var [stateCherche, setStateCherche] = useState({
    appartenance: "",
    vehicleType: "",
    etat: "",
    phoneNumber: "",
    cle: "",
    imei: "",
  });
  const [error, setError] = React.useState({
    phoneNumber: false,
    description: false,
    type: false,
    montant: false,
    idcode: false,
  });

  React.useEffect(() => {
    afficher()
  }, []);

  const GetAllGPS = async (page, size, search) => {
    setSpinner(true)
    Service.ListGPS(page, size, search).then(
      async (response) => {
        setSpinner(false)
        setPage({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setReponse(response.data.content)
      }, error => {
        setSpinner(false);
        toast.error(error.response.data.error, {position: "top-center", autoClose: 10000,})
    })
}


const cliquer = async () => {
  if(state.phoneNumber.trim() === ""){
    setError({...error, phoneNumber: true})
  } else {
    setError({...error, phoneNumber: false})
    let data = {}
    data = state
    if(data.cle === ""){
      data.cle = 0
    }
      setSpinner(true);
      Service.updateGPS(data).then(
        async (response) => {
          setSpinner(false);
          setOpen(false)
          toast.success("Modification effectuée avec succès", {position: "top-center", autoClose: 10000,})
          GetAllGPS(page.page, page.size, (search !== "&" ? search.slice(0, -1) : ""))
        }, error => {
            setSpinner(false);
            toast.error("Erreur d'ajout. Identifiant de l'api doit être unique", {position: "top-center", autoClose: 10000,})
        }
      )
  }
}

const afficher = async () => {
  search = "&"
  if(stateCherche.appartenance.trim() !== ""){
    search = `${search}appartenance=${stateCherche.appartenance}&`
  }
  if(stateCherche.vehicleType.trim() !== ""){
    search = `${search}vehicleType=${stateCherche.vehicleType}&`
  }
  if(stateCherche.etat.trim() !== ""){
    search =`${search}etat=${stateCherche.etat}&`
  }
  if(stateCherche.phoneNumber.trim() !== ""){
    search =`${search}phoneNumber=${stateCherche.phoneNumber}&`
  }
  if(stateCherche.cle.trim() !== ""){
    search =`${search}cle=${stateCherche.cle}&`
  }
  if(stateCherche.imei.trim() !== ""){
    search =`${search}imei=${stateCherche.imei}&`
  }
  GetAllGPS(page.page, page.size, (search !== "&" ? search.slice(0, -1) : ""))
}

const modifier = async (data) => {
  setState({
    id: data.id,
    appartenance: data.appartenance,
    vehicleType: data.vehicleType,
    etat: data.etat,
    phoneNumber: data.phoneNumber,
    cle: data.cle,
    nomgps: data.nomgps,
    descriptiongps: data.descriptiongps,
  })
  setOpen(true)
}

const etatColor: React.CSSProperties = (value) => {
  if(value === "FONCTIONNEL") {
    return classes.success;
  } else if(value === "DAMAGED") {
    return classes.secondary;
  } else if(value === "LOSE") {
    return classes.dark;
  }  else if(value === "INSTALLED" || value === "CONFIGURED") {
    return classes.warning;
  }
}

const columns = [
  {
    name: "nomgps",
    label: "Nom du GPS",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "descriptiongps",
    label: "Autre information sur le GPS",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "etat",
    label: "Etat",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <div>
          <Chip label={Constant.etat.filter((a)=> a.value === value)[0].label} classes={{root: etatColor(value)}}/>
        </div>;
      }
     }
  },
  {
    name: "vehicleType",
    label: "Type du véhicule",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "imei",
    label: "Imei",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "phoneNumber",
    label: "Numéro de la puce",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "appartenance",
    label: "Appartenance",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "cle",
    label: "Clé",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "lastCoordinate.action",
    label: "Dernier message",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    name: "lastCoordinate.dateTime",
    label: "Dernière Date",
    options: {
      filter: true,
      sort: true,
     }
  },
  {
    label: "Action",
    name: "id",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return <div>
          {/* <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm("Etes-vous sûr de votre action ?"))deleteCentreConcours(value)}}> <Delete /> Supprimer </IconButton> */}
          <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifier(tableMeta.tableData[tableMeta.rowIndex])}}> <Edit /> Modifier </IconButton>
        </div>;
      }
    }
  }
];


  return (
      <iframe src={urlTraccar}  height= {window.innerHeight -100} width= '100%'></iframe>
  );
}
