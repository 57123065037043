import React, { useState } from "react";
import { Button, CircularProgress, Grid, IconButton, InputAdornment, LinearProgress, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Service from "../../service";

import { DatePicker, Select } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/fr';
import frFR from 'antd/es/date-picker/locale/fr_FR';
import { Constant } from "../../constant";
import { AccountBalance, Visibility, VisibilityOff, VpnKey } from "@material-ui/icons";
import orangemoney from '../../assets/orangemoney.png'
import moovmoney from '../../assets/moovmoney.png'
import samamoney from '../../assets/samamoney.png'
import wavemoney from '../../assets/wavemoney.jpeg'
const { RangePicker } = DatePicker;

let user = JSON.parse(localStorage.getItem('user'));
export default function Profil() {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [state, setState] = React.useState({
    ancien: "",
    nouveau: "",
    confirmation: "",
    numerobanque: "",
    nombanque: "",
    orangemoney: "",
    moovmoney: "",
    samamoney: "",
    wavemoney: "",
  });
  const [error, setError] = React.useState({
    ancien: false,
    nouveau: false,
    confirmation: false,
    nombanque: false,
    numerobanque: false,
  });
  const [values, setValues] = React.useState({showPassword: false});

  React.useEffect(() => {
    getData()
  }, []);

  const getData = () => {
    let numerobanque = "";
    let nombanque = "";
    let orangemoney = "";
    let moovmoney = "";
    let samamoney = "";
    let wavemoney = "";
    if(user.banques !== undefined && user.banques !== null && user.banques.trim() !== "") {
      let banques = user.banques.split("%value%");
      nombanque = banques[0];
      numerobanque = banques[1];
    }
    if(user.mobiles !== undefined && user.mobiles !== null && user.mobiles.trim() !== "") {
      let mobiles = user.mobiles.split("%element%");
      mobiles.forEach((element) => {
        if(element.includes("OrangeMoney")) {
          orangemoney = element.split("%value%")[1]
        } else if(element.includes("MoovMoney")) {
          moovmoney = element.split("%value%")[1]
        } else if(element.includes("SamaMoney")) {
          samamoney = element.split("%value%")[1]
        } else if(element.includes("WaveMoney")) {
          wavemoney = element.split("%value%")[1]
        }
      })
    }

    setState({
      ...state,
      nombanque: nombanque,
      numerobanque: numerobanque,
      orangemoney: orangemoney,
      moovmoney: moovmoney,
      samamoney: samamoney,
      wavemoney: wavemoney,
    })
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const ModifierBanque = (element) => {
    if(state.nombanque.trim() === "") {
      setError({...error, nombanque: true})
    } else if(state.numerobanque.trim() === "") {
      setError({...error, nombanque: false, numerobanque: true})
    } else {
      setError({...error, nombanque: false, numerobanque: false})
      let data = {
        id: user.id,
        type: element,
        value: state.nombanque+"%value%"+state.numerobanque,
      }
      ModificationBanqueMobile(data);
  };
}

  const ModificationBanqueMobile = (data) => {
    setSpinner(true);
        Service.ModificationBanqueMobile(data).then(
          async (response) => {
            setSpinner(false);
            localStorage.setItem('user', JSON.stringify(response.data));
            toast.success("Modification effectuée avec succès", {position: "top-center", autoClose: 10000,}, )
          }, error => {
              setSpinner(false);
              toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
        
          }
        )
    }

  const ModifierMobile = (element) => {
    let d = "";
    if(state.orangemoney.trim() !== "") {
      d = d + "%element%OrangeMoney%value%"+state.orangemoney
    }
    if(state.moovmoney.trim() !== "") {
      d = d + "%element%MoovMoney%value%"+state.moovmoney
    }
    if(state.samamoney.trim() !== "") {
      d = d + "%element%SamaMoney%value%"+state.samamoney
    }
    if(state.wavemoney.trim() !== "") {
      d = d + "%element%WaveMoney%value%"+state.wavemoney
    }

      let data = {
        id: user.id,
        type: element,
        value: d,
      }
      ModificationBanqueMobile(data);
  };

  const ModifierMotDePasse = () => {
    if(state.ancien.trim() === "") {
      setError({...error, ancien: true})
    } else if(state.nouveau.trim() === "") {
      setError({...error, ancien: false, nouveau: true})
    } else if(state.confirmation.trim() === "" || state.nouveau !== state.confirmation) {
      setError({...error, ancien: false, nouveau: false, confirmation: true})
    } else {
      setError({...error, ancien: false, nouveau: false, confirmation: false})
      const token = btoa(user.users.nomUtilisateur.toString() + ':' + state.ancien);
      if(localStorage.getItem("token") !== token) {
        toast.error("L'ancien mot de passe n'est pas correct", {position: "top-center", autoClose: 10000,})
      } else {
        setSpinner(true);
        Service.ModificationMotDePasse(user.users.nomUtilisateur, state.nouveau).then(
          async (response) => {
            setSpinner(false);
            const tok = btoa(user.users.nomUtilisateur.toString() + ':' + state.nouveau);
            localStorage.setItem('token', tok);
            toast.success("Votre mot de passe à été modifié avec succès", {position: "top-center", autoClose: 10000,}, )
            setInterval(() => {
              window.location.reload()
            }, 5000);
          }, error => {
              setSpinner(false);
              toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
        
          }
        )
      }
    } 
  };

  return (
    <>
      <ToastContainer />
      <PageTitle title="Mon profil" />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item sm={4}>
          <Paper square  style={{padding: 20, marginBottom: 20}}>
            <p><strong>Nom: </strong> {user.nom}</p>
            <p><strong>Téléphone: </strong> {user.telephone}</p>
            <p><strong>Email: </strong> {user.users.nomUtilisateur}</p>
            <p><strong>Date d'inscription: </strong> {user.dateinscription}</p>
          </Paper>

          <Paper square  style={{padding: 20}}>
            <p><strong>Modification du mot de passe</strong></p>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField type={values.showPassword ? 'text' : 'password'} error={error.ancien} helperText={error.ancien && Constant.champObligatoire} onChange={(e)=>setState({...state, ancien: e.target.value})} size="small" label="Ancien mot de passe" variant="outlined" fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKey />
                        </InputAdornment>
                      ),
                      endAdornment:(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField type={values.showPassword ? 'text' : 'password'} error={error.nouveau} helperText={error.nouveau && Constant.champObligatoire} onChange={(e)=>setState({...state, nouveau: e.target.value})} size="small" label="Nouveau mot de passe" variant="outlined" fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKey />
                        </InputAdornment>
                      ),
                      endAdornment:(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField type={values.showPassword ? 'text' : 'password'} error={error.confirmation} helperText={error.confirmation && Constant.champObligatoire + ". Et doit être identique au nouveau mot de passe."} onChange={(e)=>setState({...state, confirmation: e.target.value})} size="small" label="Saisissez encore le nouveau mot de passe" variant="outlined" fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKey />
                        </InputAdornment>
                      ),
                      endAdornment:(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" color="secondary" onClick={(e)=>ModifierMotDePasse()} disabled={spinner}>
                    Modifier 
                    {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                  </Button>
                </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={1}></Grid>
        <Grid item sm={7}>
        <Paper square  style={{padding: 20, marginBottom: 20, textAlign: 'center'}}>
            <p><strong>Vos informations financières pour recevoir votre argent en cas de retrait</strong></p>
        </Paper>
        <Paper square  style={{padding: 20, marginBottom: 20}}>
            <p><strong>Pour la banque</strong></p>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField type='text' value={state.nombanque} error={error.nombanque} helperText={error.nombanque && Constant.champObligatoire} onChange={(e)=>setState({...state, nombanque: e.target.value})} size="small" label="Nom de la banque" variant="outlined" fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBalance />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' value={state.numerobanque} error={error.numerobanque} helperText={error.numerobanque && Constant.champObligatoire} onChange={(e)=>setState({...state, numerobanque: e.target.value})} size="small" label="Numéro du compte bancaire" variant="outlined" fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBalance />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" color="secondary" onClick={(e)=>ModifierBanque("banques")} disabled={spinner}>
                    Modifier 
                    {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                  </Button>
                </Grid>
            </Grid>
          </Paper>
          <Paper square  style={{padding: 20, marginBottom: 20}}>
            <p><strong>Pour les mobiles</strong></p>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField type='text' value={state.orangemoney} onChange={(e)=>setState({...state, orangemoney: e.target.value})} size="small" label="OrangeMoney" variant="outlined" fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={orangemoney} style={{height: 20, borderRadius: 5}} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' value={state.moovmoney} onChange={(e)=>setState({...state, moovmoney: e.target.value})} size="small" label="MoovMoney" variant="outlined" fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                           <img src={moovmoney} style={{height: 20, borderRadius: 5}} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' value={state.samamoney} onChange={(e)=>setState({...state, samamoney: e.target.value})} size="small" label="SamaMoney" variant="outlined" fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                           <img src={samamoney} style={{height: 20, borderRadius: 5}} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField type='text' value={state.wavemoney} onChange={(e)=>setState({...state, wavemoney: e.target.value})} size="small" label="WaveMoney" variant="outlined" fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                           <img src={wavemoney} style={{height: 20, borderRadius: 5}} />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" color="secondary" onClick={(e)=>ModifierMobile("mobiles")} disabled={spinner}>
                    Modifier 
                    {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                  </Button>
                </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
