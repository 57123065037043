import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Modal,
  Paper,
  TextField
} from "@material-ui/core";
import React, { useState } from "react";

// styles
import useStyles from "./styles";

import PageTitle from "../../components/PageTitle/PageTitle";
import { Constant } from "../../constant";
import BigStat from "./components/BigStat/BigStat";


import { DatePicker, Select } from 'antd';

import { AccountBalanceWallet, Cancel, LibraryBooks, Mail } from "@material-ui/icons";
import frFR from 'antd/es/date-picker/locale/fr_FR';
import moment from 'moment';
import 'moment/locale/fr';
import MUIDataTable from "mui-datatables";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Service from "../../service";
import { AsRoles } from "../../verifyRoles";
const { RangePicker } = DatePicker;

let user = JSON.parse(localStorage.getItem('user'));
let dateConst = {}
export default function Statistique(props) {
  var classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const [userTrouver, setUserTrouver] = useState({});
  const [reponse, setReponse] = useState([]);
  const [compte, setCompte] = useState([]);
  const [compteUtilisateur, setCompteUtilisateur] = useState([]);
  const [compteDate, setCompteDate] = useState([]);
  const [compteDateSevob, setCompteDateSevob] = useState([]);
  var [open, setOpen] = useState(false);
  var [titre, setTitre] = useState("");
  const [state, setState] = React.useState({
    idutilisateur: user.id,
    montant:0,
    montantConfirmation:0,
    typepaiement: "",
    nomentreprise: "",
    autreinformation: "",
    emailDetinateur: "",
    idreceveur: 0
  });
  const [error, setError] = React.useState({
    montant: false,
    montantConfirmation: false,
    typepaiement: false,
    emailDetinateur: false,
  });

  const columns = [
    {
      name: "Motif"
    },
    {
      name: "Nom"
    },
    {
      name: "Montant"
    },
    {
      name: "Facturaction"
    },
    {
      name: "Reste"
    },
    {
      name: "Type de transaction"
    },
    {
      name: "Autres informations"
    },
    {
      name: "Commentaire de SEVOB"
    },
    {
      name: "Date d'ajout"
    },
    {
      name: "Heure"
    },
    {
      name: "Statut",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Chip label={value} classes={{root: value.includes("effectué") ? classes.success : (value.includes("attente") ? classes.warning : (value.includes("cours") ? classes.blueBackground : classes.secondary))}}/>;
        }
      }
    },
    {
      name: "Action",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return !tableMeta.rowData[10].includes("annulé") && <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm('Confirmez-vous l\'annulation de la demande ?')) changeStatut(tableMeta.rowData[11])}}> <Cancel /> Annuler</IconButton> 
        }
      }
    }
  ];

  React.useEffect(() => {
    (async ()=> {
      if(props.match.params.data.split("2474&")[1] !== undefined){
        if(AsRoles([Constant.ROLE_SEVOB_SUPER_ADMIN, Constant.ROLE_STATISTIQUES_SEVOB]) === Constant.visible){
          await init();
        } else {
          props.history.push("/app/Profil")
        }
      } else {
        init()
      }
    })();
    
  }, []);


  const init = async () => {
    getSolde()
}

  const changeStatut = async (idTransaction) => {
      setSpinner(true)
      Service.annulerTransactionSevob(idTransaction).then(
        async (response) => {
          setSpinner(false);
          if(response !== undefined && response.data.includes("succès")) {
            toast.success(response.data, {position: "top-center", autoClose: 10000,})
            getSolde()
            if(dateConst.utilisateurid !== undefined){
              let d = dateConst.debut.setMonth(dateConst.debut.getMonth() - 1);
              let dend = dateConst.fin.setMonth(dateConst.fin.getMonth() - 1);
              historique(new Date(d), new Date(dend))
            }
          } else {
            toast.error(response.data, {position: "top-center", autoClose: 10000,})
          }
        }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      })
  }

  const Envoyer = async (titre) => {
    if(state.idreceveur <= 0 && titre === Constant.faireTransfertSevob){
      setError({...error, montant: false, montantConfirmation: false, typepaiement: false, emailDetinateur: true})
      // toast.error("L'email du destinateur est obligatoire et doit être un email lié à un compte SEVOB", {position: "top-center", autoClose: 10000,})
    } else if(state.montant <= 0){
      setError({...error, montant: true})
    } else if(state.montantConfirmation <= 0 || state.montantConfirmation !== state.montant){
      setError({...error, montant: false, montantConfirmation: true})
    } else if(state.typepaiement.trim() === "" && titre === Constant.faireRetraitSevob){
      setError({...error, montant: false, montantConfirmation: false, typepaiement: true})
    } else if(state.nomentreprise.trim() === ""){
      setError({...error, montant: false, montantConfirmation: false, typepaiement: false, nomentreprise: true})
    } else if(state.autreinformation.trim() === ""){
      setError({...error, montant: false, montantConfirmation: false, typepaiement: false, nomentreprise: false, autreinformation: true})
    } else {
      if(window.confirm('Confirmez-vous l\'envoie de la demande ?')){
            setSpinner(true)
            setError({...error, montant: false, montantConfirmation: false, typepaiement: false, nomentreprise: false, autreinformation: false})
            if(titre === Constant.faireRetraitSevob) {
                Service.faireRetraitSevob(state).then(
                  async (response) => {
                    setSpinner(false);
                    if(response !== undefined && response.data.includes("succès")) {
                      toast.success(response.data, {position: "top-center", autoClose: 10000,})
                      setOpen(false)
                      getSolde()
                      if(dateConst.utilisateurid !== undefined){
                        let d = dateConst.debut.setMonth(dateConst.debut.getMonth() - 1);
                        let dend = dateConst.fin.setMonth(dateConst.fin.getMonth() - 1);
                        historique(new Date(d), new Date(dend))
                      }
                    } else {
                      toast.error(response.data, {position: "top-center", autoClose: 10000,})
                    }
                  }, error => {
                    toast.error("Erreur du serveur. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
                    setSpinner(false);
                  }
                )
            } else if(titre === Constant.faireTransfertSevob){
              Service.faireTransfertSEVOB(state).then(
                async (response) => {
                  setSpinner(false);
                  if(response !== undefined && response.data.includes("succès")) {
                    toast.success(response.data, {position: "top-center", autoClose: 10000,})
                    setOpen(false)
                    getSolde()
                    if(dateConst.utilisateurid !== undefined){
                      let d = dateConst.debut.setMonth(dateConst.debut.getMonth() - 1);
                      let dend = dateConst.fin.setMonth(dateConst.fin.getMonth() - 1);
                      historique(new Date(d), new Date(dend))
                    }
                  } else {
                    toast.error(response.data, {position: "top-center", autoClose: 10000,})
                  }
                }, error => {
                  toast.error("Erreur du serveur. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
                  setSpinner(false);
                }
              )
            }
        }
    }

  }
  const getSolde = () => {
    Service.getSoldeStatistique().then(
      async (response) => {
        if(typeof(response.data) !== "string"){
          let data = [
            {
              product: "Solde actuel de SEVOB",
              total: response.data.soldeSevob
            },
            {
              product: "Retraits de SEVOB",
              total: response.data.retraitSevob
            },
            {
              product: "Transferts de SEVOB",
              total: response.data.transfertSevob
            },
          ]

          let data2 = [
            {
              product: "Solde actuel des utilisateurs",
              total: response.data.solde
            },
            {
              product: "Recharges des utilisateurs",
              total: response.data.recharge
            },
            {
              product: "Retraits des utilisateurs",
              total: response.data.retrait
            },
            {
              product: "Transferts des utilisateurs",
              total: response.data.transfert
            },
            {
              product: "Paiements web des utilisateurs",
              total: response.data.paiement
            },
            {
              product: "Abonnement des utilisateurs",
              total: response.data.abonnement
            },
            {
              product: "Achat des SMS",
              total: response.data.smsachat
            },
          ]
          setCompte(data);
          setCompteUtilisateur(data2);
        } else {
          alert(response.data)
          props.history.push("/app/Profil")
        }
      })
  }

  const periode = async (value) => {
    // await setEndDate(new Date());
    const date = new Date();
    if(value === "Journalière") {
    } else if(value === "Hebdomendaire") {
      await date.setDate(date.getDate() - 7);
    } else if(value === "Mensuel") {
      await date.setDate(date.getDate() - 30);
    } else if(value === "Trimestriel") {
      await date.setDate(date.getDate() - 90);
    } else if(value === "Annuelle") {
      await date.setDate(date.getDate() - 365);
    }
    historique(date, new Date())
  }


  const historique = async (date, endDate) => {
    setSpinner(true);
    endDate.setMonth(endDate.getMonth());
    date.setMonth(date.getMonth());
    let data = {
      utilisateurid: user.id,
      debut: date.getFullYear()+"-"+(date.getMonth() + 1)+"-"+date.getDate(),
      fin: endDate.getFullYear()+"-"+(endDate.getMonth() + 1)+"-"+endDate.getDate(),
    }
    dateConst = {
      utilisateurid: 1,
      debut: date,
      fin: endDate
    };
    Service.SoldeParDateSEVOB(data).then(
      async (response) => {
        if(typeof(response.data) !== "string"){
          let data = [
            {
              product: "Solde des utilisateurs",
              total: response.data.solde
            },
            {
              product: "Recharges des utilisateurs",
              total: response.data.recharge
            },
            {
              product: "Retraits des utilisateurs",
              total: response.data.retrait
            },
            {
              product: "Transferts des utilisateurs",
              total: response.data.transfert
            },
            {
              product: "Paiements web des utilisateurs",
              total: response.data.paiement
            },
            {
              product: "Abonnement des utilisateurs",
              total: response.data.abonnement
            },
          ]

          let data2 = [
            {
              product: "Solde de SEVOB",
              total: response.data.soldeSevob
            },
            {
              product: "Retraits de SEVOB",
              total: response.data.retraitSevob
            },
            {
              product: "Transferts de SEVOB",
              total: response.data.transfertSevob
            },
          ]
          
          setCompteDate(data);
          setCompteDateSevob(data2);
          let donnees = []
          response.data.listTransfertRetrait.reverse().forEach((e, index) => {
            let motif = "";
            if(e.etat.includes('Retrait')) {
              motif = 'Retrait';
            } else if(e.etat.includes('Transfert')) {
              motif = 'Transfert'
            }
            if(e.utilisateurid === ((props.match.params.data.split("2474&")[1] !== undefined) ? parseInt(props.match.params.data.split("2474&")[0]) : user.id) && e.etat.includes('Transfert')){
              
            } else {
              donnees.push([
                motif,
                e.nomentreprise,
                e.montant.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " " +Constant.devis,
                e.facturation,
                e.recetteclient.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')  + " " +Constant.devis,
                e.typepaiement,
                e.autreinformation,
                e.commentaire,
                e.dateinscription,
                e.heure,
                e.etat,
                e.id,
              ])
            }
            if(response.data.listTransfertRetrait.length === (index + 1)){
              setReponse(donnees);
            }
          });
        } else {
          alert(response.data)
          props.history.push("/app/Profil")
        }
        setSpinner(false);

      }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      }
    )
}


function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}
const onChange = (dates, dateStrings) => {
  if(dateStrings[0] !== '' && dateStrings[1] !== ''){
    historique(new Date(dateStrings[0]), new Date(dateStrings[1]))
  }
  // this.setState({debut: dateStrings[0].split("-").reverse().join("-"), fin: dateStrings[1].split("-").reverse().join("-")})
}

const OpenModal = async (titre) => {
  setOpen(true)
  setTitre(titre)
}

const verifier = async () => {
  setUserTrouver({})
  if(state.emailDetinateur.trim() === "" && state.emailDetinateur.split("@")[1] === undefined){
    setError({...error, emailDetinateur: true})
  } else {
    setError({...error, emailDetinateur: false})
    setSpinner(true);
    Service.getUtilisateur(state.emailDetinateur).then(
      async (response) => {
        setSpinner(false);
        setUserTrouver(response.data)
        setState({...state, idreceveur: response.data.id})
      }, error => {
        toast.error("Erreur du serveur. Assurez-vous que cet email est lié à un compte SEVOB", {position: "top-center", autoClose: 10000,})
        setSpinner(false);
      })
  }
}
 
  return (
    <>
      <PageTitle title={(props.match.params.data.split("2474&")[1] !== undefined) ? ("Compte de " + props.match.params.data.split("2474&")[1]) : "Statistiques"} button={
        ((props.match.params.data.split("2474&")[1] === undefined)) &&
        <div>
          {user.roles.some((a)=> a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_RETRAIT_SEVOB) &&
            <Button
              variant="contained"
              size="medium"
              style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginBottom: 10}}
              onClick={(e)=>{OpenModal(Constant.faireRetraitSevob)}}
            >
                {Constant.faireRetraitSevob}
            </Button>
          }
          {user.roles.some((a)=> a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_TRANSFERT_SEVOB) &&
            <Button
              variant="contained"
              size="medium"
              style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, marginLeft: 20, marginBottom: 10}}
              onClick={(e)=>{OpenModal(Constant.faireTransfertSevob)}}
            >
                {Constant.faireTransfertSevob}
            </Button>
          }
        </div>
      } />
      <Grid container spacing={4}>
        {compte.length>0 && compte.map(stat => (
          <Grid item md={4} sm={6} xs={12} key={stat.product} style={{backgroundColor: 'rgba(255,0,0,0.8)', borderBottomColor: 'rgba(255,0,0,0.3)', borderBottomWidth: 5, borderBottomStyle: 'solid'}}>
            <BigStat {...stat} />
          </Grid>
        ))}
        </Grid>
        <Grid container spacing={4}>
        {compteUtilisateur.length>0 && compteUtilisateur.map(stat => (
          <Grid item md={3} sm={6} xs={12} key={stat.product} style={{backgroundColor: Constant.couleurPrimaire, borderBottomColor: 'rgba(255,0,0,0.3)', borderBottomWidth: 5, borderBottomStyle: 'solid'}}>
            <BigStat {...stat} />
          </Grid>
        ))}


      <Grid container spacing={4} style={{marginTop: 20, marginLeft: 0}}>
        <Grid item sm={2}>
          <strong>Par Période</strong>
          <br/>
          <Select
          dropdownStyle={{zIndex: 1300}}
          style={{ width: '100%' }}
            onChange={(e)=>periode(e)}
            options={[
              { value: 'Journalière', label: 'Journalière' },
              { value: 'Hebdomendaire', label: 'Hebdomendaire' },
              { value: 'Mensuel', label: 'Mensuel' },
              { value: 'Trimestriel', label: 'Trimestriel' },
              { value: 'Annuelle', label: 'Annuelle' },
            ]}
          />
        </Grid>
        <Grid item sm={4}>
          <strong>Par intervalle de Date</strong>
          <br/>
          <RangePicker 
            disabledDate={disabledDate}
            onChange={onChange}
            // format="DD-MM-YYYY"
            style={{width: "100%"}}
            locale={frFR}
            ranges={{
              'Aujourd\'hui': [moment(), moment()],
              'Ce mois': [moment().startOf('month'), moment().endOf('month')],
            }}
            placeholder={["Du", "au"]}
          />
        </Grid>
        <Grid item xs={12}>
        {spinner && <LinearProgress color="secondary" />}

        </Grid>
      </Grid>

      {compteDateSevob.length>0 && compteDateSevob.map(stat => (
          <Grid item md={4} sm={6} xs={12} key={stat.product}>
            <BigStat {...stat} />
          </Grid>
        ))}

      {compteDate.length>0 && compteDate.map(stat => (
          <Grid item md={3} sm={6} xs={12} key={stat.product}>
            <BigStat {...stat} />
          </Grid>
        ))}

        <Grid item xs={12}>
          <MUIDataTable
          title={(props.match.params.data.split("2474&")[1] !== undefined) ? ("Liste des retraits et transferts de " + props.match.params.data.split("2474&")[1]) : "Liste des retraits et transferts de SEVOB"}
            data={reponse}
            columns={columns}
            options={{
              filterType: "checkbox",
              textLabels: {
                body: {
                  noMatch: 'Rien à afficher pour le moment',
                }
              },
              selectableRows: "none"
            }}
          />
        </Grid>
      </Grid>


      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1200, overflow: 'scroll'}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item lg={4}>
              </Grid>
              <Grid item lg={4}>
                <Paper square  style={{marginTop: 50, padding: 50, paddingTop: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       {titre}
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item lg={1}>
                        </Grid>
                        <Grid item lg={10}>
                            {titre === Constant.faireTransfertSevob &&
                              <Grid container spacing={3} style={{backgroundColor: "rgba(0, 255, 0, 0.1)", marginBottom: 20}}>
                                    <Grid item sm={8}>
                                            <TextField type="email" error={error.emailDetinateur} helperText={error.emailDetinateur && Constant.champObligatoire + ". Il doit être un email valide et lié à un compte SEVOB."}  onChange={(e)=>setState({...state, emailDetinateur: e.target.value})} size="small" label="Email du destinateur" variant="outlined" fullWidth
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <Mail />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                      </Grid>
                                      <Grid item sm={3}>
                                        <Button variant="outlined" color="primary" onClick={(e)=>{verifier()}} style={{marginRight: 10}} disabled={spinner}>
                                            Verifier {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                                          </Button>
                                      </Grid>
                                      <Grid item sm={1}></Grid>
                                      {userTrouver.id !== undefined &&
                                        <Grid item xs={6} style={{backgroundColor: Constant.couleurPrimaire, color: 'white', lineHeight: 1}}>
                                          <strong style={{lineHeight: 1}}>Nom: {userTrouver.nom}</strong>
                                        </Grid>
                                      }
                                      {userTrouver.id !== undefined &&
                                        <Grid item sm={6} style={{backgroundColor: Constant.couleurPrimaire, color: 'white', lineHeight: 1}}>
                                          <strong style={{lineHeight: 1}}>Téléphone: {userTrouver.telephone}</strong>
                                        </Grid>
                                      }
                              </Grid>
                            }
                            <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                          <TextField type="number" error={error.montant} helperText={error.montant && Constant.champObligatoire} onChange={(e)=>setState({...state, montant: e.target.value})} size="small" label={titre === Constant.faireRetraitSevob ? "Montant du retrait" : "Montant du transfert"} variant="outlined" fullWidth
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <AccountBalanceWallet />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                          <TextField type="number" error={error.montantConfirmation} helperText={error.montantConfirmation && Constant.champObligatoire} onChange={(e)=>setState({...state, montantConfirmation: e.target.value})} size="small" label={titre === Constant.faireRetraitSevob ? "Saisissez à nouveau le montant du retrait" : "Saisissez à nouveau le montant du transfert" } variant="outlined" fullWidth
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <AccountBalanceWallet />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                    </Grid>
                                    {titre === Constant.faireRetraitSevob &&
                                      <Grid item xs={12}>
                                          <span>Type du règlement</span>
                                          <br/>
                                          <Select
                                            style={{ width: '100%' }}
                                            dropdownStyle={{zIndex: 1300}}
                                            onChange={(e)=>setState({...state, typepaiement: e})}
                                            options={[
                                              { value: 'Cash', label: 'Cash' },
                                              { value: 'Chèque', label: 'Chèque' },
                                              { value: 'Virement', label: 'Virement' },
                                              { value: 'OrangeMoney', label: 'OrangeMoney' },
                                              { value: 'WaveMoney', label: 'WaveMoney' },
                                              { value: 'SamaMoney', label: 'SamaMoney' },
                                              { value: 'MoovMoney', label: 'MoovMoney' },
                                            ]}
                                          />
                                          {error.typepaiement && <small style={{color: 'red'}}>{Constant.champObligatoire}</small>}
                                      </Grid>
                                    }
                                    <Grid item xs={12}>
                                          <TextField error={error.nomentreprise} helperText={error.nomentreprise && Constant.champObligatoire} onChange={(e)=>setState({...state, nomentreprise: e.target.value})} size="small" label="Nom" variant="outlined" fullWidth
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <LibraryBooks />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                            <small>Ce champ fait référence au nom spécifique de l'objet du {titre === Constant.faireRetraitSevob ? "retrait" : "transfert"}, tel que le nom d'une société, d'une personne, etc... <br/>Example: SEVOB-SARL ou Remboursement de PIERRE</small>
                                    </Grid>
                                    <Grid item xs={12}>
                                          <TextField error={error.autreinformation} helperText={error.autreinformation && Constant.champObligatoire} onChange={(e)=>setState({...state, autreinformation: e.target.value})} size="small" label="Autres informations" variant="outlined" fullWidth
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <LibraryBooks />
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                            <small>Ce champ concerne toute autre information que vous souhaitez conserver concernant ce {titre === Constant.faireRetraitSevob ? "retrait" : "transfert"}.</small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="outlined" color="secondary" onClick={(e)=>{Envoyer(titre)}} style={{marginRight: 10}} disabled={spinner}>
                                          Envoyer {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                                        </Button>
                                        <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                                          Fermer
                                        </Button>
                                    </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={1}>
                        </Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item lg={4}>
              </Grid>
            </Grid>

        </div>
      </Modal>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
