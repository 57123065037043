import React from "react";


let user = JSON.parse(localStorage.getItem('user'));
// This is named export.
export const AsRoles = (data) => {
  let visibilite = "hidden"
  data.forEach((element, index) => {
    if(user.roles.some(e => e.name === element)) {
      visibilite = "visible";
    }
  });
  return visibilite
};