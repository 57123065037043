import {
  Button
} from "@material-ui/core";
import React, { useState } from "react";

// components

// data
import Service, { urlTraccar } from "../../service";

import { Alert, Spin } from 'antd';

import { AccountBalanceWallet } from "@material-ui/icons";
import { theme } from 'antd';
import axios from "axios";
// import "leaflet-routing-machine";
// import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import 'moment/locale/fr';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorgif from "../../assets/error.gif";
import gpsspinner from "../../assets/gpsspinner.gif";
import { Constant } from "../../constant";

export default function Geolocalisation(props) {
  let propriete = props.match === undefined ? props.props : props;
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [codeAbonnement, setCodeAbonnement] = useState("");
  const [grandSpinner, setGrandSpinner] = useState(true);
  const [loadings, setLoadings] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();


  React.useEffect(() => {
    VerifierMonAbonnement()
    
  }, [propriete]);
  
  const VerifierMonAbonnement = async () => {
    Service.VerifierMonAbonnement(propriete.match.params.data.split("2474&")[0]).then(
      async (response) => {
            setMessage(response.data.message)
            setEmail(response.data.email)
            setCodeAbonnement(response.data.code);
            if(response.data.code === "200") {
              if(propriete.match.url.includes("maps")){
                verifyLink()
              }
              setGrandSpinner(propriete.match.url.includes("maps") ? true : false)
            }
          })
}

  const verifyLink = async () => {
    if(props.location.search === "" || props.location.search=== null || props.location.search === undefined){
      setMessage(Constant.lienNonValide)
    } else {
      Service.verifyLink(props.location.search.split("=")[1]).then(
          async (response) => {
            setGrandSpinner(false)
          }, error => {
            setMessage(Constant.lienNonValide)
        })
    } 
}

const PayerMonAbonnement = () => {
  setLoadings(true)
  Service.addAbonnement(propriete.match.params.data.split("2474&")[0]).then(
    async (response) => {
      let body = {
      'mode': 'REEL',
      'cle': Constant.CleDeSevob,
      'nom_entreprise': 'SEVO-Abonnement',
      'lien_logo_entreprise': Constant.lienLogo,
      'montant': response.data.montant,
      'commande_id': new Date().getTime()+"p",
      'autre_information': '',
      'return_url': window.location.href,
      'cancel_url': 'https://vps82722.serveur-vps.net:2474/sevob/anonyme/changeStatutAbonnement/'+response.data.id,
      'success_url': 'https://vps82722.serveur-vps.net:2474/sevob/anonyme/changeStatutAbonnement/'+response.data.id,
      'error_url': 'https://vps82722.serveur-vps.net:2474/sevob/anonyme/changeStatutAbonnement/'+response.data.id,
      }
      axios.post('https://api.sevob.net', body).then(
        (reponse)=> {
          if(reponse.data.code === "200") {
            window.open(reponse.data.url, "_self")
          } else {
            toast.error(reponse.data.message, {position: "top-center", autoClose: 10000,})
          }
        },
        error => {
          toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
        }
      );
      setLoadings(false)
    }, error => {
      setLoadings(false)
      toast.error(Constant.erreurServeur, {position: "top-center", autoClose: 10000,})
  })
  
}


  return (
    <>
    {(message !== "" && codeAbonnement === "200") && <Alert message={
      <div>
        {message}
        <Button size="small" onClick={(e)=>{PayerMonAbonnement()}} variant="outlined" startIcon={<AccountBalanceWallet />} style={{color: 'white', borderColor: 'white', backgroundColor: 'red', marginLeft: 20}}>
            Payer mon abonnement maintenant {loadings && <Spin style={{backgroundColor: 'white', marginLeft: 10}}/>}
        </Button>
      </div>
    } type="error" showIcon closable/>}
    {(!grandSpinner && email !== "") ?
        <div>
          <iframe src={urlTraccar + "login?email=" + email} height= {window.innerHeight -100} width= '100%'></iframe>
        </div>
    :
        <div>
          <div style={{
            display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            height: '100vh',
          }}>
              {message === "" ?
                  <img
                    alt="Recherche"
                    src={gpsspinner}
                    // style={{height: 150, width: 150}}
                  />
                  :
                  <div style={{textAlign: 'center'}}>
                    <img
                      alt="Recherche"
                      src={errorgif}
                      // style={{height: 150, width: 150}}
                    />
                    <h1 style={{color: 'red'}}>{message}</h1>
                    {codeAbonnement === "400" && 
                      <Button size="small" onClick={(e)=>{PayerMonAbonnement()}} variant="outlined" startIcon={<AccountBalanceWallet />} style={{color: 'white', borderColor: 'white', backgroundColor: Constant.couleurPrimaire}}>
                          Payer mon abonnement maintenant {loadings && <Spin style={{backgroundColor: 'white', marginLeft: 10}}/>}
                      </Button>
                    }
                  </div>
                }
          </div>
        </div>
    }
    </>
  );
}
