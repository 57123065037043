import axios from 'axios';

export const header = {'Authorization': `Basic ${localStorage.getItem('token') !== null ? localStorage.getItem('token') : ""}`};


// const url = 'http://localhost:8080'
const url = 'https://vps82722.serveur-vps.net:2474/sevob'
const urlgps = 'https://vps82722.serveur-vps.net:2474/gps'
export const urlTraccar = 'https://map.sevob.net/'
class Service {
      constructor() {}
       
       static inscription(data) {
        return axios.post(url + '/anonyme/inscription', data).catch(function (error) {
          if(error.response === undefined || error.response.status === 403 || error.response.status === 401) {
            return axios.post(url + '/users/inscription', data);
          }
        });
      }

      static ListGPS(page, size, search) {
        return axios.get(urlgps + `/gps/all?paginate=true&page=${page}&size=${size}${search}`);
      }

     static updateGPS(data) {
        return axios.put(urlgps + '/gps', data, {headers: header});
      }

      static async verifyLink(lien) {
        return axios.get(url + '/anonyme/DataForLink/'+lien);
      }
       

      static async VerifierMonAbonnement(cle) {
        return axios.get(url + '/anonyme/VerifierMonAbonnement/'+cle);
      }
       

      static async changeStatutAPIs(id) {
        return axios.get(url + '/users/changeStatutAPIs/'+id, {headers: header});
      }
       
      static async envoisms(data) {
        return axios.post(url + '/anonyme/sendSMS', data);
      }
      static async addSmsPayment(data) {
        return axios.post(url + '/users/addSmsPayment', data, {headers: header});
      }
       
       
      static async addAbonnement(cle, data= {}) {
        return axios.post(url + '/users/addAbonnement/' + cle, data, {headers: header}).catch(function (error) {
          // if(error.response === undefined || error.response.status === 403 || error.response.status === 401) {
          //   return axios.get(url + '/anonyme/addAbonnement/' + cle);
          // }
        });
      }
       
      static async getSession(data) {
        return axios.post(urlTraccar + '/api/session', data, {headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }});
      }
       
      static async faireRetraitSevob(data) {
        return axios.post(url + '/users/faireRetraitSevob', data, {headers: header});
      }
       
      static async demandeRetrait(data) {
        return axios.post(url + '/users/demandeRetrait', data, {headers: header});
      }
       
      static async demandeTransfert(data) {
        return axios.post(url + '/users/demandeTransfert', data, {headers: header});
      }
       
      static async faireRecharge(data) {
        return axios.post(url + '/users/faireRecharge', data, {headers: header});
      }
       
      static async addGroupe(data) {
        return axios.post(url + '/users/addGroupe', data, {headers: header});
      }
       
      static async addSender(data) {
        return axios.post(url + '/users/addSender', data, {headers: header});
      }
       
      static async addQrCode(data) {
        return axios.post(url + '/users/addQrCode', data, {headers: header});
      }
       
      static async getQrCode(cle) {
        return axios.get(url + '/anonyme/getQrCode/' + cle);
      }
       
      static async faireTransfertSEVOB(data) {
        return axios.post(url + '/users/faireTransfertSEVOB', data, {headers: header});
      }
       
      static async historiqueAllUtilisateur(data) {
        return axios.post(url + '/users/historiqueAllUtilisateur', data, {headers: header});
      }
       
       
      static async allUtilisateurRecherche(data) {
        return axios.post(url + '/users/allUtilisateurRecherche', data, {headers: header});
      }
       
       
      static async utilisateurRetraitTransfert(data) {
        return axios.post(url + '/users/utilisateurRetraitTransfert', data, {headers: header});
      }
       
      static async mesTransactionList(data) {
        return axios.post(url + '/users/mesTransactionList', data, {headers: header});
      }
       
      static async SoldeParDate(data) {
        return axios.post(url + '/users/SoldeParDate', data, {headers: header});
      }
       
      static async EnvoiFaitParDate(data) {
        return axios.post(url + '/users/EnvoiFaitParDate', data, {headers: header});
      }
       
       
      static async AnnulerRetrait(data) {
        return axios.post(url + '/users/AnnulerRetrait', data, {headers: header});
      }
       
      static async SoldeParDateSEVOB(data) {
        return axios.post(url + '/users/SoldeParDateSEVOB', data, {headers: header});
      }
       
      static async getSolde(IdUtilisateur) {
        return axios.get(url + '/users/getSolde/' + IdUtilisateur, {headers: header});
      }
       
      static async getApisDuClient(idUser, identifiant) {
        return axios.get(url + '/users/getApisDuClient/' + identifiant + "/" + idUser, {headers: header});
      }

      static async getSoldeSMS(cle) {
        return axios.get(url + '/users/getSoldeSMS/' + cle, {headers: header});
      }
       
      static async getGroupe(cle) {
        return axios.get(url + '/users/getGroupe/' + cle, {headers: header});
      }
       
      static async getSender(idutilisateur) {
        return axios.get(url + '/users/getSender/' + idutilisateur, {headers: header});
      }
       
      static async getSenderAll() {
        return axios.get(url + '/users/getSenderAll', {headers: header});
      }
      
       
      static async getSoldeStatistique() {
        return axios.get(url + '/users/getSoldeStatistique', {headers: header});
      }
      
       
      static async mesTransactionTestList(data) {
        return axios.post(url + '/users/mesTransactionTestList', data, {headers: header});
      }
      
       
      static async historiqueAllCentre(data) {
        return axios.post(url + '/users/historiqueAllCentre', data, {headers: header});
      }
      
       
      static async historiqueState(data) {
        return axios.post(url + '/users/historiqueState', data, {headers: header});
      }
       
      static async SaveApi(data) {
        return axios.post(url + '/users/SaveApi', data, {headers: header});
      }
       
      static async ModifierApiUtilisateur(data) {
        return axios.post(url + '/users/ModifierApiUtilisateur', data, {headers: header});
      }
      
        
      static async addPub(data) {
        return axios.post(url + '/users/addPub', data, {headers: header});
      }

      static async updateUser(data) {
        return axios.put(url + '/users/updateUser', data, {headers: header});
      }

      
      static Listpub() {
        return axios.get(url + '/anonyme/Listpub').catch(function (error) {
          if(error.response === undefined || error.response.status === 403 || error.response.status === 401) {
            return axios.get(url + '/users/Listpub');
          }
        });
      }
      
      static async getUtilisateur(data) {
        return axios.get(url + '/portemonnaieelectronique/utilisateur/' + data).catch(async function (error) {
          if(error.response === undefined || error.response.status === 403 || error.response.status === 401) {
            return axios.get(url + '/users/utilisateur/' + data, {headers: header});
          }
        });
      }

      static async ModifierMotDePasse(id, password) {
        return axios.get(url + '/users/ModifierMotDePasse/' + id + '/' + password, {headers: header});
      }

      static async validation(id, value) {
        return axios.get(url + '/users/validation/' + id + '/' + value, {headers: header});
      }

      static async addAminCenter(centreId, IdUtilisateur) {
        return axios.get(url + '/users/addAminCenter/' + centreId + '/' + IdUtilisateur, {headers: header});
      }

      static async ListPub() {
        return axios.get(url + '/users/ListPub', {headers: header});
      }

      static async getAPIsList(value) {
        return axios.get(url + '/users/getAPIsList/'+value, {headers: header});
      }

      static async getAPIsUtilisateur(idUser) {
        return axios.get(url + '/users/getAPIsUtilisateur/'+idUser, {headers: header});
      }

      static async ValiderApi(idUser, idApi) {
        return axios.get(url + '/users/ValiderApi/'+idUser+"/"+idApi, {headers: header});
      }

      static async changeStatutApiUtilisateur(idApiUtilisateur, statut) {
        return axios.get(url + '/users/changeStatutApiUtilisateur/'+idApiUtilisateur+"/"+statut, {headers: header});
      }

      static async supprimer(idApiUtilisateur) {
        return axios.delete(url + '/users/supprimerApiUtilisateur/'+idApiUtilisateur, {headers: header});
      }

      static async supprimerApi(idApi) {
        return axios.delete(url + '/users/supprimerApi/'+idApi, {headers: header});
      }

      static async changeSenderStatut(value, idSmsSender) {
        return axios.get(url + '/users/changeSenderStatut/'+value+"/"+idSmsSender, {headers: header});
      }

      static async supprimerSender(idSmsSenderUtilisateur) {
        return axios.delete(url + '/users/supprimerSender/'+idSmsSenderUtilisateur, {headers: header});
      }

      static async supprimerGroupe(idGroupe) {
        return axios.delete(url + '/users/supprimerGroupe/'+idGroupe, {headers: header});
      }

      static async annulerTransaction(idTransaction) {
        return axios.get(url + '/users/annulerTransaction/'+idTransaction, {headers: header});
      }

      static async annulerTransactionSevob(idTransaction) {
        return axios.get(url + '/users/annulerTransactionSevob/'+idTransaction, {headers: header});
      }

      static async supprimerPub(id) {
        return axios.get(url + '/users/supprimerPub/' + id, {headers: header});
      }

      static async supprimerAdmin(IdUtilisateur) {
        return axios.get(url + '/users/supprimerAdmin/' + IdUtilisateur, {headers: header});
      }

      static connexion(token, nomUtilisateur) {
        const HEADERS = {'Authorization' : `Basic ${token}`};
        return axios.get(url + '/portemonnaieelectronique/login/'+nomUtilisateur, {headers: HEADERS});
      }

      static envoiCode(email, code) {
        return axios.post(url + '/anonyme/envoiCode', {email, code});
      }

      static envoiCodeInscription(email, code, nom) {
        return axios.post(url + '/anonyme/envoiCodeInscription', {email, code, nom});
      }

      static ModificationMotDePasse(email, motDePasse) {
        return axios.post(url + '/anonyme/modificationMotDePasse', {email, motDePasse}).catch(async function (error) {
          if(error.response === undefined || error.response.status === 403 || error.response.status === 401) {
            return axios.post(url + '/users/modificationMotDePasse', {email, motDePasse}, {headers: header});
          }
        });
        // return axios.post(url + '/anonyme/modificationMotDePasse', {email, motDePasse});
      }
      static ModificationBanqueMobile(data) {
        return axios.post(url + '/users/ModificationBanqueMobile', data, {headers: header});
      }
      static contacter(data) {
        return axios.post(url + '/anonyme/contacter', data).catch(async function (error) {
          if(error.response === undefined || error.response.status === 403 || error.response.status === 401) {
            return axios.post(url + '/users/contacter', data, {headers: header});
          }
        });
      }

}

export default Service;