import { Button, Grid, InputAdornment, LinearProgress, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Service from "../../service";

import { DatePicker, Select } from 'antd';

import { AccountBalanceWallet, BarChart, Edit, LibraryBooks, PieChart } from "@material-ui/icons";
import frFR from 'antd/es/date-picker/locale/fr_FR';
import moment from 'moment';
import 'moment/locale/fr';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Constant } from "../../constant";
const { RangePicker } = DatePicker;


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
let user = JSON.parse(localStorage.getItem('user'));
export default function GestionUtilisateur(props) {
  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  let telephone = "";
  let email = "";
  const [state, setState] = React.useState({
    telephone: "",
    email: "",
  });
  const navigate = useHistory();

  const columns = [
    {
      name: "id",
      label: "ID",
        options: {
          filter: true,
          sort: true,
         }
    },
    {
      name: "nom",
      label: "Nom",
        options: {
          filter: true,
          sort: true,
         }
    },
    {
      name: "telephone",
      label: "Téléphone",
        options: {
          filter: true,
          sort: true,
         }
    },
    {
      name: "email",
      label: "Email",
        options: {
          filter: true,
          sort: true,
         }
    },
    {
      name: "dateinscription",
      label: "Date d'ajout",
        options: {
          filter: true,
          sort: true,
         }
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let e = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
              {user.roles.some((a)=> a.name === Constant.ROLE_GESTION_UTILISATEUR_COMPTE || a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_GESTION_UTILISATEUR) &&
                <Button onClick={(element)=>{navigation("/app/Solde", `${e.id}2474&${e.nom}`)}} size="small" variant="contained" startIcon={<AccountBalanceWallet />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                  Compte
                </Button>
              }
              {user.roles.some((a)=> a.name === Constant.ROLE_GESTION_UTILISATEUR_API || a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_GESTION_UTILISATEUR) &&
                <Button onClick={(element)=>{navigation("/app/mesApis", `${e.id}2474&${e.nom}`)}} size="small" variant="contained" startIcon={<LibraryBooks />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                  API
                </Button>
              }
              {user.roles.some((a)=> a.name === Constant.ROLE_GESTION_UTILISATEUR_TRANSACTIONS || a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_GESTION_UTILISATEUR) &&
                <Button onClick={(element)=>{navigation("/app/mesTransactions", `${e.id}2474&${e.nom}`)}} size="small" variant="contained" startIcon={<PieChart />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                  Transactions
                </Button>
              }
              {user.roles.some((a)=> a.name === Constant.ROLE_GESTION_UTILISATEUR_TRANSACTIONS_TEST || a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_GESTION_UTILISATEUR) &&
                <Button onClick={(element)=>{navigation("/app/mesTransactionsTest", `${e.id}2474&${e.nom}`)}} size="small" variant="contained" startIcon={<BarChart />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                  Transactions Tests
                </Button>
              }
            </div>;
        }
      }
    }
  ];

  React.useEffect(() => {
    (async ()=> {
    if(props.match.params.data.split("2474&")[1] !== undefined){
      if((user.roles.some(e => e.name === "ROLE_EMPLOYE" || e.name === "ROLE_SEVOB_SUPER_ADMIN"))){
      } else {
        props.history.push("/app/Profil")
      }
    }
  })();
  }, []);

  const navigation = (path, data) => {
    navigate.push({
      pathname: `${path}/${data}`
    });
  }

  const periode = async (value) => {
    // await setEndDate(new Date());
    const date = new Date();
    if(value === "Journalière") {
    } else if(value === "Hebdomendaire") {
      await date.setDate(date.getDate() - 7);
    } else if(value === "Mensuel") {
      await date.setDate(date.getDate() - 30);
    } else if(value === "Trimestriel") {
      await date.setDate(date.getDate() - 90);
    } else if(value === "Annuelle") {
      await date.setDate(date.getDate() - 365);
    }
    historique(date, new Date())
  }


  const historique = async (date, endDate) => {
    setSpinner(true);
    endDate.setMonth(endDate.getMonth());
    date.setMonth(date.getMonth());
    let data = {
      // utilisateurid: localStorage.getItem('user').id,
      debut: date.getFullYear()+"-"+(date.getMonth() + 1)+"-"+date.getDate(),
      fin: endDate.getFullYear()+"-"+(endDate.getMonth() + 1)+"-"+endDate.getDate(),
    }

    if((props.match.params.data.split("2474&")[1] !== undefined)){
      data.idApi = parseInt(props.match.params.data.split("2474&")[0]);
    }
    Service.historiqueAllUtilisateur(data).then(
      async (response) => {
        if(response.data.data !== undefined) {
          setReponse(response.data.data.map((e)=>JSON.parse(e)));
        } else {
          alert(response.data)
        }
        setSpinner(false);
      }, error => {
          setSpinner(false);
          toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
      }
    )
}


function disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}
const onChange = (dates, dateStrings) => {
  historique(new Date(dateStrings[0]), new Date(dateStrings[1]))
  // this.setState({debut: dateStrings[0].split("-").reverse().join("-"), fin: dateStrings[1].split("-").reverse().join("-")})
}
const recherche = () => {
  setSpinner(true);
  Service.allUtilisateurRecherche(state).then(
    async (response) => {
      if(response.data.id !== undefined) {
        setReponse([response.data]);
      } else {
        alert(response.data)
      }
      setSpinner(false);
    }, error => {
        setSpinner(false);
    }
  )
}

  return (
    <>
      <ToastContainer />
      <PageTitle title={(props.match.params.data.split("2474&")[1] !== undefined) ? ("Gestion des utilisateurs de l'API : " + props.match.params.data.split("2474&")[1]) : "Gestion des utilisateurs"} />
      {user.roles.some((a)=> a.name === Constant.ROLE_SEVOB_SUPER_ADMIN || a.name === Constant.ROLE_GESTION_UTILISATEUR || a.name === Constant.ROLE_GESTION_UTILISATEUR_RECHERCHE) &&
        <Grid container spacing={4} style={{marginBottom: 20}}>
          <Grid item sm={2}>
            <strong>Par Période</strong>
            <br/>
            <Select
              style={{ width: "100%" }}
              onChange={(e)=>periode(e)}
              options={[
                { value: 'Journalière', label: 'Journalière' },
                { value: 'Hebdomendaire', label: 'Hebdomendaire' },
                { value: 'Mensuel', label: 'Mensuel' },
                { value: 'Trimestriel', label: 'Trimestriel' },
                { value: 'Annuelle', label: 'Annuelle' },
              ]}
            />
          </Grid>
          <Grid item sm={4}>
            <strong>Par intervalle de Date</strong>
            <br/>
            <RangePicker 
              disabledDate={disabledDate}
              onChange={onChange}
              // format="DD-MM-YYYY"
              style={{width: "100%"}}
              locale={frFR}
              ranges={{
                'Aujourd\'hui': [moment(), moment()],
                'Ce mois': [moment().startOf('month'), moment().endOf('month')],
              }}
              placeholder={["Du", "au"]}
            />
          </Grid>
          <Grid item sm={2}>
          <strong>Téléphone</strong>
            <br/>
            <TextField onChange={(e)=>setState({...state, telephone: e.target.value})} size="small" fullWidth style={{backgroundColor: 'white'}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Edit />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={2}>
            <strong>Email</strong>
            <br/>
            <TextField onChange={(e)=>setState({...state, email: e.target.value})} size="small" fullWidth style={{backgroundColor: 'white'}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Edit />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={2}>
            <Button variant="outlined" style={{backgroundColor: Constant.couleurPrimaire, color: 'white'}} onClick={(e)=>recherche()} disabled={spinner || (state.telephone.trim() === "" && state.email.trim() === "")}>
              Afficher
            </Button>
          </Grid>
        </Grid>
      }
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color="secondary" />}
          <MUIDataTable
            title="Listes des utilisateurs"
            data={reponse}
            columns={columns}
            // columns={["ID", "Nom", "Téléphone", "Email", "Date d'ajout"]}
            options={{
              filter: true,
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              textLabels: {
                body: {
                  noMatch: 'Rien à afficher pour le moment',
                }
              },
              selectableRows: "none"
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
