import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress, Modal, Paper, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Service from "../../service";

import { Button as ButtonAntd, DatePicker, Select, Steps, theme } from 'antd';

import { AccountBalanceWallet, Add, Cancel, Check, Code, Delete, Edit, FileCopy, GpsFixed, Settings, Sms } from "@material-ui/icons";
import 'moment/locale/fr';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from "react-router-dom";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IntegrationPaiement from '../../assets/Integration.pdf';
import IntegrationDataGps from '../../assets/IntegrationDataGps.pdf';
import IntegrationMapsGps from '../../assets/IntegrationMapsGps.pdf';
import IntegrationSMS from '../../assets/IntegrationSMS.pdf';
import cartemaps from "../../assets/cartemaps.png";
import Widget from "../../components/Widget/Widget";
import { Constant } from "../../constant";
import { AsRoles } from "../../verifyRoles";
import useStyles from "../dashboard/styles";
const { RangePicker } = DatePicker;

let user = JSON.parse(localStorage.getItem("user"));
export default function MesApis(props) {
  const classes = useStyles();

  const [spinner, setSpinner] = useState(false);
  const [reponse, setReponse] = useState([]);
  const [apivalide, setApiValide] = useState([]);
  const [apidisponible, setApiDisponible] = useState([]);
  var [openFacturation, setOpenFacturation] = useState(false);
  var [open, setOpen] = useState(false);
  var [openPaiment, setOpenPaiment] = useState(false);
  var [openSMS, setOpenSMS] = useState(false);
  var [openGps, setOpenGps] = useState({visible: false, type: ""});
  const [state, setState] = React.useState({
    id:0,
    type: "",
    montant: 0,
    duree: 0,
  });
  const [error, setError] = React.useState({
    type: false,
    montant: false,
  });
  var [cle, setCle] = useState("");
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const navigate = useHistory();
  const steps = [
    {
      title: 'Intégration',
    },
    {
      title: 'Définitions',
    },
    {
      title: 'Réponses',
    }
  ];
  const steps2 = [
    {
      title: 'Intégration',
    },
    {
      title: 'Définitions',
    }
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const items2 = steps2.map((item) => ({ key: item.title, title: item.title }));

  React.useEffect(() => {
    (async ()=> {
    if(props.match.params.data.split("2474&")[1] !== undefined){
      if((user.roles.some(e => e.name === "ROLE_EMPLOYE" || e.name === "ROLE_SEVOB_SUPER_ADMIN"))){
        await init();
      } else {
        props.history.push("/app/Profil")
      }
    } else {
      init()
    }
  })();

  }, []);

  const init = async () => {
    getAPIsList()
    getAPIsUtilisateur()
}

  const getAPIsList = async () => {
    Service.getAPIsList('true').then(
      async (response) => {
        setApiValide(response.data)
      })
}
  const OpenModal = async () => {
    setApiDisponible(apivalide.filter((e)=> props.match.params.data.split("2474&")[1] !== undefined || !e.idcode.includes("--")));
    setOpen(true)
    // let d = []
    // apivalide.forEach((api, index) => {
    //   if((props.match.params.data.split("2474&")[1] !== undefined && !reponse.some(elem => elem[8] === api.id)) || (!api.idcode.includes("--") && !reponse.some(elem => elem[8] === api.id))) {
    //     d.push(api);
    //   }
    //   if(apivalide.length === (index + 1)) {
    //     setApiDisponible(d);
    //     setOpen(true)
    //   }
    // })
}

  const changeStatut = async (idApiUtilisateur, statut) => {
    setSpinner(true)
    Service.changeStatutApiUtilisateur(idApiUtilisateur, statut).then(
      async (response) => {
        getAPIsUtilisateur();
        setSpinner(false)
        toast.success(response.data, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
    })
}

  const supprimer = async (idApiUtilisateur) => {
    setSpinner(true)
    Service.supprimer(idApiUtilisateur).then(
      async (response) => {
        getAPIsUtilisateur();
        setSpinner(false)
        toast.success(response.data, {position: "top-center", autoClose: 10000,})
      }, error => {
        setSpinner(false);
        toast.error("Impossible de faire de faire cette suppression. Car il a des enregistrements", {position: "top-center", autoClose: 10000,})
    })
}

  const openIntegration = async (idcode, cle, type) => {
    setCle(cle)
    setCurrent(0)
    if(idcode.includes(Constant.paiementweb)) {
      setOpenPaiment(true);
    } else if(idcode.includes(Constant.gpstrack)) {
      setOpenGps({...openGps, visible: true, type: type});
    } else if(idcode.includes(Constant.sms)) {
      setOpenSMS(true);
    }
}

  const getAPIsUtilisateur = async () => {
    Service.getAPIsUtilisateur((props.match.params.data.split("2474&")[1] !== undefined) ? parseInt(props.match.params.data.split("2474&")[0]) : user.id).then(
      async (response) => {
        if(typeof(response.data) !== "string"){
          setReponse(response.data);
        } else {
          alert(response.data)
          props.history.push("/app/Profil")
        }
      })
}

  const ValiderApi = async (idApi) => {
    setSpinner(true);
    Service.ValiderApi((props.match.params.data.split("2474&")[1] !== undefined) ? parseInt(props.match.params.data.split("2474&")[0]) : user.id, idApi).then(
      async (response) => {
        setSpinner(false);
        toast.success(response.data, {position: "top-center", autoClose: 10000,})
        getAPIsUtilisateur();
        setOpen(false)
      }, error => {
        setSpinner(false);
        toast.error("Erreur de connexion. Merci de nous contacter si l'erreur persiste", {position: "top-center", autoClose: 10000,})
    })

  }
  const onChangeV = (value) => {
    setCurrent(value);
  };

  const navigation = (path, data) => {
    navigate.push({
      pathname: `${path}/${data}`
    });
  }

const facturationModal = async (item) => {
  setState(item);
  setOpenFacturation(true)
}


  const columns = [
      {
        name: "cle",
        label: "CLE",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        label: "Code pour les tests",
        name: "codetest",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "api.nom",
        label: "Nom de l'api",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "api.description",
        label: "Description de l'api",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        label: "Facturaction",
        name: "id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let e = tableMeta.tableData[tableMeta.rowIndex];
            return <p>{e.montant + (e.type === "Pourcentage" ? "% par transaction" : " " + Constant.devis + " " + e.type)}</p>;
          }
         }
      },
      {
        name: "duree",
        label: "Durée",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        label: "Date d'ajout",
        name: "dateInscription",
        options: {
          filter: true,
          sort: true,
         }
      },
      {
        name: "statut",
        label: "Statut",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Chip label={value} classes={{root: value === "Activé" ? classes.success : classes.secondary}}/>;
          }
        }
      },
      {
        name: "id",
        label: "Action",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let d = tableMeta.tableData[tableMeta.rowIndex];
            return <div>
              {((props.match.params.data.split("2474&")[1] !== undefined) || (d.api.idcode !== undefined && !d.api.idcode.includes("--"))) &&
                <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm('Êtes-vous de faire cette suppression ?'))supprimer(d.id)}}><Delete/> Supprimer</IconButton>
              }
               <IconButton style={{color: d.statut === "Activé" ? 'red' : 'green', fontSize: 15}} onClick={(e)=> changeStatut(d.id, d.statut === "Activé" ? "Desactivé" : "Activé")}> {d.statut === "Activé" && <><Cancel /> Desactiver</>} {d.statut === "Desactivé" && <><Check /> Activer</> } </IconButton>
               
               {((props.match.params.data.split("2474&")[1] !== undefined) && AsRoles([Constant.ROLE_SEVOB_SUPER_ADMIN, Constant.ROLE_MODIFIER_TYPE_FACTURATION, Constant.ROLE_MODIFIER_FACTURATION]) === Constant.visible)  &&
                  <><br/><Button onClick={(e)=>{facturationModal(d)}} variant="contained" startIcon={<Edit />} style={{backgroundColor: 'red', color: Constant.textPrimaire, margin: 5}}>
                    Modifier la facturation
                  </Button></>
                }
               {(d.api.idcode !== undefined && d.api.idcode.includes(Constant.paiementweb)) &&
                  <><br/><Button onClick={(e)=>{openIntegration(d.api.idcode, d.cle, 'paiementweb')}} variant="contained" startIcon={<Code />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                    Intégrer l'api de paiement
                  </Button></>
                }

                {(d.api.idcode !== undefined && d.api.idcode.includes(Constant.gpstrack)) &&
                  <><br/><Button onClick={(e)=>{navigation("/app/MesGPS", `${d.cle}${(props.match.params.data.split("2474&")[1] !== undefined) ? ('2474&' + props.match.params.data.split("2474&")[1]) : ""}`)}} size="small" variant="contained" startIcon={<GpsFixed />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                    Gestion et localisation des GPS
                  </Button></>
                }

               {(d.api.idcode !== undefined && d.api.idcode.includes(Constant.gpstrack)) &&
                  <><br/><Button onClick={(e)=>{openIntegration(d.api.idcode, d.cle, 'carte')}} variant="contained" startIcon={<Code />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                    Intégrer la carte avec vos GPS
                  </Button></>
                }
               
               {(d.api.idcode !== undefined && d.api.idcode.includes(Constant.gpstrack)) &&
                  <><br/><Button onClick={(e)=>{openIntegration(d.api.idcode, d.cle, 'informations')}} variant="contained" startIcon={<Code />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                    Intégrer la récupération des informations des GPS
                  </Button></>
                }
               {(d.api.idcode !== undefined && d.api.idcode.includes(Constant.sms)) &&
                  <><br/><Button onClick={(e)=>{navigation("/app/GestionSMS", `${d.cle}${(props.match.params.data.split("2474&")[1] !== undefined) ? ('2474&' + props.match.params.data.split("2474&")[1]) : ""}`)}} variant="contained" startIcon={<Sms />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                    Gestion de mes SMS
                  </Button></>
                }
               {(d.api.idcode !== undefined && d.api.idcode.includes(Constant.sms)) &&
                  <><br/><Button onClick={(e)=>{openIntegration(d.api.idcode, d.cle, 'sms')}} variant="contained" startIcon={<Code />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                    Intégrer l'envoi des SMS
                  </Button></>
                }
               {(d.api.idcode !== undefined && d.api.idcode.includes(Constant.qrcode)) &&
                  <><br/><Button onClick={(e)=>{navigation("/app/GestionQrCode", `${d.cle}${(props.match.params.data.split("2474&")[1] !== undefined) ? ('2474&' + props.match.params.data.split("2474&")[1]) : ""}`)}} variant="contained" startIcon={<Settings />} style={{backgroundColor: Constant.couleurPrimaire, color: Constant.textPrimaire, margin: 5}}>
                    Qr code personnalisé
                  </Button></>
                }
               
              </div>;
          }
        }
      }
    ];

    const contentStyle: React.CSSProperties = {
      color: token.colorTextTertiary,
      backgroundColor: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      border: `1px dashed ${token.colorBorder}`,
      marginTop: 16,
      padding: 20
    };

    const codeString = "let body = {\n"+
      "'mode': '',\n"+
      `'cle': '${cle}',\n`+
      "'nom_entreprise': '',\n"+
      "'lien_logo_entreprise': '',\n"+
      "'montant': 0,\n"+
      "'commande_id': '',\n"+
      "'autre_information': '',\n"+
      "'return_url': '',\n"+
      "'cancel_url': '',\n"+
      "'success_url': '',\n"+
      "'error_url': '',\n"+
    "}";
    const codeGPSCarteString = "let body = {\n"+
      "'mode': 'GPSMAPS',\n"+
      `'cle': '${cle}',\n`+
      "'nom_entreprise': '',\n"+
    "}";
    const codeSMSString = `{
      "cle": ${cle},
      "mode": "SMS",
      "nom_a_afficher": "",
      "numeros": "",
      "message": ""
  }`
    const codeGPSdataString = "let body = {\n"+
      "'mode': 'GPSDATA',\n"+
      `'cle': '${cle}',\n`+
    "}";

    const codeString2 = "curl -X POST \\ \n"+
    '-H "Content-Type: application/json" \\ \n'+
    '-H "Accept: application/json" \\ \n'+
    '-d body \\ \n'+
    'https://api.sevob.net';

    const codeString3 = "{\n"+
      'code: "400",\n'+
      'message: "Le message d\'erreur sera ici"\n'+
      "}";

    const codeString5 = "{\n"+
      'code: "200",\n'+
      'message: "Le message de confirmation sera ici"\n'+
      "}";

    const codeString4 = "{\n"+
      'code: "200",\n'+
      'url: "https://paiementenligne.sevob.net?c=7e2ad3bf-930d-484e-85e1-e1acs33f683201A"\n'+
      "}";

    const codeGPSCarteString4 = "{\n"+
      'code: "200",\n'+
      'url: "https://gps.sevob.net/#/maps/107g/?c=053bb64b-0c2c-4dbd-8136-235ba6830080107g"\n'+
      "}";

      let d = `[
        {
          "id": 5,
          "attributes": {
              "deviceImage": "device.jpeg"
          },
          "groupId": 1,
          "calendarId": 0,
          "name": "Idrissa Diarra (72-20-81-20)",
          "uniqueId": "8648950360760139",
          "status": "offline",
          "lastUpdate": 1707428131000,
          "positionId": 846927,
          "phone": "72208120",
          "model": "TK103",
          "contact": null,
          "category": "motorcycle",
          "disabled": false,
          "expirationTime": null,
          "lastPosition": [
              {
                  "id": 846927,
                  "attributes": {
                      "distance": 5.6769358912000065,
                      "totalDistance": 8187508.987463549,
                      "motion": false
                  },
                  "deviceId": 5,
                  "protocol": "gps103",
                  "serverTime": "2024-02-08T21:35:26.000+00:00",
                  "deviceTime": "2024-02-08T21:35:24.000+00:00",
                  "fixTime": "2024-02-08T21:35:24.000+00:00",
                  "outdated": false,
                  "valid": true,
                  "latitude": 12.5827885,
                  "longitude": -7.974141833333333,
                  "altitude": 0.0,
                  "speed": 0.0,
                  "course": 261.35,
                  "address": null,
                  "accuracy": 0.0,
                  "network": null,
                  "geofenceIds": null
              }
          ]
      }
      ]`
    const codeGPSDATAString4 = "{\n"+
      'code: "200",\n'+
      'data: ' + d +
      "}";
      

    const codeStringReponse = "{\n"+
    "'etat': '',\n"+
    "'typepaiement': '',\n"+
    "'montant': '',\n"+
    "'nomentreprise': '',\n"+
    "'autreinformation': '',\n"+
    "}\n";

    const codeGPSDataReponse = `[{
        "id": 19,
        "imei": "864895016074806",
        "phoneNumber": "93363310",
        "password": null,
        "etat": "FONCTIONNEL",
        "vehicleType": "Moto",
        "vehicleID": null,
        "appartenance": "SEVOB",
        "cle": 107,
        "nomgps": "tvs 009",
        "descriptiongps": "test 9",
        "lastCoordinate": {
            "id": 43033962,
            "action": "tracker",
            "dateTime": "2023-11-27 18:13:56",
            "rfid": null,
            "time": "18:13:56",
            "latitude": 12.643440166666668,
            "longitude": -8.018156000000001,
            "speed": 0,
            "direction": 355.15,
            "altitude": null,
            "acc": null,
            "etatPorte": null,
            "reservoir1": null,
            "reservoir2": null,
            "temperature": null
        }
    }]`;

    const cliquer = async () => {
      if(state.type.trim() === ""){
        setError({...error, type: true})
      } else if(state.montant <= 0){
        setError({...error, montant: true, type: false})
      } else {
        setError({...error, montant: false, type: false})
          setSpinner(true);
          Service.ModifierApiUtilisateur(state).then(
            async (response) => {
              setSpinner(false);
              setOpenFacturation(false)
              toast.success("Modification effectuée avec succès", {position: "top-center", autoClose: 10000,})
              getAPIsUtilisateur()
            }, error => {
                setSpinner(false);
                toast.error("Erreur de modification. Merci d'appeler l'administrateur si l'erreur persiste", {position: "top-center", autoClose: 10000,})
            }
          )
      }
  }

  return (
    <>
    {/* <iframe
  id="inlineFrameExample"
  title="Inline Frame Example"
  width="100%"
  height="100%"
  src="https://faba-holding-groupe.net/#/">
</iframe> */}
      <ToastContainer />
      <PageTitle title={(props.match.params.data.split("2474&")[1] !== undefined) ? ("Gestion des APIs de " + props.match.params.data.split("2474&")[1]) : "Gestion de mes APIs"} />
      <Grid container spacing={4} style={{marginBottom: 20}}>
        <Grid item xs={12}>
          <Button onClick={(e)=>{OpenModal()}} variant="outlined" startIcon={<Add />} style={{color: 'green', borderColor: 'green'}}>
            {(props.match.params.data.split("2474&")[1] !== undefined) ? ("Ajouter un API au compte de " + props.match.params.data.split("2474&")[1]) : "Ajouter un API"}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {spinner && <LinearProgress color="secondary" />}
          <MUIDataTable
            title={(props.match.params.data.split("2474&")[1] !== undefined) ? ("Liste des APIs de " + props.match.params.data.split("2474&")[1]) : "Liste de mes APIs"}
            data={reponse}
            columns={columns}
            options={{
              filter: true,
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              textLabels: {
                body: {
                  noMatch: 'Rien à afficher pour le moment',
                }
              },
              selectableRows: "none"
            }}
            
          />
        </Grid>
      </Grid>
{/* Add APIS */}
      <Modal
        open={open}
        onClose={(e)=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{zIndex: 1200, overflow: 'scroll'}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item sm={2}>
              </Grid>
              <Grid item sm={8}>
                <Paper square  style={{marginTop: 50, padding: 50, paddingTop: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Page d'ajout d'un API
                    </h1>
                    {spinner && <LinearProgress color="secondary" />}
                    <Widget title="Listes des APIs disponibles" upperTitle noBodyPadding bodyClass={classes.tableWidget}>
                      {apidisponible.length > 0 && 
                      <Table className="mb-0">
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Nom</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Facturation</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {apidisponible.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{item.nom}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.montant}{item.type === "Pourcentage" ? "% par transaction" : " " + Constant.devis + " " + item.type}</TableCell>
                                <TableCell> <IconButton style={{color: 'green', fontSize: 20}} onClick={(e)=> ValiderApi(item.id)}> <Check /> Valider</IconButton></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      }
                    </Widget>
                      <br/>
                    <Button variant="outlined" color="default" onClick={(e)=>setOpen(false)}>
                      Fermer
                    </Button>
                </Paper>
              </Grid>
              <Grid item sm={2}>
              </Grid>
            </Grid>

        </div>
      </Modal>
{/* Integration API Paiement */}
      <Modal
            open={openPaiment}
            onClose={(e)=>setOpenPaiment(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{overflow: 'scroll', zIndex: 1200}}
          >
            <Grid container spacing={3}>
                <Grid item lg={2}>
                </Grid>
                <Grid item lg={9}>
                    <Paper square  style={{padding: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginTop: 50}}>
                        <br/>
                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'red', borderColor: 'red'}}>
                          <a href={IntegrationPaiement} target="_blank" download>Télécharger la version PDF</a>
                        </Button>
                        <br/><br/><br/>
                        <Steps current={current} items={items} onChange={onChangeV}/>
                          <div style={contentStyle}>
                          {/* Integration */}
                            {current === 0 &&
                              <Grid container spacing={3}>
                                <Grid item lg={12}>
                                  <h3><strong style={{fontSize: 20}}>🟠</strong> Le code ci-dessous est un objet (JSON) qui a été conçu en utilisant Javascript. Modifiez-le en fonction de votre langage de programmation. Dans l'étape <strong style={{color: 'red'}}>Définitions</strong>, vous obtiendrez toutes les informations sur chaque élément.</h3>
                                </Grid>
                                <Grid item lg={12}>
                                    <CopyToClipboard text={codeString} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={12}>
                                  <hr/>
                                  <h3>
                                    <strong style={{fontSize: 20}}>🟠</strong> Pour demander le lien de l'API de paiement, utilisez le lien ci-dessous avec la méthode <strong style={{color: 'red'}}>POST</strong>. Si tout se passe bien, vous recevrez l'<strong style={{color: 'red'}}>URL</strong> pour afficher l'interface de l'api dans un navigateur web.
                                    <br/>
                                    <br/>
                                    <strong style={{color: 'red', fontSize: 20, textDecorationLine: 'underline'}}>Attention:</strong> N'oubliez pas d'envoyer l'objet (JSON) ci-dessus ☝🏿 que vous avez adapté à votre langage de programmation dans la méthode <strong style={{color: 'red'}}>POST</strong>.
                                  </h3>
                                </Grid>
                                <Grid item lg={12}>
                                    <CopyToClipboard text={codeString2} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString2}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={12}>
                                <hr/>
                                  <h3>
                                    <strong style={{fontSize: 20}}>🟠</strong> - Dans le cas où vous commettez une erreur, la réponse que vous obtiendrez contiendra un attribut code qui est 400 et un attribut message qui est le message d'erreur rencontré.
                                    <br/>
                                    - Mais si tout est bon, la réponse que vous obtiendrez contiendra un attribut code qui est 200 et un attribut <strong style={{color: 'red'}}>url</strong> pour afficher l'interface de paiement.
                                    <br/>
                                    <br/>
                                    <strong style={{color: 'red', fontSize: 20, textDecorationLine: 'underline'}}>NB:</strong> Assurez-vous d'ouvrir l'<strong style={{color: 'red'}}>URL</strong> que vous avez obtenu dans un navigateur web. L'<strong style={{color: 'red'}}>URL</strong> que vous allez obtenir n'est utilisable qu'une seule fois.
                                  
                                  </h3>
                                </Grid>
                                <Grid item lg={6}>
                                    <h3 style={{color: 'blue'}}>En cas d'erreur</h3>
                                    <CopyToClipboard text={codeString3} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString3}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={6}>
                                    <h3 style={{color: 'blue'}}>En cas de succès</h3>
                                    <CopyToClipboard text={codeString4} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString4}
                                    </SyntaxHighlighter>
                                </Grid>
                              </Grid>
                            }
                          {/* Définitions */}
                            {current === 1 &&
                              <Grid container spacing={3}>
                                <Grid item sm={12}>
                                  <Widget title="Définition des variables" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                                    <Table className="mb-0">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Variable</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Obligatoire</TableCell>
                                            <TableCell>A éviter</TableCell>
                                            <TableCell>Taille</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {/* mode */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">1</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>mode</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                Elle prend deux valeurs:<br/>
                                                <strong>TEST</strong> : Pour faire des tests de paiement et vérifier que l'API marche.<br/>
                                                <strong>REEL</strong> : Pour passer en mode production et recevoir les paiement réel.
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell>Autres valeurs que <strong>TEST</strong> et <strong>REEL</strong></TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          {/* nom_entreprise */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">2</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>nom_entreprise</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est le nom de l'entreprise où le paiement sera fait. Cette variable est très utile pour les statistiques par entreprise au niveau de vos transactions
                                              </TableCell>
                                              <TableCell>Oui, peut être ""</TableCell>
                                              <TableCell></TableCell>
                                              <TableCell>150 caractères au maximum</TableCell>
                                            </TableRow>
                                          {/* lien_logo_entreprise */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">3</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>lien_logo_entreprise</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est le lien du logo de l'entreprise où le paiement sera fait.
                                              </TableCell>
                                              <TableCell>Oui, peut être ""</TableCell>
                                              <TableCell>Mauvaise URL</TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          {/* montant */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">4</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>montant</strong></TableCell>
                                              <TableCell>Double | Decimal | Int</TableCell>
                                              <TableCell>
                                                C'est le montant de la transaction
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell> Supérieur à 0 {Constant.devis}</TableCell>
                                            </TableRow>
                                          {/* commande_id */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">5</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>commande_id</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est le numéro de la commande. Très utile pour la traçabilité.
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell>Les doublons</TableCell>
                                              <TableCell>25 caractères au maximum</TableCell>
                                            </TableRow>
                                          {/* autre_information */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">6</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>autre_information</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est tout informons dont vous souhaitez recevoir à la fin de la transaction.
                                              </TableCell>
                                              <TableCell>Oui, peut être ""</TableCell>
                                              <TableCell></TableCell>
                                              <TableCell>250 caractères au maximum</TableCell>
                                            </TableRow>
                                          {/* return_url */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">7</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>return_url</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                Ce lien sera affiché quand l'utilisateur voudra retourner en arrière
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell>Mauvaise URL ou le lien localhost</TableCell>
                                              <TableCell>100 caractères au maximum</TableCell>
                                            </TableRow>
                                          {/* cancel_url */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">8</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>cancel_url</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                Ce lien sera affiché quand l'utilisateur annulera la transaction
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell>Mauvaise URL ou le lien localhost</TableCell>
                                              <TableCell>100 caractères au maximum</TableCell>
                                            </TableRow>
                                          {/* success_url */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">9</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>success_url</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                Ce lien sera appelé en arrière plan quand la transaction sera faite avec succès
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell>Mauvaise URL ou le lien localhost</TableCell>
                                              <TableCell>100 caractères au maximum</TableCell>
                                            </TableRow>
                                          {/* error_url */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">10</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>error_url</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                Ce lien sera appelé en arrière plan quand il y'aura une erreur lors de la transaction
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell>Mauvaise URL ou le lien localhost</TableCell>
                                              <TableCell>100 caractères au maximum</TableCell>
                                            </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Widget>
                                </Grid>
                              </Grid>
                            }
                          {/* Réponses */}
                            {current === 2 &&
                              <Grid container spacing={3}>
                                <Grid item sm={12}>
                                  <h3>Des données sont envoyés sur vos différents URL (success_url, error_url) en cas de réussite ou échec de la transaction.</h3>
                                  <h3>C'est données sont envoyés en JSON avec la méthode <strong style={{color: 'red'}}>POST</strong>.</h3>
                                </Grid>
                                <Grid item lg={12}>
                                    <CopyToClipboard text={codeStringReponse} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeStringReponse}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item sm={12}>
                                  <Widget title="Définition des variables" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                                    <Table className="mb-0">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Variable</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Nos conseils</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {/* etat */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">1</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>etat</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                Elle prend deux valeurs:<br/>
                                                <strong>Transaction effectuée</strong> : quand la transaction est faite avec succès.<br/>
                                                <strong>Transaction annulée</strong> : quand la transaction a echoué.
                                              </TableCell>
                                              <TableCell>Vérifier toujours l'etat avant de valider ou d'annuler la transaction dans votre base de données</TableCell>
                                            </TableRow>
                                          {/* typepaiement */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">2</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>typepaiement</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est le type de paiement choisi par l'utilisateur pour payer la transaction
                                              </TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          {/* montant */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">3</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>montant</strong></TableCell>
                                              <TableCell>Double</TableCell>
                                              <TableCell>
                                                C'est le montant de la transaction qui à été payé
                                              </TableCell>
                                              <TableCell>
                                                Comparer toujours le montant reçu avec le montant envoyé avant de valider la transaction dans votre base de données
                                              </TableCell>
                                            </TableRow>
                                          {/* nomentreprise */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">4</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>nomentreprise</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est le nom de l'entreprise où le paiement a été fait
                                              </TableCell>
                                              <TableCell>
                                                
                                              </TableCell>
                                            </TableRow>
                                          {/* autreinformation */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">5</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>autreinformation</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                Ce sont les informations que vous voulez recevoir à la fin de la transaction
                                              </TableCell>
                                              <TableCell>
                                                
                                              </TableCell>
                                            </TableRow>
                                          
                                        </TableBody>
                                      </Table>
                                    </Widget>
                                </Grid>
                              </Grid>
                            }
                          </div>

                          <div style={{ marginTop: 24 }}>
                            {current > 0 && (
                              <ButtonAntd style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Précédent
                              </ButtonAntd>
                            )}
                            {current < steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => next()}>
                                Suivant
                              </ButtonAntd>
                            )}
                            {current === steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => setOpenPaiment(false)}>
                                Terminé
                              </ButtonAntd>
                            )}
                            <ButtonAntd style={{ margin: '0 8px', float: 'right' }} onClick={() => setOpenPaiment(false)}>
                                Fermer
                              </ButtonAntd>
                          </div>
                    </Paper>
                </Grid>
                <Grid item lg={1}>
                </Grid>
            </Grid>
          </Modal>
{/* Integration carte GPS Track */}
      <Modal
            open={(openGps.visible && openGps.type === "carte")}
            onClose={(e)=>setOpenGps({...openGps, visible: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{overflow: 'scroll', zIndex: 1200}}
          >
            <Grid container spacing={3}>
                <Grid item lg={2}>
                </Grid>
                <Grid item lg={9}>
                    <Paper square  style={{padding: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginTop: 50}}>
                        <br/>
                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'red', borderColor: 'red'}}>
                          <a href={IntegrationMapsGps} target="_blank" download>Télécharger la version PDF</a>
                        </Button>
                        <br/><br/><br/>
                        <Steps current={current} items={items} onChange={onChangeV}/>
                          <div style={contentStyle}>
                          {/* Integration */}
                            {current === 0 &&
                              <Grid container spacing={3}>
                                <Grid item lg={12}>
                                  <h3><strong style={{fontSize: 20}}>🟠</strong> Le code ci-dessous est un objet (JSON) qui a été conçu en utilisant Javascript. Modifiez-le en fonction de votre langage de programmation. Dans l'étape <strong style={{color: 'red'}}>Définitions</strong>, vous obtiendrez toutes les informations sur chaque élément.</h3>
                                </Grid>
                                <Grid item lg={12}>
                                    <CopyToClipboard text={codeGPSCarteString} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeGPSCarteString}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={12}>
                                  <hr/>
                                  <h3>
                                    <strong style={{fontSize: 20}}>🟠</strong> Pour demander le lien de la carte avec tous vos Gps, utilisez le lien ci-dessous avec la méthode <strong style={{color: 'red'}}>POST</strong>. Si tout se passe bien, vous recevrez l'<strong style={{color: 'red'}}>URL</strong> pour afficher la carte de l'api dans un navigateur web.
                                    <br/>
                                    <strong style={{color: 'green'}}>Vous pouvez même utiliser un <strong style={{color: 'red'}}>iframe</strong> pour l'intégrer dans vos interfaces</strong>
                                    <br/>
                                    <br/>
                                    <strong style={{color: 'red', fontSize: 20, textDecorationLine: 'underline'}}>Attention:</strong> N'oubliez pas d'envoyer l'objet (JSON) ci-dessus ☝🏿 que vous avez adapté à votre langage de programmation dans la méthode <strong style={{color: 'red'}}>POST</strong>.
                                  </h3>
                                </Grid>
                                <Grid item lg={12}>
                                    <CopyToClipboard text={codeString2} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString2}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={12}>
                                <hr/>
                                  <h3>
                                    <strong style={{fontSize: 20}}>🟠</strong> - Dans le cas où vous commettez une erreur, la réponse que vous obtiendrez contiendra un attribut code qui est 400 et un attribut message qui est le message d'erreur rencontré.
                                    <br/>
                                    - Mais si tout est bon, la réponse que vous obtiendrez contiendra un attribut code qui est 200 et un attribut <strong style={{color: 'red'}}>url</strong> pour afficher la carte.
                                    <br/>
                                    <br/>
                                    <strong style={{color: 'red', fontSize: 20, textDecorationLine: 'underline'}}>NB:</strong> Assurez-vous d'ouvrir l'<strong style={{color: 'red'}}>URL</strong> que vous avez obtenu dans un navigateur web. L'<strong style={{color: 'red'}}>URL</strong> que vous allez obtenir n'est utilisable qu'une seule fois.
                                    <br/>
                                    <strong style={{color: 'green'}}>Vous pouvez même utiliser un <strong style={{color: 'red'}}>iframe</strong> pour l'intégrer dans vos interfaces</strong>
                                  </h3>
                                </Grid>
                                <Grid item lg={6}>
                                    <h3 style={{color: 'blue'}}>En cas d'erreur</h3>
                                    <CopyToClipboard text={codeString3} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString3}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={6}>
                                    <h3 style={{color: 'blue'}}>En cas de succès</h3>
                                    <CopyToClipboard text={codeGPSCarteString4} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeGPSCarteString4}
                                    </SyntaxHighlighter>
                                </Grid>
                              </Grid>
                            }
                          {/* Définitions */}
                            {current === 1 &&
                              <Grid container spacing={3}>
                                <Grid item sm={12}>
                                  <Widget title="Définition des variables" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                                    <Table className="mb-0">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Variable</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Obligatoire</TableCell>
                                            <TableCell>A éviter</TableCell>
                                            <TableCell>Taille</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {/* nom_entreprise */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">1</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>nom_entreprise</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est le nom que vous souhaitez afficher au niveau de l'interface de la carte.
                                              </TableCell>
                                              <TableCell>Oui, peut être ""</TableCell>
                                              <TableCell></TableCell>
                                              <TableCell>150 caractères au maximum</TableCell>
                                            </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Widget>
                                </Grid>
                              </Grid>
                            }
                          {/* Réponses */}
                            {current === 2 &&
                              <Grid container spacing={3}>
                                <Grid item sm={12}>
                                  <h3>Si vous réussissez, vous aurez une page similaire à l'image ci-dessous</h3>
                                </Grid>
                                <Grid item lg={12}>
                                  <img src={cartemaps} alt="Carte Maps"  style={{height: "100%", width: "100%"}}/>
                                </Grid>
                              </Grid>
                            }
                          </div>

                          <div style={{ marginTop: 24 }}>
                            {current > 0 && (
                              <ButtonAntd style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Précédent
                              </ButtonAntd>
                            )}
                            {current < steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => next()}>
                                Suivant
                              </ButtonAntd>
                            )}
                            {current === steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => setOpenGps({...openGps, visible: false})}>
                                Terminé
                              </ButtonAntd>
                            )}
                            <ButtonAntd style={{ margin: '0 8px', float: 'right' }} onClick={() => setOpenGps({...openGps, visible: false})}>
                                Fermer
                              </ButtonAntd>
                          </div>
                    </Paper>
                </Grid>
                <Grid item lg={1}>
                </Grid>
            </Grid>
          </Modal>
{/* Integration data GPS Track */}
      <Modal
            open={(openGps.visible && openGps.type === "informations")}
            onClose={(e)=>setOpenGps({...openGps, visible: false})}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{overflow: 'scroll', zIndex: 1200}}
          >
            <Grid container spacing={3}>
                <Grid item lg={2}>
                </Grid>
                <Grid item lg={9}>
                    <Paper square  style={{padding: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginTop: 50}}>
                        <br/>
                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'red', borderColor: 'red'}}>
                          <a href={IntegrationDataGps} target="_blank" download>Télécharger la version PDF</a>
                        </Button>
                        <br/><br/><br/>
                        {/* <Steps current={current} items={items} onChange={onChangeV}/> */}
                          <div style={contentStyle}>
                          {/* Integration */}
                            {/* {current === 0 && */}
                              <Grid container spacing={3}>
                                <Grid item lg={12}>
                                  <h3><strong style={{fontSize: 20}}>🟠</strong> Le code ci-dessous est un objet (JSON) qui a été conçu en utilisant Javascript. Modifiez-le en fonction de votre langage de programmation. Dans l'étape <strong style={{color: 'red'}}>Définitions</strong>, vous obtiendrez toutes les informations sur chaque élément.</h3>
                                </Grid>
                                <Grid item lg={12}>
                                    <CopyToClipboard text={codeGPSdataString} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeGPSdataString}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={12}>
                                  <hr/>
                                  <h3>
                                    <strong style={{fontSize: 20}}>🟠</strong> Pour avoir les informations sur vos Gps, utilisez le lien ci-dessous avec la méthode <strong style={{color: 'red'}}>POST</strong>. Si tout se passe bien, vous recevrez un élément <strong style={{color: 'red'}}>data</strong> dans la réponse qui est <strong style={{color: 'red'}}>une liste</strong>.
                                    <br/>
                                    <br/>
                                    <strong style={{color: 'green', fontSize: 20, textDecorationLine: 'underline'}}>NB:</strong> Vous pouvez créer une boucle qui appelle le lien toutes les 10 secondes pour suivre en temps réel la position de vos véhicules..<br/> <strong style={{color: 'red'}}>Ex: </strong> setInterval.
                                    <br/>
                                    <br/>
                                    <strong style={{color: 'red', fontSize: 20, textDecorationLine: 'underline'}}>Attention:</strong> N'oubliez pas d'envoyer l'objet (JSON) ci-dessus ☝🏿 que vous avez adapté à votre langage de programmation dans la méthode <strong style={{color: 'red'}}>POST</strong>.
                                  </h3>
                                </Grid>
                                <Grid item lg={12}>
                                    <CopyToClipboard text={codeString2} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString2}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={12}>
                                <hr/>
                                  <h3>
                                    <strong style={{fontSize: 20}}>🟠</strong> - Dans le cas où vous commettez une erreur, la réponse que vous obtiendrez contiendra un attribut code qui est 400 et un attribut message qui est le message d'erreur rencontré.
                                    <br/>
                                    - Mais si tout est bon, la réponse que vous obtiendrez contiendra un attribut code qui est 200 et un attribut <strong style={{color: 'red'}}>data</strong> qui contient les informations de vos gps.
                                  </h3>
                                </Grid>
                                <Grid item lg={6}>
                                    <h3 style={{color: 'blue'}}>En cas de succès</h3>
                                    <CopyToClipboard text={codeGPSDATAString4} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeGPSDATAString4}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={6}>
                                    <h3 style={{color: 'blue'}}>En cas d'erreur</h3>
                                    <CopyToClipboard text={codeString3} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString3}
                                    </SyntaxHighlighter>
                                </Grid>
                              </Grid>
                            {/* } */}
                          {/* Définitions */}
                            {current === 1 &&
                              <Grid container spacing={3}>
                                <Grid item sm={12}>
                                  <Widget title="Définition des variables" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                                    <Table className="mb-0">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Variable</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Obligatoire</TableCell>
                                            <TableCell>A éviter</TableCell>
                                            <TableCell>Taille</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {/* vehicleType */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">1</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>vehicleType</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est pour afficher les gps par type de véhicule.<br/>
                                                <strong style={{color: 'red'}}>Valeurs possibles: </strong> 
                                                <strong>Moto</strong>, 
                                                <strong>Voiture</strong>, 
                                                <strong>Camion</strong>, 
                                                <strong>Bateau</strong>, 
                                              </TableCell>
                                              <TableCell>Non, peut être enlever</TableCell>
                                              <TableCell>vehicleType: ""</TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          {/* etat */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">2</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>etat</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est pour afficher les gps par leur état.<br/>
                                                <strong style={{color: 'red'}}>Valeurs possibles: </strong> <br/>
                                                <strong>CONFIGURED</strong>, signification: Configuré <br/>
                                                <strong>INSTALLED</strong>, signification: Installé <br/>
                                                <strong>FONCTIONNEL</strong>, signification: Fonctionnel <br/>
                                                <strong>DAMAGED</strong>, signification: Non fonctionnel <br/>
                                                <strong>LOSE</strong>, signification: Perdu 
                                              </TableCell>
                                              <TableCell>Non, peut être enlever</TableCell>
                                              <TableCell>etat: ""</TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          {/* phoneNumber */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">3</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>phoneNumber</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est pour afficher le gps par le numéro de la puce qui est dedans.
                                              </TableCell>
                                              <TableCell>Non, peut être enlever</TableCell>
                                              <TableCell>phoneNumber: ""</TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          {/* imei */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">4</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>imei</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est pour afficher le gps par son identifiant imei.
                                              </TableCell>
                                              <TableCell>Non, peut être enlever</TableCell>
                                              <TableCell>imei: ""</TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Widget>
                                </Grid>
                              </Grid>
                            }
                          {/* Réponses */}
                            {current === 2 &&
                              <Grid container spacing={3}>
                              <Grid item sm={12}>
                                <h3>Les données du gps ressemblent à ça une fois convertie en Objet (JSON) : </h3>
                              </Grid>
                              <Grid item lg={12}>
                                  <CopyToClipboard text={codeGPSDataReponse} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                      <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                        Cliquez-ici pour copier
                                      </Button>
                                  </CopyToClipboard>
                                  <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                      {codeGPSDataReponse}
                                  </SyntaxHighlighter>
                              </Grid>
                              <Grid item sm={12}>
                                <Widget title="Définition des variables" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                                  <Table className="mb-0">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>#</TableCell>
                                          <TableCell>Variable</TableCell>
                                          <TableCell>Type</TableCell>
                                          <TableCell>Description</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {/* imei */}
                                          <TableRow>
                                            <TableCell className="pl-3 fw-normal">1</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>imei</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              L'identifiant du GPS. Il permet d'identifier le gps de façon unique
                                            </TableCell>
                                          </TableRow>
                                        {/* phoneNumber */}
                                          <TableRow>
                                            <TableCell className="pl-3 fw-normal">2</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>phoneNumber</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              C'est le numéro de la puce qui se trouve dans le gps
                                            </TableCell>
                                          </TableRow>
                                        {/* etat */}
                                          <TableRow>
                                            <TableCell className="pl-3 fw-normal">3</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>etat</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                                Il s'agit de l'état actuel du gps.<br/>
                                                  <strong style={{color: 'red'}}>Valeurs possibles: </strong> <br/>
                                                  <strong>CONFIGURED</strong>, signification: Configuré <br/>
                                                  <strong>INSTALLED</strong>, signification: Installé <br/>
                                                  <strong>FONCTIONNEL</strong>, signification: Fonctionnel <br/>
                                                  <strong>DAMAGED</strong>, signification: Non fonctionnel <br/>
                                                  <strong>LOSE</strong>, signification: Perdu 
                                            </TableCell>
                                          </TableRow>
                                        {/* vehicleType */}
                                          <TableRow>
                                            <TableCell className="pl-3 fw-normal">4</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>vehicleType</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              Il s'agit du type de véhicule dans lequel le gps est intégré<br/>
                                                <strong style={{color: 'red'}}>Valeurs possibles: </strong> 
                                                <strong>Moto</strong>, 
                                                <strong>Voiture</strong>, 
                                                <strong>Camion</strong>, 
                                                <strong>Bateau</strong>, 
                                            </TableCell>
                                          </TableRow>
                                        {/* nomgps */}
                                          <TableRow>
                                            <TableCell className="pl-3 fw-normal">5</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>nomgps</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              C'est le nom que vous avez donné au gps dans la partie gestion des gps
                                            </TableCell>
                                          </TableRow>
                                        {/* descriptiongps */}
                                          <TableRow>
                                            <TableCell className="pl-3 fw-normal">6</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>descriptiongps</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              C'est la descrition que vous avez donné au gps dans la partie gestion des gps.<br/>
                                              <strong style={{color: Constant.couleurSecondaire}}>Ex:</strong> le nom et numéro de téléphone de celui qui conduit le véhicule en question
                                            </TableCell>
                                          </TableRow>
                                        {/* lastCoordinate */}
                                          <TableRow>
                                            <TableCell className="pl-3 fw-normal">7</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>lastCoordinate</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              C'est objet (JSON) qui contient les informations sur la dernière position du gps.
                                            </TableCell>
                                          </TableRow>
                                        {/* lastCoordinate */}
                                          <TableRow>
                                            <TableCell className="pl-3 fw-normal">8</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>lastCoordinate</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              C'est objet (JSON) qui contient les informations sur la dernière position du gps.
                                            </TableCell>
                                          </TableRow>
                                        {/* action */}
                                          <TableRow>
                                            <TableCell className="pl-3 fw-normal">9</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>action</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                                C'est le message envoyé par le gps<br/>
                                                  <strong style={{color: 'red'}}>Valeurs possibles: </strong> <br/>
                                                  <strong>tracker</strong>, signification: Position actuelle du gps <br/>
                                                  <strong>help me</strong>, signification: Appel d'urgence <br/>
                                                  <strong>oil</strong>, signification: Besoin de prendre du carburant <br/>
                                                  <strong>low battery</strong>, signification: Battérie faible <br/>
                                                  <strong>speed</strong>, signification: Dépassement de la vitesse autorisé <br/>
                                                  <strong>ac alarm</strong>, signification: Le véhicule à été eteint ou allumé <br/>
                                                  <strong>stockade</strong>, signification: Alarme de géo-clôture<br/>
                                                  <strong>door alarm</strong>, signification: Ouverture et fermeture des portes<br/>
                                                  <strong>sensor alarm</strong>, signification: Alarme capteur de choc<br/>
                                                  <strong>accident alarm</strong>, signification: Alarme en cas d'accident<br/>
                                                  <strong>acc alarm</strong>, signification: Dépassement de la distance autorisé avec le véhicule qui est devant<br/>
                                                  <strong>DTC</strong>, signification: Notification de panne du véhicule<br/>
                                                  <strong>service</strong>, signification: Notification d'entretien du véhicule<br/>
                                                  <strong>Bonnet alarm</strong>, signification: Alarme de capot<br/>
                                                  <strong>Footbrake alarm</strong>, signification: Alarme de frein à pied<br/>
                                                  <strong>acc on</strong>, signification: ACC activé<br/>
                                                  <strong>acc off</strong>, signification: ACC désactivé<br/>
                                                  <strong>Temperature alarm</strong>, signification: Alarme du capteur de température<br/>
                                            </TableCell>
                                          </TableRow>
                                        {/* dateTime */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">10</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>dateTime</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              Date et heure d'envoi de la dernière position du gps
                                            </TableCell>
                                          </TableRow>
                                        {/* RFID */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">11</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>RFID</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              RFID est l'acronyme de Radio Frequency Identification, ce qui donne en français « identification par radiofréquence ». C'est une technologie utilisant les ondes radio haute fréquence pour transmettre et mémoriser des données.
                                            </TableCell>
                                          </TableRow>
                                        {/* time */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">12</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>time</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              Heure d'envoi de la dernière position du gps
                                            </TableCell>
                                          </TableRow>
                                        {/* latitude */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">13</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>latitude</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              Latitude de la dernière position du gps
                                            </TableCell>
                                          </TableRow>
                                        {/* longitude */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">14</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>longitude</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              Longitude de la dernière position du gps
                                            </TableCell>
                                          </TableRow>
                                        {/* speed */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">15</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>speed</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              La vitesse actuelle du véhicule
                                            </TableCell>
                                          </TableRow>
                                        {/* direction */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">16</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>direction</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              La direction du véhicule
                                            </TableCell>
                                          </TableRow>
                                        {/* altitude */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">17</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>altitude</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              L'altitude du véhicule
                                            </TableCell>
                                          </TableRow>
                                        {/* acc */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">18</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>acc</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              La distance avec le véhicule de devant
                                            </TableCell>
                                          </TableRow>
                                        {/* etatPorte */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">19</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>etatPorte</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              L'état des portes
                                            </TableCell>
                                          </TableRow>
                                        {/* reservoir1 */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">20</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>reservoir1</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              Le carburant dans le premier réservoir
                                            </TableCell>
                                          </TableRow>
                                        {/* reservoir2 */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">21</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>reservoir2</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              Le carburant dans le deuxième réservoir
                                            </TableCell>
                                          </TableRow>
                                        {/* temperature */}
                                           <TableRow>
                                            <TableCell className="pl-3 fw-normal">22</TableCell>
                                            <TableCell><strong style={{color: Constant.couleurSecondaire}}>temperature</strong></TableCell>
                                            <TableCell>String</TableCell>
                                            <TableCell>
                                              La temperature dans le véhicule.
                                            </TableCell>
                                          </TableRow>
                                        
                                      </TableBody>
                                    </Table>
                                  </Widget>
                              </Grid>
                            </Grid>
                            }
                          </div>

                          <div style={{ marginTop: 24 }}>
                            {current > 0 && (
                              <ButtonAntd style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Précédent
                              </ButtonAntd>
                            )}
                            {current < steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => next()}>
                                Suivant
                              </ButtonAntd>
                            )}
                            {current === steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => setOpenGps({...openGps, visible: false})}>
                                Terminé
                              </ButtonAntd>
                            )}
                            <ButtonAntd style={{ margin: '0 8px', float: 'right' }} onClick={() => setOpenGps({...openGps, visible: false})}>
                                Fermer
                              </ButtonAntd>
                          </div>
                    </Paper>
                </Grid>
                <Grid item lg={1}>
                </Grid>
            </Grid>
          </Modal>
{/* Modification facturation */}
    <Modal
      open={openFacturation}
      onClose={(e)=>setOpenFacturation(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{overflow: 'scroll', zIndex: 1200}}
      >
        <div>
            <Grid container spacing={3}>
              <Grid item lg={4}>
              </Grid>
              <Grid item lg={4}>
                <Paper square  style={{marginTop: 50, paddingBottom: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                    <h1 style={{textAlign: 'center', color: Constant.couleurPrimaire, paddingTop: 50, paddingBottom: 20}}>
                       Modification de la facturation
                    </h1>

                    <Grid container spacing={3}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8}>
                          <Grid container spacing={3}>
                            {AsRoles([Constant.ROLE_SEVOB_SUPER_ADMIN, Constant.ROLE_MODIFIER_TYPE_FACTURATION]) === Constant.visible &&
                              <Grid item xs={12}>
                                  <span>Type de facturation</span>
                                  <br/>
                                  <Select
                                    style={{ width: "100%", zIndex: 1}}
                                    dropdownStyle={{zIndex: 1300}}
                                    onChange={(e)=>setState({...state, type: e})}
                                    defaultValue={state.type}
                                    options={[
                                      { value: 'Pourcentage', label: 'Pourcentage' },
                                      { value: 'Transaction', label: 'Transaction' },
                                      { value: 'Journalière', label: 'Journalière' },
                                      { value: 'Hebdomendaire', label: 'Hebdomendaire' },
                                      { value: 'Mensuel', label: 'Mensuel' },
                                      { value: 'Trimestriel', label: 'Trimestriel' },
                                      { value: 'Annuelle', label: 'Annuelle' },
                                    ]}
                                  />
                                  {error.type && <span style={{color: 'red'}}>{Constant.champObligatoire}</span>}
                              </Grid>
                            }
                            <Grid item xs={12}>
                              <TextField type="number" defaultValue={state.montant} error={error.montant} helperText={error.montant && Constant.champObligatoire} onChange={(e)=>setState({...state, montant: e.target.value})} size="small" label="Montant de la facturation" variant="outlined" fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AccountBalanceWallet />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField type="number" defaultValue={state.duree} onChange={(e)=>setState({...state, duree: e.target.value})} size="small" label="Durée" variant="outlined" fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AccountBalanceWallet />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Button variant="outlined" color="secondary" onClick={(e)=>cliquer()} disabled={spinner}>
                                {state.id === 0 ? "Ajouter" : "Modifier"}
                                {spinner && <CircularProgress size={20} style={{marginLeft: 10}} />}
                              </Button>
                            </Grid>
                            <Grid item xs={6} style={{textAlign: 'right'}}>
                              <Button variant="outlined" color="default" onClick={(e)=>setOpenFacturation(false)}>
                                Fermer
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Paper>
              </Grid>
              <Grid item lg={3}>
              </Grid>
            </Grid>
        </div>
      </Modal>
{/* Integration l'envoi SMS */}
      <Modal
            open={openSMS}
            onClose={(e)=>setOpenSMS(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{overflow: 'scroll', zIndex: 1200}}
          >
            <Grid container spacing={3}>
                <Grid item lg={2}>
                </Grid>
                <Grid item lg={9}>
                    <Paper square  style={{padding: 50, borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginTop: 50}}>
                        <br/>
                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'red', borderColor: 'red'}}>
                          <a href={IntegrationSMS} target="_blank" download>Télécharger la version PDF</a>
                        </Button>
                        <br/><br/><br/>
                        <Steps current={current} items={items2} onChange={onChangeV}/>
                          <div style={contentStyle}>
                          {/* Integration */}
                            {current === 0 &&
                              <Grid container spacing={3}>
                                <Grid item lg={12}>
                                  <h3><strong style={{fontSize: 20}}>🟠</strong> Le code ci-dessous est un objet (JSON) qui a été conçu en utilisant Javascript. Modifiez-le en fonction de votre langage de programmation. Dans l'étape <strong style={{color: 'red'}}>Définitions</strong>, vous obtiendrez toutes les informations sur chaque élément.</h3>
                                </Grid>
                                <Grid item lg={12}>
                                    <CopyToClipboard text={codeSMSString} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeSMSString}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={12}>
                                  <hr/>
                                  <h3>
                                    <strong style={{fontSize: 20}}>🟠</strong> Pour envoyer vos SMS, utilisez le lien ci-dessous avec la méthode <strong style={{color: 'red'}}>POST</strong>. Si tout se passe bien, vous recevrez le message de confirmation.
                                    <br/>
                                    <br/>
                                    <strong style={{color: 'red', fontSize: 20, textDecorationLine: 'underline'}}>Attention:</strong> N'oubliez pas d'envoyer l'objet (JSON) ci-dessus ☝🏿 que vous avez adapté à votre langage de programmation dans la méthode <strong style={{color: 'red'}}>POST</strong>.
                                  </h3>
                                </Grid>
                                <Grid item lg={12}>
                                    <CopyToClipboard text={codeString2} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString2}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={12}>
                                <hr/>
                                  <h3>
                                    <strong style={{fontSize: 20}}>🟠</strong> - Dans le cas où vous commettez une erreur, la réponse que vous obtiendrez contiendra un attribut code qui est 400 et un attribut message qui est le message d'erreur rencontré.
                                    <br/>
                                    - Mais si tout est bon, la réponse que vous obtiendrez contiendra un attribut code qui est 200 et un attribut <strong style={{color: 'red'}}>message</strong> pour confirmer l'envoi.
                                    <br/>
                                    <br/>
                                    <strong style={{color: 'red', fontSize: 20, textDecorationLine: 'underline'}}>NB:</strong> Les SMS sont envoyés de façon <strong style={{color: 'red'}}>asynchrone</strong>. Cela implique que le serveur a la capacité d'envoyer les messages en arrière-plan une fois soumis.
                                  </h3>
                                </Grid>
                                <Grid item lg={6}>
                                    <h3 style={{color: 'blue'}}>En cas d'erreur</h3>
                                    <CopyToClipboard text={codeString3} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString3}
                                    </SyntaxHighlighter>
                                </Grid>
                                <Grid item lg={6}>
                                    <h3 style={{color: 'blue'}}>En cas de succès</h3>
                                    <CopyToClipboard text={codeString5} onCopy={() => toast.success("Copié", {position: "top-center", autoClose: 10000,})}>
                                        <Button variant="outlined" startIcon={<FileCopy />} style={{color: 'green', borderColor: 'green'}}>
                                          Cliquez-ici pour copier
                                        </Button>
                                    </CopyToClipboard>
                                    <SyntaxHighlighter language="javascript" style={dark} showLineNumbers wrapLines>
                                        {codeString5}
                                    </SyntaxHighlighter>
                                </Grid>
                              </Grid>
                            }
                          {/* Définitions */}
                            {current === 1 &&
                              <Grid container spacing={3}>
                                <Grid item sm={12}>
                                  <Widget title="Définition des variables" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                                    <Table className="mb-0">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Variable</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Obligatoire</TableCell>
                                            <TableCell>A éviter</TableCell>
                                            <TableCell>Taille</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {/* nom_a_afficher */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">1</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>nom_a_afficher</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est le nom ou le numéro que vous souhaitez afficher comme expéditeur auprès de vos clients.
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell>Evitez les caractères spéciaux</TableCell>
                                              <TableCell>
                                                <strong>18 caractères au maximum</strong> si vous utilisez uniquement les chiffres.
                                                <br/><br/>
                                                <strong>11 caractères au maximum</strong> si vous utilisez les chiffres et les lettres.
                                              </TableCell>
                                            </TableRow>
                                          {/* numeros */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">2</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>numeros</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                Ce sont les numéros auxquels vous souhaitez envoyer des SMS.
                                                <br/><br/>
                                                Veuillez fournir les numéros dans leur format standard et séparé par des virgules ( , ), sans symboles ni caractères supplémentaires. N'incluez pas de signe plus ( + ) ou de préfixe ( 00 )
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell>
                                                signe plus ( + )
                                                <br/><br/>
                                                préfixe ( 00 )
                                              </TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                            {/* message */}
                                            <TableRow>
                                              <TableCell className="pl-3 fw-normal">3</TableCell>
                                              <TableCell><strong style={{color: Constant.couleurSecondaire}}>message</strong></TableCell>
                                              <TableCell>String</TableCell>
                                              <TableCell>
                                                C'est le contenu que vous souhaitez envoyer à vos clients.
                                              </TableCell>
                                              <TableCell>Oui</TableCell>
                                              <TableCell></TableCell>
                                              <TableCell>
                                                Le contenu de votre message ne doit pas dépasser <strong>300 caractères au maximum</strong>
                                              </TableCell>
                                            </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Widget>
                                </Grid>
                              </Grid>
                            }
                          </div>

                          <div style={{ marginTop: 24 }}>
                            {current > 0 && (
                              <ButtonAntd style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Précédent
                              </ButtonAntd>
                            )}
                            {current < steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => next()}>
                                Suivant
                              </ButtonAntd>
                            )}
                            {current === steps.length - 1 && (
                              <ButtonAntd type="primary" onClick={() => setOpenSMS(false)}>
                                Terminé
                              </ButtonAntd>
                            )}
                            <ButtonAntd style={{ margin: '0 8px', float: 'right' }} onClick={() => setOpenSMS(false)}>
                                Fermer
                              </ButtonAntd>
                          </div>
                    </Paper>
                </Grid>
                <Grid item lg={1}>
                </Grid>
            </Grid>
      </Modal>
    </>
  );
}
