import { Box, IconButton, Link } from '@material-ui/core';
import Icon from '@mdi/react';
import classnames from "classnames";
import React from "react";
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiGithub as GithubIcon,
  mdiTwitter as TwitterIcon,
} from '@mdi/js';

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Profil from "../../pages/Profil";
import Solde from "../../pages/Solde";
import Statistique from "../../pages/Statistique";
import Charts from "../../pages/charts";
import Dashboard from "../../pages/dashboard";
import Icons from "../../pages/icons";
import Maps from "../../pages/maps";
import Notifications from "../../pages/notifications";
import Tables from "../../pages/tables";
import Typography from "../../pages/typography";

// context
import { useLayoutState } from "../../context/LayoutContext";
import MesTransactions from "../../pages/MesTransactions/MesTransactions";
import MesTransactionsTest from "../../pages/MesTransactionsTest/MesTransactionsTest";
import DemandeRetrait from "../../pages/demandeRetrait/DemandeRetrait";
import GestionApis from "../../pages/gestionApis/GestionApis";
import GestionGPS from "../../pages/gestionGPS/GestionGPS";
import GestionQrCode from '../../pages/gestionQrCode/GestionQrCode';
import GestionSMS from "../../pages/gestionSMS/GestionSMS";
import GestionUtilisateur from "../../pages/gestionUtilisateur/GestionUtilisateur";
import MesApis from "../../pages/mesApis/MesApis";
import MesGPS from "../../pages/mesGPS/MesGPS";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/Solde/:data" component={Solde} />
              <Route path="/app/GestionSMS/:data" component={GestionSMS} />
              <Route path="/app/GestionQrCode/:data" component={GestionQrCode} />
              <Route path="/app/Statistique/:data" component={Statistique} />
              <Route path="/app/Profil" component={Profil} />
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/gestionUtilisateur/:data" component={GestionUtilisateur} />
              <Route path="/app/demandeRetrait" component={DemandeRetrait} />
              <Route path="/app/mesTransactions/:data" component={MesTransactions} />
              <Route path="/app/mesTransactionsTest/:data" component={MesTransactionsTest} />
              <Route path="/app/gestionApis" component={GestionApis} />
              <Route path="/app/GestionGPS" component={GestionGPS} />
              <Route path="/app/notifications" component={Notifications} />
              <Route path="/app/mesApis/:data" component={MesApis} />
              <Route path="/app/MesGPS/:data" component={MesGPS} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <p>Copyright &copy; 2023 - {new Date().getFullYear()} par SEVOB-Sarl</p>
              </div>
              <div>
                <Link
                  href={'https://www.facebook.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://github.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={GithubIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
